import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import CUSTOM_STYLES from '../../utils/modalStyle';
import { ProductsPrefference } from '@desquared/wave-components-library';
import { prefferenceSnackBarStore, productPrefferencesStore, snackBarMessageStore } from '@/store/prefferenceModal';
import styles from './ProductsPrefferenceModal.module.css';
import { prefferenceModalStore } from '@/store/prefferenceModal';
import {
  addProduct,
  initMyoProducts,
  removeProduct,
  createStringArray,
  checkIsOpted,
  updateSharedCartWithComments,
} from '@/utils/products';
import { productsForOrder } from '@/store/cart';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { CurrencyContext } from '@/contexts/CurrencyProvider';
import { useConfiguration } from '@/contexts/ConfigContext';

function ProductsPrefferenceModal({ isOpen = true, modalClose, shouldCloseOnOverlayClick = true }) {
  const [_isModalOpen, setIsModalOpen] = useState(isOpen);
  const router = useRouter();
  const { formatter } = useContext(CurrencyContext);
  const [pref, setPref] = useState();
  const [optionsGroups, setOptionsGroups] = useState([]);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const t = useTranslations();
  const { config: configuration } = useConfiguration();
  const [commentMaxLength, setCommentMaxLength] = useState(100);

  const buttonText = t('common.preferences_modal.qs_text');
  const textAreaDescription = t('common.preferences_modal.comment_placeholder');
  const textAreaTitle = t('common.preferences_modal.comment_label');

  function closeModal() {
    if (checkIsOpted(optionsGroups[0].options)) {
      snackBarMessageStore.update(createStringArray(optionsGroups[0].options, createdProducts, t));
      prefferenceSnackBarStore.update(true);
    }

    let entries = productsForOrder.getValue();
    createdProducts.forEach((el, index) => {
      entries.forEach(entry => {
        if (entry.myoId === el.myoId) {
          entry.comments = comments[index];
        }
      });
    });

    productsForOrder.update(entries);
    updateSharedCartWithComments(entries, t('common.asides.side_cart.no_comment'));
    setIsModalOpen(false);
    prefferenceModalStore.update(false);
  }

  const [createdProducts, setCreatedProducts] = useState([]);
  useEffect(() => {
    if (configuration?.fields?.orderItems?.comments?.maxLength)
      setCommentMaxLength(configuration?.fields?.orderItems?.comments?.maxLength ?? 100);
  }, [configuration, setCommentMaxLength]);

  const handleCommentChange = comment => {
    setComments(prevComments => {
      const updatedComments = [...prevComments];
      updatedComments[selectedIndex] = comment;
      return updatedComments;
    });
  };

  const getOptions = groups => {
    if (groups.length > 0) {
      const firstGroup = groups[0];

      const groupOptions = firstGroup.options.map((option, index) => {
        return {
          text: option.label,
          selected: selectedOptions[firstGroup.id] === option.id || index === selectedIndex,
          isOpted: createdProducts[index]?.modifiedUom > 0,
        };
      });

      const optionsData = [
        {
          title: firstGroup.label,
          options: groupOptions,
        },
      ];

      setOptionsGroups(optionsData);

      const defaultSelectedOption = groupOptions[0]?.text || '';
      setSelectedOptions({ [firstGroup.id]: defaultSelectedOption });
    }
  };

  const selectOption = (group, index) => {
    const newOptions = optionsGroups[0].options.map((el, index2) => {
      if (index2 === index) {
        el.selected = true;
        return el;
      } else {
        el.selected = false;
        return el;
      }
    });

    setOptionsGroups(prevOptionsGroups => [
      {
        ...prevOptionsGroups[0],
        options: newOptions,
      },
      // Include the other groups if needed
    ]);

    setSelectedIndex(index);
  };
  const onAdd = () => {
    addProduct(createdProducts[selectedIndex], false, undefined, false, t('common.asides.side_cart.no_comment'));
    setCreatedProducts(initMyoProducts(pref, comments));
    getOptions(pref?.optionsGroups);
  };
  const onRemove = () => {
    removeProduct(createdProducts[selectedIndex], undefined, t('common.asides.side_cart.no_comment'));
    setCreatedProducts(initMyoProducts(pref, comments));
    getOptions(pref?.optionsGroups);
  };

  useEffect(() => {
    const productPrefferences$ = productPrefferencesStore.subscribe(setPref);
    setOptionsGroups(pref?.optionsGroups ?? []);
    setSelectedOptions({});
    setCreatedProducts(initMyoProducts(pref, comments));

    return () => {
      productPrefferences$.unsubscribe();
    };
  }, [pref, router.locale]);

  useEffect(() => {
    if (createdProducts?.length > 0) {
      getOptions(pref?.optionsGroups);
      setComments(createdProducts?.map(el => el?.comments ?? ''));
    }
  }, [createdProducts]);

  useEffect(() => {
    handleCommentChange(comment);

    if (createdProducts?.length > 0) {
      setCreatedProducts(prevArray =>
        prevArray.map((item, i) => (i === selectedIndex ? { ...item, comments: comment } : item))
      );
      setComments(createdProducts?.map(el => el?.comments ?? ''));
    }
  }, [comment]);

  if (!pref || !optionsGroups || !createdProducts) return null;

  return (
    _isModalOpen && (
      <ReactModal
        isOpen={_isModalOpen}
        onRequestClose={closeModal}
        style={CUSTOM_STYLES}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        className={styles.modalContent}
      >
        <ProductsPrefference
          buttonText={buttonText}
          textAreaDescription={textAreaDescription}
          textAreaTitle={textAreaTitle}
          comments={comments}
          image={pref?.image}
          title={pref?.displayName}
          chips={optionsGroups[0]?.options ?? []}
          categoryTitle={optionsGroups[0]?.title ?? ''}
          setComment={setComment}
          handleClose={closeModal}
          onPress={selectOption}
          product={selectedIndex !== undefined ? createdProducts[selectedIndex] : pref}
          addProduct={onAdd}
          removeProduct={onRemove}
          subTitle={t('common.preferences_modal.subtitle')}
          selectedIndex={selectedIndex}
          qsUnavailableText={t('common.qs.default_unavailable')}
          qsText={t('common.qs.default')}
          currencyFormatter={formatter}
          commentMaxLength={commentMaxLength}
        />
      </ReactModal>
    )
  );
}

export default ProductsPrefferenceModal;
