import { wave, waveV3 } from '@/api/config';

export async function createUserLists(label, authorization, locale) {
  const headers = {
    appId: process.env.appId,
    authorization: `Bearer ${authorization}`,
    'Accept-Language': locale,
  };
  try {
    const response = await waveV3.post(`/lists`, { label: label }, { headers });
    return { data: response?.data || [] };
  } catch (error) {
    return { data: [], error };
  }
}

export async function getUserListsV3() {
  try {
    const response = await wave.get(`/v3.1/lists?withProductPreviewCount=${4}`);
    return { data: response?.data || [] };
  } catch (error) {
    return { data: [], error };
  }
}

export async function updateUserLists(authorization, id, action, products) {
  try {
    const response = await waveV3.put(`/lists/${id}/products`, { action, products });
    return { data: response?.data?.payload || [] };
  } catch (error) {
    return { data: [], error };
  }
}

export async function deleteUserList(authorization, id) {
  const headers = {
    appId: process.env.appId,
    authorization: `Bearer ${authorization}`,
  };
  try {
    const response = await waveV3.delete(`/lists/${id}`, { headers });
    return { data: response?.data?.payload || [] };
  } catch (error) {
    return { data: [], error };
  }
}

export async function editUserList(authorization, id, label) {
  const headers = {
    appId: process.env.appId,
    authorization: `Bearer ${authorization}`,
  };
  try {
    const response = await waveV3.patch(`/lists/${id}`, { label }, { headers });
    return { data: response?.data?.payload || [] };
  } catch (error) {
    return { data: [], error };
  }
}

export async function getUserListsProductsV3(authorization, id, resource) {
  const headers = {
    appId: process.env.appId,
    authorization: `Bearer ${authorization}`,
  };
  try {
    const response = await wave.get(`/v3.1/lists/${id}/products`, { params: { ...resource }, headers });
    return { data: response?.data || [] };
  } catch (error) {
    return { data: [], error };
  }
}

export function getFrequentlyBoughtTogether(id, locale) {
  const headers = {
    appId: process.env.appId,
    'x-api-key': process.env.PRIVATE_API_KEY || undefined,
    'Accept-Language': locale,
  };

  return wave.get(`/v3.1/lists/frequently-bought-together/${id}?valid=true`, { headers });
}

export function getSimilarProducts(id, locale) {
  const headers = {
    appId: process.env.appId,
    'x-api-key': process.env.PRIVATE_API_KEY || undefined,
    'Accept-Language': locale,
  };

  return wave.get(`/v3.1/lists/similar-products/${id}?valid=true`, { headers });
}
