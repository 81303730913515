function distanceFromTwoPoints(lat1, lon1, lat2, lon2, unit) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }

  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;

  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  // Clamping the value of dist to avoid any floating-point precision errors
  if (dist > 1) {
    dist = 1;
  } else if (dist < -1) {
    dist = -1;
  }

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI; // Convert radian to degree
  dist = dist * 60 * 1.1515; // Convert degree distance to miles

  if (unit === 'K') {
    dist = dist * 1.609344; // Convert miles to kilometers
  } else if (unit === 'N') {
    dist = dist * 0.8684; // Convert miles to nautical miles
  }

  return dist;
}

export default distanceFromTwoPoints;
