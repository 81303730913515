import { BehaviorSubject } from 'rxjs';

const subjectPriceChangesModal = new BehaviorSubject(false);
const priceChanges = new BehaviorSubject({});

const priceChangesModalStore = {
  update: value => {
    subjectPriceChangesModal.next(value);
  },
  subscribe: setState => subjectPriceChangesModal.subscribe(setState),
  getValue: () => subjectPriceChangesModal.value,
};
const priceChangesStore = {
  update: value => {
    priceChanges.next(value);
  },
  subscribe: setState => priceChanges.subscribe(setState),
  getValue: () => priceChanges.value,
};

export { priceChangesModalStore, priceChangesStore };
