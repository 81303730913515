import React, { useState, useEffect, useRef, useContext } from 'react';
import { SideSheet, AddressView, MapPinEnum } from '@desquared/wave-components-library';
import isEmpty from 'lodash/isEmpty';

import CountryCodeAside from '../Aside/CountryCodeAside';
import { CountryCodeSideSheetStore } from '../../store/sideSheet';
import { CountryCodeStore } from '@/store/countryCode';
import useDisableBodyScroll from '@/hooks/useDisableBodyScroll';
import User from '@/store/user';
import { AddressViewSideSheetStore } from '../../store/sideSheet';
import GOOGLE_API_KEY from '@/utils/googleAPIKey';
import { seperateCountryCode } from '@/utils/countriesTransform';
import styles from '@/components/AddressViewAside/AddressViewAside.module.css';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext';
import { Shop } from '@/store/shop';

export default function AddressViewAside({
  handleClose,
  selectedAddress,
  isEdit,
  user,
  onSubmit,
  guestUser,
  isNotEligible,
  notEligibleWording,
  setIsNotEligibleText,
  setIsNotEligible,
  config,
}) {
  const ref = useRef(null);
  const t = useTranslations();
  const router = useRouter();

  const [countryCode, setCountryCode] = useState(['']);
  const [sideSheet, setSideSheet] = useState(false);
  const [defaultMapCenter, setDefaultMapCenter] = useState({
    lat: process.env.defaultMapCenter.lat,
    lon: process.env.defaultMapCenter.lng,
  });

  useDisableBodyScroll();
  let address;
  if (!isEmpty(guestUser)) {
    if (guestUser?.address) {
      address = guestUser?.address[0];
    } else {
      {
        address = {};
      }
    }
  } else {
    address = user?.addresses?.find(ele => ele._id === selectedAddress);
  }
  const mainAddress = User.getUserMainAddress();
  let myAddress;
  if (address) {
    let type = '';
    switch (address.type) {
      case 'home':
        type = 0;
        break;
      case 'work':
        type = 1;
        break;
      case 'other':
        type = 2;
        break;
      default:
        type = 2;
        break;
    }
    myAddress = {
      street: address.street,
      streetNo: address.streetNo,
      isMainAddress: guestUser ? true : mainAddress._id === address._id,
      floor: address.floor,
      nameAtBell: address.nameAtBell,
      postalCode: address.postalCode,
      city: address.city,
      phone: guestUser ? address.contactPhone : seperateCountryCode(address.contactPhone).phoneNumber,
      addressType: type,
      latitude: address.latitude,
      longitude: address.longitude,
    };
  } else {
    myAddress = {};
  }

  const onClick = () => {
    setIsNotEligibleText('');
    setIsNotEligible(false);
    setTimeout(() => {
      AddressViewSideSheetStore.close();
      handleClose();
    }, '300');
  };

  useEffect(() => {
    const CountryCodeStore$ = CountryCodeStore.subscribe(setCountryCode);
    const $shop = Shop.subscribe(shopData => {
      if (shopData?.selectedShop) {
        setDefaultMapCenter({
          lat: shopData.selectedShop.address.latitude,
          lon: shopData.selectedShop.address.longitude,
        });
      }

      if (shopData?.takeAway) {
        // TODO: Optimize this
        setDefaultMapCenter({
          lat: shopData.takeAway[0].address.latitude,
          lon: shopData.takeAway[0].address.longitude,
        });
      }
    });

    if (!guestUser) {
      const cc = seperateCountryCode(address ? address.contactPhone : '').countryCode;

      address && cc !== '' && setCountryCode([cc]);
    }
    const AddressViewSideSheetStore$ = AddressViewSideSheetStore.subscribe(setSideSheet);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      AddressViewSideSheetStore$.unsubscribe();
      document.removeEventListener('click', handleClickOutside, false);

      CountryCodeStore$.unsubscribe();
      $shop.unsubscribe();
    };
  }, []);
  const handleClickOutside = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      onClick();
    }
  };
  const onCountryCodeClick = () => {
    CountryCodeSideSheetStore.open();
  };

  if (!sideSheet) return null;

  return (
    <div ref={ref} className={styles.wrapper}>
      <SideSheet
        header={isEdit ? t('common.asides.address_view.header_edit') : t('common.asides.address_view.header_add')}
        hasClose={true}
        onClose={onClick}
      >
        <AddressView
          footer={true}
          footerButtonWording={t('common.asides.address_view.save')}
          footerOnClick={e => {
            onSubmit(e);
          }}
          googleApiKey={GOOGLE_API_KEY}
          address={myAddress}
          type={MapPinEnum.Profile}
          hasMainAddressOption={guestUser ? false : process.env.hasMainAddressOption}
          mainAddressWording={t('common.asides.address_view.main_address')}
          notEligibleWording={
            notEligibleWording.length > 0 ? notEligibleWording : t('common.asides.address_view.not_eligible')
          }
          setIsNotEligible={setIsNotEligible}
          onCodeClick={onCountryCodeClick}
          onOpenSideClick={onCountryCodeClick}
          addressTypeWording={t('common.asides.address_view.address_kind')}
          selectedCodeIndex={0}
          countryCodeLabel={t('common.asides.address_view.country_code')}
          countries={countryCode}
          isNotEligible={isNotEligible}
          formLabels={{
            address: t('common.asides.address_view.form_labels.address'),
            city: t('common.asides.address_view.form_labels.area'),
            street: t('common.asides.address_view.form_labels.road'),
            streetNo: t('common.asides.address_view.form_labels.street_no'),
            contactPhone: t('common.asides.address_view.form_labels.tel'),
            floor: t('common.asides.address_view.form_labels.floor'),
            nameAtBell: t('common.asides.address_view.form_labels.door_bell'),
            postalCode: t('common.asides.address_view.form_labels.postal'),
          }}
          dropdownArgs={[
            t('common.asides.service_address.types.home'),
            t('common.asides.service_address.types.work'),
            t('common.asides.service_address.types.other'),
          ]}
          minLengthErrorText={t('common.minimun_character')}
          addressConfig={config?.fields?.address ?? null}
          mapTilesLocale={router.locale}
          // TODO: Change this when it's in the api configuration
          restrictAutoCompleteToCountry={process.env.defaultAutocompleteCountryCode}
          defaultCenter={defaultMapCenter}
          requiredText={t('common.asides.address_view.form_labels.required_field')}
          invalidText={t('common.asides.address_view.form_labels.invalid_field')}
        />
        <CountryCodeAside />
      </SideSheet>
    </div>
  );
}
