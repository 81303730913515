import { BehaviorSubject } from 'rxjs';
import jsonStorage from '../utils/json-storage';

export const HeaderMessageStore = {
  subject: undefined,

  _lazyInit() {
    if (HeaderMessageStore.subject) return HeaderMessageStore.subject;
    if (typeof window !== 'undefined' && window?.localStorage) {
      const storage = window.localStorage;
      const message = jsonStorage.get('headerMessage', { storage }) || 'none';
      HeaderMessageStore.subject = new BehaviorSubject(message);
      return HeaderMessageStore.subject;
    }
  },
  update: timeslot => {
    const message = HeaderMessageStore._lazyInit();
    message.next(timeslot);
    const storage = window.localStorage;
    jsonStorage.set('headerMessage', timeslot, { storage });
  },
  subscribe: setState => {
    const message = HeaderMessageStore._lazyInit();
    message.subscribe(setState);
  },
  getValue: () => {
    const message = HeaderMessageStore._lazyInit();
    return message?.value;
  },
};

export default { HeaderMessageStore };
