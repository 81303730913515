import { getBadgeText, getProductImage } from './products';
import { getCategoryNameFromFile } from '@/utils/categories/categories';

export function categoriesToTerms(categories, query, t, locale, defaultLocale) {
  const terms = [];
  const onAllCategories = {
    href: locale === defaultLocale ? `/search?product=${query.terms}` : `/${locale}/search?product=${query.terms}`,
    type: 'Search',
    termSuggestion: query.terms,
    category: t('in_all_categories'),
    hasCategory: true,
    count: query.terms.length,
    hasCount: false,
    hasNotBorderBottom: false,
  };
  if (categories[0]) {
    terms.push(onAllCategories);
  }

  categories.map((category, index) => {
    const tempTerm = {
      href:
        locale === defaultLocale
          ? `/search?category=${category.category._id}&product=${category.term}`
          : `/${locale}/search?category=${category.category._id}&product=${category.term}`,
      type: category.previouslySearched ? 'History' : 'Search',
      termSuggestion: category.term,
      category: t('in_category', { categoryName: getCategoryNameFromFile(category.category._id, locale) }),
      hasCategory: true,
      count: category.term.length,
      hasCount: false,
      hasNotBorderBottom: false,
    };
    terms.push(tempTerm);
  });
  if (terms[terms.length - 1]) {
    terms[terms.length - 1].hasNotBorderBottom = true;
  }

  return terms;
}

export function suggestionsToProducts(suggestions, bundleOffersSummary, locale, defaultLocale) {
  const products = [];
  suggestions.map((suggestion, index) => {
    const tempProduct = {
      href: locale === defaultLocale ? `/${suggestion.slug}` : `/${locale}/${suggestion.slug}`,
      image: getProductImage(suggestion, '1x'),
      title: suggestion.name,
      subtitle: suggestion.description,
      hasSubtitle: !!suggestion.description,

      hasNotBorderBottom: false,
      label: getBadgeText(suggestion, bundleOffersSummary),

      hasOffer: suggestion.offerType !== 'none',
    };
    if (tempProduct?.label?.length > 0) tempProduct.hasOffer = true;
    products.push(tempProduct);
  });
  if (products[products.length - 1]) {
    products[products.length - 1].hasNotBorderBottom = true;
  }
  return products;
}
