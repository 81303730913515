import cn from 'classnames';
import ReactModal from 'react-modal';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { DeleteAddressModal } from '@desquared/wave-components-library';
import User from '@/store/user';
import DeliveryMode from '@/store/deliveryMode';
import { LoginProviderStore } from '@/store/loginProvider';
import CUSTOM_STYLES from '@/utils/modalStyle';
import { PostalCode } from '@/store/isAddressRegistered';
import { SideSheetStore, ResponsiveMenuStore, AddressViewSideSheetStore } from '@/store/sideSheet';
import UserListsStore from '@/store/userLists';
import { useTranslations } from 'next-intl';
import { DeliveryStore as DeliveryAddressIndexStore } from '@/store/sideSheet';
import { clearCart } from '@/utils/cart';

import styles from '@/styles/User.module.css';
import { Shop } from '@/store/shop';

export default function LogoutModal({ showModal, setShowModal }) {
  const [user, setUser] = useState();
  const router = useRouter();
  const t = useTranslations('common.logout_modal');

  async function onClickLogout() {
    try {
      await Promise.all(
        [User.reset(), () => {}, DeliveryMode.update('easy')],
        LoginProviderStore.clear(),
        UserListsStore.clear(),
        PostalCode.delete(),
        DeliveryAddressIndexStore.update(undefined),
        clearCart()
      );
      // await Promise.all([User.reset(), clearCart(), DeliveryMode.update('easy')]);
    } finally {
      AddressViewSideSheetStore.reset();
      setShowModal(false);
      SideSheetStore.close();
      if (process.env.hasStoreNecessary) {
        router.replace('/welcome');
      } else {
        router.replace('/');
      }
      Shop.reset();
      ResponsiveMenuStore.close();
    }
  }

  useEffect(() => {
    const user$ = User.subscribe(_user => {
      setUser(_user);
    });

    return () => user$.unsubscribe();
  }, []);

  if (!user?._id) return null;
  return showModal ? (
    <ReactModal
      isOpen={showModal}
      onRequestClose={() => {
        setShowModal(false);
      }}
      style={CUSTOM_STYLES}
      contentLabel={'logout modal'}
      ariaHideApp={false}
      className={cn(styles.modalContainer, styles.modal)}
      shouldCloseOnOverlayClick={true}
    >
      <DeleteAddressModal
        // icon={LoginLogout}
        title={t('title')}
        description={t('description')}
        cancelWording={t('cancel')}
        deleteWording={t('logout')}
        onDelete={() => {
          {
            setShowModal(false);
            /*placeholder for logout */
            onClickLogout();
          }
        }}
        onCancel={() => {
          setShowModal(false);
        }}
      ></DeleteAddressModal>
    </ReactModal>
  ) : (
    <></>
  );
}
