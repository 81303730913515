import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getOrFetchBundleOffers } from '@/utils/getBundleOffers';

export default function useBundleOffersSummary() {
  const router = useRouter();
  const [bundleOffersSummary, setBundleOffersSummary] = useState({});

  useEffect(() => {
    async function getBundleOffersSummary() {
      const bundleOffers = await getOrFetchBundleOffers(router.locale);
      setBundleOffersSummary(bundleOffers);
    }
    getBundleOffersSummary();
  }, []);

  return { bundleOffersSummary };
}
