export function normalizeGreek(text) {
  const newText = text
    .replace('Ά', 'Α')
    .replace('Έ', 'Ε')
    .replace('Ή', 'Η')
    .replace(/Ί|Ϊ|Ι/, 'I')
    .replace('Ό', 'Ο')
    .replace(/Ύ|Ϋ|Υ/g, 'Ύ')
    .replace('Ώ', 'Ω')
    .replace()
    .replace('ά', 'α')
    .replace('έ', 'ε')
    .replace('ή', 'η')
    .replace(/ί|ΐ|ϊ/g, 'ι')
    .replace('ό', 'ο')
    .replace(/ύ|ΰ|ϋ/g, 'υ')
    .replace('ώ', 'ω')
    .replace(/Σ|ς/g, 'σ');
  return newText;
}

export function getGreekVariants(word) {
  const variants = [word, word.toLowerCase(), '', ''];
  for (let i = 0; i < word.length; i = i + 1) {
    variants[2] = variants[2] + normalizeGreek(word.charAt(i));
    variants[3] = variants[3] + normalizeGreek(word.charAt(i)).toLowerCase();
  }
  return variants;
}
