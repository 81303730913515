import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext.jsx';
import { TextColorEnum, StoreSelector } from '@desquared/wave-components-library';
import { useState, useEffect, useContext } from 'react';
import { getShopsForDeliveryV3 } from '@/api/Shops';
import { Shop } from '@/store/shop';
import { GenericTimeslotsStore } from '@/store/timeslots';
import { FirstSelectedTimeslotStore } from '@/store/selectedTimeslot';
import { SelectedShopId } from '@/store/isAddressRegistered';
import { getStoresList, transformShops } from '@/utils/storeSelector';
import GOOGLE_API_KEY from '@/utils/googleAPIKey';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { DistanceContext } from '@/contexts/DistanceUomContext';

export default function StoreSelectorSheet(props) {
  const router = useRouter();
  const [filteredShops, setFilteredShops] = useState([]);
  const [modifiedShops, setModifiedShops] = useState([]);

  const [storesList, setStores] = useState([]);
  const [mapStores, setMapStores] = useState([]);
  const { selectedService, setSelectedShop, selectedShop, setSelectedTakeAwayStoreIndex, selectedTakeAwayStoreIndex } =
    useContext(DeliveryServicesContext);
  const { unitShorthand } = useContext(DistanceContext);
  const [loading, setLoading] = useState(true);
  const t = useTranslations('common.asides.store_selector');

  const shops = Shop.getValue()?.[selectedService?.id];
  const handleSelectStoreByIndex = shopIndex => {
    setSelectedTakeAwayStoreIndex(shopIndex);
    const newStore = storesList.find((i, idx) => idx === shopIndex);
    setSelectedShop(newStore);
  };

  function onChangeSearchInput(value) {
    const trimmedValue = value.trim().toLowerCase();

    const tempShops = modifiedShops.filter(shop => {
      if (trimmedValue.length > 1) {
        return shop.searchTerms.some(term => term.includes(trimmedValue));
      } else {
        return (
          shop.address.region.toLowerCase().includes(trimmedValue) ||
          shop.address.city.toLowerCase().includes(trimmedValue) ||
          shop.address.street.toLowerCase().includes(trimmedValue)
        );
      }
    });

    setFilteredShops(tempShops);
  }

  async function getSelectedServiceStores() {
    if (selectedService && Shop.getValue()?.[selectedService.id]) return Shop.getValue()?.[selectedService.id];

    const shops = await getShopsForDeliveryV3(selectedService?.id);
    if (selectedService?.id) Shop.setShopByDeliveryId(selectedService?.id, shops);
    return shops;
  }
  async function getSelectedServiceStoresInit() {
    const shops = await getShopsForDeliveryV3(selectedService?.id);
    if (selectedService?.id) Shop.setShopByDeliveryId(selectedService?.id, shops);
    return shops;
  }

  function getStores() {
    if (filteredShops?.length > 0) {
      setStores([]);
      setMapStores([]);

      const { enhancedMapStores, enhancedStores } = getStoresList(
        filteredShops,
        selectedTakeAwayStoreIndex,
        unitShorthand
      );

      setMapStores([...enhancedMapStores]);
      setStores([...enhancedStores]);
      if (selectedShop) {
        const selectedIndex = enhancedStores.findIndex(i => i?._id === selectedShop?._id);
        setSelectedTakeAwayStoreIndex(selectedIndex);
      }
    } else {
      setStores([]);
    }
  }

  useEffect(() => {
    getSelectedServiceStoresInit();
    if (selectedTakeAwayStoreIndex > shops?.length - 1) setSelectedTakeAwayStoreIndex(shops?.length - 1 ?? 0);
  }, []);

  useEffect(() => {
    getStores();
    //eslint-disable-next-line
  }, [filteredShops]);

  useEffect(() => {
    if (!shops) getSelectedServiceStores();
    function getBrowserLocation() {
      return new Promise((res, rej) => {
        if (navigator && navigator.geolocation) navigator.geolocation.getCurrentPosition(res, rej);
      });
    }

    async function createFormattedStores() {
      let transformedShops;
      try {
        const position = await getBrowserLocation();
        transformedShops = transformShops(shops, { lat: position.coords.latitude, lng: position.coords.longitude });
      } catch (e) {
        transformedShops = transformShops(shops);
      }

      return transformedShops;
    }

    createFormattedStores()
      .then(transformedShops => {
        setFilteredShops(transformedShops);
        setModifiedShops(transformedShops);
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }, [shops, setLoading, setFilteredShops, setModifiedShops]);

  useEffect(() => {
    if (selectedTakeAwayStoreIndex !== undefined && shops && storesList.length > 0) {
      let newSelectedStore;

      if (selectedTakeAwayStoreIndex > storesList.length - 1) {
        newSelectedStore = storesList[storesList.length - 1];
      } else {
        if (selectedTakeAwayStoreIndex < 0) {
          newSelectedStore = storesList[0];
        } else {
          newSelectedStore = storesList[selectedTakeAwayStoreIndex];
        }
      }
      let newSelectedShop = shops.find(shop => shop._id === newSelectedStore._id);

      if (!newSelectedShop?._id) {
        newSelectedShop = shops[0];
      }
      Shop.setTakeAwayShop(newSelectedShop);
      setSelectedShop(newSelectedShop);
      GenericTimeslotsStore.reset();
      FirstSelectedTimeslotStore.update(true);
      SelectedShopId.update(newSelectedShop._id);
      // Note: we probably shouldn't save the store post code as the user's - it messes up the calculation of distances
      // PostalCode.update(selectedShop.postalCode);
    } else {
      if (shops && storesList.length > 0) setSelectedTakeAwayStoreIndex(0);
    }
  }, [storesList, selectedTakeAwayStoreIndex]);

  return (
    <StoreSelector
      searchPlaceholder={t('search_placeholder')}
      loading={loading}
      onChange={onChangeSearchInput}
      setSelectedStore={handleSelectStoreByIndex}
      selectedIndex={selectedTakeAwayStoreIndex}
      listAddress={storesList}
      textColor={TextColorEnum.Gray1}
      stigmas={mapStores}
      logo={props.responsiveLogo}
      googleApiKey={GOOGLE_API_KEY}
      noSearchResults={t('no_results')}
      mapTilesLocale={router.locale}
      openStoreLabelWording={t('open')}
      closedStoreLabelWording={t('closed')}
      closingSoonStoreLabelWording={t('closing')}
    />
  );
}
