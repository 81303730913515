export function getValidObject(key, value) {
  return value ? { [key]: value } : {};
}

export function getUniqueValuesArray(_array) {
  return [...new Set(_array)];
}

export function resetDynamicObject(_object) {
  const _objectKeys = Object.keys(_object);
  if (_objectKeys.length < 1) {
    return {};
  }
  const resetObject = _objectKeys.reduce((accumulator, currentValue) => {
    return {
      ...accumulator,
      [currentValue]: [],
    };
  }, {});
  return resetObject;
}

export default { getValidObject };
