import layoutData from '../../../prebuild-data/layout-data.json';

//Read the prebuilt layout data and enhance them
export async function getLayoutProps(overrides, locale = 'el') {
  return {
    ...layoutData[locale],
    headerProps: { ...layoutData[locale].headerProps, ...(overrides?.headerProps || {}) },
    footerProps: {
      ...layoutData[locale].footerProps,
      ...(overrides?.footerProps || {}),
    },
  };
}

export default getLayoutProps;
