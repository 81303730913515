import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext.jsx';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { SideSheet } from '@desquared/wave-components-library';
import { SideSheetStore } from '@/store/sideSheet';
import StoreSelectorSheet from '@/components/StoreSelectorSheet/StoreSelectorSheet';
import DeliveryServicesSheet from '@/components/DeliveryServiceSheet/DeliveryServiceSheet';
import { useTranslations } from 'next-intl';
export default function Aside(props) {
  const [sideSheet, setSideSheet] = useState(false);

  const { selectedService } = useContext(DeliveryServicesContext);

  const refOne = useRef(null);

  const onClick = () => {
    setTimeout(() => {
      SideSheetStore.close();
    }, '300');
  };

  useEffect(() => {
    const SideSheetStore$ = SideSheetStore.subscribe(setSideSheet);

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      SideSheetStore$.unsubscribe();

      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      SideSheetStore.close();
    }
  };

  const shouldShowStoresSelection = !selectedService?.deliversToAddress;

  const t = useTranslations('common.asides');

  if (!sideSheet) return null;

  // useDisableBodyScroll();
  return (
    <div ref={refOne}>
      <SideSheet
        header={!shouldShowStoresSelection ? t('service_address.select_address') : t('service_address.select_store')}
        hasClose={true}
        onClose={onClick}
        buttonText={t('service_address.save_btn')}
        onSubmit={onClick}
        hasBack={props.hasBack}
        onBack={props.onBack}
      >
        {shouldShowStoresSelection ? (
          <StoreSelectorSheet responsiveLogo={props.responsiveLogo} />
        ) : (
          <DeliveryServicesSheet />
        )}
      </SideSheet>
    </div>
  );
}
