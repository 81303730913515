import Cookie from './../../src/store/cookies';
import cookies from './cookies';
import SortBy from './../../src/store/productsSort';
import { IsAddressRegistered } from './../../src/store/isAddressRegistered';
import { PostalCode } from './../../src/store/isAddressRegistered';
import cn from 'classnames';
import styles from '../components/CookiesPage/CookiesPage.module.css';
export function onClickSettings() {
  const cookiesUpdate = {
    necessary: true,
    performance: false,
    ad: false,
    ux: false,
  };
  Cookie.update(cookiesUpdate);
}

export function onClickAccept(option, setShowBanner) {
  if (option === 'all') {
    const cookiesUpdate = {
      necessary: true,
      performance: true,
      ad: true,
      ux: true,
    };
    Cookie.update(cookiesUpdate);
    setShowBanner(false);
    return;
  }
  const cookiesUpdate = {
    necessary: true,
    performance: false,
    ad: false,
    ux: false,
  };
  Cookie.update(cookiesUpdate);
  setShowBanner(false);
}

export const getCookieSwitchers = (props, cookiesOption, onChangePerformance, onChangeAd, onChangeUX) => {
  const COOKIES_SWITCHERS = [
    {
      isAvailable: true,
      isChecked: true,
      onChange: () => {},
      text: props.fields?.necessarytext,
      disabled: true,
      id: 0,
    },
    {
      isAvailable: true,
      isChecked: cookiesOption?.performance,
      onChange: onChangePerformance,
      text: props.fields?.performancetext,
      disabled: false,
      id: 1,
    },
    {
      isAvailable: true,
      isChecked: cookiesOption?.ad,
      onChange: onChangeAd,
      text: props.fields?.adtext,
      disabled: false,
      id: 2,
    },
    {
      isAvailable: true,
      isChecked: cookiesOption?.ux,
      onChange: onChangeUX,
      text: props.fields?.functionalitytext,
      disabled: false,
      id: 3,
    },
    //   {
    //     isAvailable: true,
    //     isChecked: cookiesOption?.thirdParty,
    //     onChange: onChangeThirdParty,
    //     text: props.fields?.thirdpartytext,
    //     disabled: false,
    //   },
  ];
  return COOKIES_SWITCHERS;
};
export const getTable = (elements, locale) => {
  const tempTable = {
    el: {
      cookiesArray: elements,
      comesFromWording: 'Προέρχεται από',
      nameWording: 'Όνομα',
      descriptionWording: 'Περιγραφή',
      durationWording: 'Χρονική διάρκεια',
    },
    en: {
      cookiesArray: elements,
      comesFromWording: 'Derives from',
      nameWording: 'Name',
      descriptionWording: 'Description',
      durationWording: 'Duration',
    },
  };
  return tempTable[locale];
};
export const getRichElement = element => {
  return <div className={cn(styles.richElement)} dangerouslySetInnerHTML={{ __html: element }}></div>;
};

export const getTotalFields = (
  cookiesEl,
  typeCookiesEl,
  checkCookiesEl,
  cookiesSettingsEl,
  props,
  cookiesOption,
  onChangePerformance,
  onChangeAd,
  onChangeUX,
  t,
  locale
) => {
  const totalFields = [
    {
      title: props.fields.cookiesgeneraltitle,
      richText: getRichElement(props.fields.cookiesgeneral),
      position: cookiesEl,
    },
    {
      title: props.fields.differentkindtitle,
      richText: getRichElement(props.fields.differentkind),
      position: typeCookiesEl,
    },
    {
      title: props.fields.necessarycookiestitle,
      richText: getRichElement(props.fields.necessarycookies),
      table: getTable(cookies[locale].cookieModelNecessary, locale),
    },
    {
      title: props.fields.performancecookiestitle,
      richText: getRichElement(props.fields.performancecookies),
      table: getTable(cookies[locale].cookiePerformance, locale),
    },
    {
      title: props.fields.functionalitycookiestitle,
      richText: getRichElement(props.fields.functionalitycookies),
      table: getTable(cookies[locale].cookieFunctional, locale),
    },
    {
      title: props.fields.adcookiestitle,
      richText: getRichElement(props.fields.adcookies),
      table: getTable(cookies[locale].cookieTargetedAd, locale),
    },
    { title: props.fields.googleanalyticstitle, richText: getRichElement(props.fields.googleanalytics) },
    {
      title: props.fields.acceptancecookiestitle,
      richText: getRichElement(props.fields.acceptancecookies),
      position: checkCookiesEl,
    },
    {
      title: props.fields.settingstitle,
      richText: <p>{t('preferences')}</p>,
      position: cookiesSettingsEl,
      switches: { toggles: getCookieSwitchers(props, cookiesOption, onChangePerformance, onChangeAd, onChangeUX) },
    },
  ];
  return totalFields;
};
export function setCookies(optionPressed, setCookiesOptions, cookiesOption) {
  if (optionPressed === 'acceptAll') {
    const cookiesUpdate = {
      necessary: true,
      performance: true,
      ad: true,
      ux: true,
    };
    setCookiesOptions(cookiesUpdate);
    Cookie.update(cookiesUpdate);
  }
  if (optionPressed === 'declineAll') {
    const cookiesUpdate = {
      necessary: true,
      performance: false,
      ad: false,
      ux: false,
    };
    setCookiesOptions(cookiesUpdate);
    Cookie.update(cookiesUpdate);

    SortBy.delete();
    IsAddressRegistered.delete();
    PostalCode.delete();
  }
  if (optionPressed === 'acceptSelected') {
    Cookie.update(cookiesOption);
  }
}

export const breadCrumbItems = t => [
  { title: t('breadcrumb_home'), link: '/' },
  { title: t('breadcrumb_statement'), link: '/cookies' },
];

export function getSidebarSection(props, cookiesEl, typeCookiesEl, checkCookiesEl, cookiesSettingsEl) {
  const tempSection = [
    { section: props.fields?.cookiesgeneraltitle, ref: cookiesEl },
    { section: props.fields?.differentkindtitle, ref: typeCookiesEl },
    { section: props.fields?.acceptancecookiestitle, ref: checkCookiesEl },
    { section: props.fields?.settingstitle, ref: cookiesSettingsEl },
  ];
  return tempSection;
}
