export function transfromTimeslots(inputData, t) {
  const allData = [];
  for (let i = 0; i < inputData?.length; i++) {
    const day = inputData[i];
    const outputData = {
      dayIndex: i,
      day: returnDay(day.date, t),
      date: returnDate(day.date, t),
      timeslots: [],
      disabled: false,
    };
    let hasAvailable = false;
    for (let j = 0; j < day.timeslots.length; j++) {
      const timeslot = day.timeslots[j];
      const startsAt = timeslot.startsAt;
      const endsAt = timeslot.endsAt;
      const isAvailable = timeslot.isAvailable;
      if (isAvailable) hasAvailable = true;
      const timeslotName = `${startsAt}-${endsAt}`;
      const timeslotObject = { name: timeslotName, unavailable: !isAvailable, timeslotIndex: j };

      outputData.timeslots.push(timeslotObject);
    }
    hasAvailable ? (outputData.disabled = false) : (outputData.disabled = true);
    allData.push(outputData);
  }
  return allData;
}

export function addDisabledDaysTimeslots(inputData, t) {
  const allData = [];
  for (let i = 0; i < inputData?.length; i++) {
    const day = inputData[i];
    const outputData = {
      date: day.date,
      timeslots: day.timeslots,
      disabled: false,
    };
    let hasAvailable = false;
    for (let j = 0; j < day.timeslots.length; j++) {
      const timeslot = day.timeslots[j];
      const isAvailable = timeslot.isAvailable;
      if (isAvailable) hasAvailable = true;
    }
    if (hasAvailable) {
      outputData.disabled = false;
    } else outputData.disabled = true;
    allData.push(outputData);
  }
  return allData;
}

export function isDateTomorrow(dateToCheck) {
  const now = new Date();
  const tomorrow = new Date(now);
  tomorrow.setDate(now.getDate() + 1);
  dateToCheck.setHours(0, 0, 0, 0);
  tomorrow.setHours(0, 0, 0, 0);
  return dateToCheck.getTime() === tomorrow.getTime();
}

export function returnDay(date, t) {
  const newDate = new Date(date);
  const currentDate = new Date();
  if (newDate.getDate() === currentDate.getDate()) return t('common.days.today');
  const tomorrow = isDateTomorrow(newDate);
  if (tomorrow) return t('common.days.tomorrow');
  const dayMap = {
    1: t('common.days.short_monday'),
    2: t('common.days.short_tuesday'),
    3: t('common.days.short_wednesday'),
    4: t('common.days.short_thursday'),
    5: t('common.days.short_friday'),
    6: t('common.days.short_saturday'),
    0: t('common.days.short_sunday'),
  };
  return dayMap[newDate.getDay()];
}

export function daytToFull(day, t) {
  switch (day) {
    case t('common.days.short_monday'):
      return t('common.days.monday');
    case t('common.days.short_tuesday'):
      return t('common.days.short_tuesday');
    case t('common.days.short_wednesday'):
      return t('common.days.wednesday');
    case t('common.days.short_thursday'):
      return t('common.days.thursday');
    case t('common.days.short_friday'):
      return t('common.days.friday');
    case t('common.days.short_saturday'):
      return t('common.days.saturday');
    case t('common.days.short_sunday'):
      return t('common.days.sunday');
    default:
      return day;
  }
}

export function returnDate(date, t) {
  const newDate = new Date(date);
  return `${('0' + newDate.getDate()).slice(-2)}/${('0' + (newDate.getMonth() + 1)).slice(-2)}`;
}

export default { transfromTimeslots, returnDate, returnDay, daytToFull, addDisabledDaysTimeslots };
