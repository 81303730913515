import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'universal-cookie';
import { ProfileMenu } from '@desquared/wave-components-library';
import LogoutModal from '../LogoutModal/LogoutModal';
import Avatar from '../../../public/assets/wave-grocery/Avatar.png';
import { getLoggedOutMobileMenu, getProfileMenuList, getSelectedBasedOnPath } from '@/utils/profileMenu';
import useWindowUnderThreshold from '@/hooks/useWindowUnderThreshold';
import { SideSheetStore, ResponsiveMenuStore } from '@/store/sideSheet';
import { useTranslations } from 'next-intl';
import { getUserV3 } from '@/api/users';
import User from '@/store/user';
import { addUserAvatar, deleteUserAvatar } from '@/api/wave/users';
import imageCompression from 'browser-image-compression';
import { toBase64 } from '@/utils/imageToBase64';
import { useToast } from '@/contexts/ToastContext';

export default function ProfileMenuSection(props) {
  const cookies = new Cookies();
  const { showToast } = useToast();

  const isMobile = useWindowUnderThreshold(950);
  const router = useRouter();
  const token = cookies.get('token');
  const [currentUser, setCurrentUser] = useState(props?.user);
  const [uploadFile, setUploadFile] = useState(null);
  const [logoutModal, setLogoutModal] = useState(false);
  const [userHasLoyaltyCard, setUserHasLoyaltyCard] = useState(!isEmpty(props.user?.loyaltyCards));

  const imageCompressionOptions = useMemo(
    () => ({
      maxSizeMB: 1,
      maxWidthOrHeight: 200,
      useWebWorker: true,
    }),
    []
  );

  const logoutClick = () => {
    setLogoutModal(true);
  };
  const logoutRedirect = '#';
  const selected = getSelectedBasedOnPath(router.pathname);
  const navigate = path => {
    router.push(path);
  };
  useEffect(() => {
    async function getCurrentUser(token) {
      const updatedUser = await getUserV3(token);

      // Error case
      if (isEmpty(updatedUser)) return;

      setCurrentUser({
        ...updatedUser,
        authToken: token,
      });
      User.update({
        ...updatedUser,
        authToken: token,
      });
      setUserHasLoyaltyCard(!isEmpty(updatedUser?.loyaltyCards));
    }
    if (token) {
      getCurrentUser(token);
    }
  }, [router.asPath]);

  useEffect(() => {
    const subscription = User.subscribe(user => {
      if (user && user.avatarURL) setUploadFile(user.avatarURL);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const t = useTranslations();

  function onClickUpload() {
    inputEl.current.click();
  }

  const onUploadFile = async event => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      if (!validImageTypes.includes(fileType)) {
        return;
      }

      try {
        const compressedFile = await imageCompression(file, imageCompressionOptions);

        const base64 = await toBase64(compressedFile);
        const res = await addUserAvatar(currentUser.authToken, base64);

        if (isEmpty(res)) {
          showToast(t('common.avatar.upload_error'));
          return;
        }

        User.update({ ...User.getValue(), avatarURL: base64 });
        showToast(t('common.avatar.upload_success'));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteAvatar = async () => {
    const response = await deleteUserAvatar(currentUser.authToken);
    if (response) {
      User.update({ ...User.getValue(), avatarURL: undefined });
      setUploadFile(undefined);
    } else {
      // Handle error case
    }
  };

  const inputEl = useRef();

  return (
    <>
      <LogoutModal showModal={logoutModal} setShowModal={setLogoutModal} />
      <ProfileMenu
        logoutButtonWording={t('common.user.profile.logout')}
        logoutOnClick={logoutClick}
        isLoggedIn={!!props.user}
        points={
          new Intl.NumberFormat('el-GR').format(props.user?.totalLoyaltyPoints) + ' ' + t('common.user.profile.points')
        }
        name={props.user ? props.user?.firstName : ''}
        profileImage={uploadFile ?? Avatar.src}
        hasLoyaltyPoints={props.user?.totalLoyaltyPoints > 0}
        loginButtonWording={t('common.user.profile.login_register')}
        loginRedirect={() => {
          SideSheetStore.close();

          router.push({
            pathname: '/login',
          });
          ResponsiveMenuStore.close();
        }}
        Items={
          props.user
            ? getProfileMenuList(
                selected,
                isMobile,
                logoutClick,
                logoutRedirect,
                userHasLoyaltyCard && process.env.hasLoyalty,
                navigate,
                props.config,
                t
              )
            : getLoggedOutMobileMenu(isMobile, t)
        }
        onChangeProfilePic={onUploadFile}
        inputEl={inputEl}
        onChangeProfilePicClick={onClickUpload}
        contextMenuItems={
          !!uploadFile && [
            {
              title: t('common.user.profile.avatar_context_menu.upload_new'),
              action: () => {
                onClickUpload();
              },
            },
            {
              title: t('common.user.profile.avatar_context_menu.delete'),
              action: async () => {
                await deleteAvatar();
              },
            },
          ]
        }
      />
    </>
  );
}
