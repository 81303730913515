import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext.jsx';
import { CheckPostcode, Button } from '@desquared/wave-components-library';
import React, { useContext, useEffect, useState } from 'react';
import GOOGLE_API_KEY from '@/utils/googleAPIKey';

import styles from './CheckPostalCodeSheet.module.css';
import { getShopsForDeliveryV3 } from '@/api/Shops';
import { transformToStigmas } from '@/utils/addressUtilities';
import { Shop } from '@/store/shop';
import { PostalCode } from '@/store/isAddressRegistered';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';

export default function CheckPostalCodeSheet({ config, logo }) {
  const router = useRouter();
  const [postalCode, setPostalCode] = useState('');
  const [successCode, setSuccessCode] = useState('');
  const [stigmas, setStigmas] = useState([]);
  const [defaultMapCenter, setDefaultMapCenter] = useState({
    lat: process.env.defaultMapCenter.lat,
    lon: process.env.defaultMapCenter.lng,
  });
  const t = useTranslations('common.asides.postal_check');
  const r = useTranslations();
  const [postalCodeError, setPostalCodeError] = useState(t('input_error'));
  const [hasError, setHasError] = useState(false);
  const { selectedService } = useContext(DeliveryServicesContext);

  const onPostalCodeChange = e => {
    setPostalCode(e);
    setSuccessCode('unknown');
    setPostalCodeError('');
    setHasError(false);
  };

  const onCheckClick = async () => {
    const numberIdNoSpace = postalCode.replace(/\s/g, '');
    const parsePattern = /^\d{5}$/;
    const isValid = numberIdNoSpace.match(parsePattern);
    if (isValid) {
      setPostalCodeError('');
      setHasError(false);

      submitPostalCode();
    } else {
      setHasError(true);
      setPostalCodeError(t('input_error'));
    }
  };

  useEffect(() => {
    const $shop = Shop.subscribe(shopData => {
      if (shopData?.selectedShop) {
        setDefaultMapCenter({
          lat: shopData.selectedShop.address.latitude,
          lon: shopData.selectedShop.address.longitude,
        });
      } else if (shopData?.takeAway) {
        setDefaultMapCenter({
          lat: shopData.takeAway[0].address.latitude,
          lon: shopData.takeAway[0].address.longitude,
        });
      }
    });

    const setUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            setDefaultMapCenter({ lat: latitude, lon: longitude });
          },
          error => {
            console.error('Error getting user location:', error);
          }
        );
      } else {
        console.warn('Geolocation is not supported by this browser.');
      }
    };

    setUserLocation();

    return () => {
      $shop.unsubscribe();
    };
  }, [setDefaultMapCenter]);

  const submitPostalCode = async () => {
    const shops = await getShopsForDeliveryV3(selectedService?.id, postalCode);
    Shop.setShopByDeliveryId(selectedService?.id, shops);

    if (!shops || shops?.length === 0) {
      setSuccessCode('error');
      PostalCode.update(postalCode);
    } else {
      setSuccessCode('success');
      PostalCode.update(postalCode);
      const newStigmas = transformToStigmas(shops);
      setStigmas(newStigmas);
    }
  };
  return (
    <>
      <CheckPostcode
        successCode={successCode}
        stigmas={stigmas}
        description={t('description')}
        label={t('input_label')}
        postCode={postalCode}
        successText={t('success', { serviceTitle: selectedService?.title })}
        errorText={t('error_text')}
        onClick={onPostalCodeChange}
        inputErrorText={postalCodeError}
        inputErrorTextType={hasError ? 'error' : 'success'}
        googleApiKey={GOOGLE_API_KEY}
        minLength={config?.fields?.address?.postalCode?.minLength ?? 0}
        maxLength={config?.fields?.address?.postalCode?.maxLength ?? 5}
        format={config?.fields?.address?.postalCode?.format ?? 'NUMERIC'}
        minLengthErrorText={r('common.minimun_character')}
        mapTilesLocale={router.locale}
        defaultCenter={defaultMapCenter}
        logo={logo}
      />
      <div className={styles.footer}>
        <Button
          onClick={() => {
            onCheckClick();
          }}
          text={t('btn_label')}
        />
      </div>
    </>
  );
}
