import { useState, useEffect } from 'react';
import { useTranslations } from 'next-intl';
import Cookie from '../../store/cookies';
import { CookieBanner } from '@desquared/wave-components-library';
import jsonStorage from '../../utils/json-storage';
import { onClickSettings, onClickAccept } from '@/utils/useCookies';
export default function CookieBar() {
  const [showBanner, setShowBanner] = useState(false);
  const t = useTranslations('common');

  useEffect(() => {
    const storage = window.localStorage;

    const privacyNew = jsonStorage.get(`${process.env.appName}:privacy`, { storage });

    if (!privacyNew) {
      setShowBanner(true);
    } else {
      const updatedCookies = privacyNew;
      Cookie.update(updatedCookies);
    }
    const cookies$ = Cookie.subscribe();
    return () => cookies$.unsubscribe();
  }, []);

  return (
    showBanner && (
      <CookieBanner
        content={t('cookies.content')}
        settingsWording={t(`cookies.settings`)}
        settingsRedirect="/cookies"
        onClickSettings={onClickSettings}
        acceptButtonWording={t(`cookies.accept`)}
        declineButtonWording={t(`cookies.decline`)}
        onAcceptButtonClick={() => onClickAccept('all', setShowBanner)}
        onDeclineButtonClick={() => onClickAccept('necessary', setShowBanner)}
      ></CookieBanner>
    )
  );
}
