import {
  ShoppingBag,
  ProfileMenuCard,
  ProfileMenuLists,
  ProfileMenuGifts,
  ProfileMenuLocation,
  ProfileMenuLogout,
  ProfileMenuLoyaltyCard,
  ProfileMenuUser,
  ShoppingBagFilled,
  ProfileMenuCardFilled,
  ProfileMenuListsFilled,
  ProfileMenuGiftsFilled,
  ProfileMenuLocationFilled,
  ProfileMenuLoyaltyCardFilled,
  ProfileMenuUserFilled,
} from '@desquared/wave-components-library';
import { CompanyAsideSideSheetStore } from '@/store/sideSheet';
export const getProfileMenuList = (
  mySelected,
  myIsMobile,
  myLogoutClick,
  myLogoutRedirect,
  hasLoyalty,
  navigate,
  config,
  t
) => {
  const userCredentialsWording = t('common.user.profile.menu.user_credentials');
  const myCredentialsWording = t('common.user.profile.menu.my_credentials');
  const myAdressesWording = t('common.user.profile.menu.my_addresses');
  const myCardsWording = t('common.user.profile.menu.my_cards');
  const myLoyaltyCardWording = t('common.user.profile.menu.my_loyalty');
  const forYouWording = t('common.user.profile.menu.for_you');
  const myOrdersWording = t('common.user.profile.menu.my_orders');
  const myListsWording = t('common.user.profile.menu.my_lists');
  const myGiftsWording = t('common.user.profile.menu.my_gifts');
  const companyWording = t('common.user.profile.menu.company');
  const companyImageWording = t('common.user.profile.menu.company_image');

  const logoutString = t('common.user.profile.menu.logout');
  const myCredentialsRedirect = '/user/credentials';
  const myAdressesRedirect = '/user/addresses';
  const myCardsRedirect = '/user/cards';
  const myLoyaltyCardRedirect = '/user/loyalty';
  const myListsRedirect = '/user/lists';
  const myGiftsRedirect = '/user/gifts';
  const myOrdersRedirect = '/user/history';
  const isMobile = myIsMobile;
  const selected = mySelected;
  const userCredentials =
    config?.loyalty?.enabled && hasLoyalty
      ? [
          {
            text: myCredentialsWording,
            icon: selected === 'credentials' ? ProfileMenuUserFilled : ProfileMenuUser,
            selected: selected === 'credentials',
            onClick: () => navigate(myCredentialsRedirect),
          },
          {
            text: myAdressesWording,
            icon: selected === 'addresses' ? ProfileMenuLocationFilled : ProfileMenuLocation,
            selected: selected === 'addresses',
            onClick: () => navigate(myAdressesRedirect),
          },
          {
            text: myCardsWording,
            icon: selected === 'cards' ? ProfileMenuCardFilled : ProfileMenuCard,
            selected: selected === 'cards',
            onClick: () => navigate(myCardsRedirect),
          },
          {
            text: myLoyaltyCardWording,
            icon: selected === 'loyalty' ? ProfileMenuLoyaltyCardFilled : ProfileMenuLoyaltyCard,
            selected: selected === 'loyalty',
            onClick: () => navigate(myLoyaltyCardRedirect || ''),
          },
        ]
      : [
          {
            text: myCredentialsWording,
            icon: selected === 'credentials' ? ProfileMenuUserFilled : ProfileMenuUser,
            selected: selected === 'credentials',
            onClick: () => navigate(myCredentialsRedirect),
          },
          {
            text: myAdressesWording,
            icon: selected === 'addresses' ? ProfileMenuLocationFilled : ProfileMenuLocation,
            selected: selected === 'addresses',
            onClick: () => navigate(myAdressesRedirect),
          },
          {
            text: myCardsWording,
            icon: selected === 'cards' ? ProfileMenuCardFilled : ProfileMenuCard,
            selected: selected === 'cards',
            onClick: () => navigate(myCardsRedirect),
          },
        ];
  const forYou = [
    {
      text: myOrdersWording,
      icon: selected === 'orders' ? ShoppingBagFilled : ShoppingBag,
      selected: selected === 'orders',
      onClick: () => navigate(myOrdersRedirect),
    },
    {
      text: myListsWording,
      icon: selected === 'lists' ? ProfileMenuListsFilled : ProfileMenuLists,
      selected: selected === 'lists',
      onClick: () => navigate(myListsRedirect),
    },
  ];
  if (config?.giftOrder?.enabled) {
    forYou.push({
      text: myGiftsWording,
      icon: selected === 'gifts' ? ProfileMenuGiftsFilled : ProfileMenuGifts,
      selected: selected === 'gifts',
      onClick: () => navigate(myGiftsRedirect),
    });
  }
  function onCompanyAsideClick() {
    CompanyAsideSideSheetStore.update(true);
  }
  const company = [
    {
      text: companyImageWording,
      icon: selected === 'lists' ? ProfileMenuListsFilled : ProfileMenuLists,

      onClick: onCompanyAsideClick,
    },
  ];
  const lists = [
    {
      title: userCredentialsWording,
      Items: userCredentials,
      isMobile: isMobile,
    },
    { title: forYouWording, Items: forYou, isMobile: isMobile },
  ];
  isMobile
    ? lists.unshift({ title: companyWording, Items: company, isMobile: isMobile })
    : lists.push({
        Items: [
          {
            text: logoutString,
            icon: ProfileMenuLogout,
            onClick: myLogoutClick,
            selected: false,
          },
        ],
        isMobile: isMobile,
      });
  return lists;
};

export const getLoggedOutMobileMenu = (isMobile, t) => {
  const lists = [];
  const companyWording = t('common.user.profile.menu.company');
  const companyImageWording = t('common.user.profile.menu.company_image');
  const company = [
    {
      text: companyWording,
      icon: ProfileMenuLists,

      onClick: onCompanyAsideClick,
    },
  ];
  function onCompanyAsideClick() {
    CompanyAsideSideSheetStore.update(true);
  }
  lists.push({ title: companyImageWording, Items: company, isMobile: isMobile });

  return lists;
};

export const getSelectedBasedOnPath = path => {
  switch (path) {
    case '/user/credentials':
      return 'credentials';
    case '/user/addresses':
      return 'addresses';
    case '/user/cards':
      return 'cards';
    case '/user/history':
    case '/user/history/details':
      return 'orders';
    case '/user/loyalty':
      return 'loyalty';
    case '/user/lists':
    case '/user/lists/list':
      return 'lists';
    case '/user/gifts':
      return 'gifts';
    default:
      return '';
  }
};
