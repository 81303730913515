import { Globe, LoginLogout, ProfileFilled, GRFlag, USFlag, GBFlag, CNflag } from '@desquared/wave-components-library';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useEffect, useState, useMemo } from 'react';

export function useActionItems(setLogoutModal) {
  const router = useRouter();
  const t = useTranslations('common');
  const [actionItems, setActionItems] = useState({
    items: [],
  });
  const [loggedOutActionItems, setLoggedOutActionItems] = useState({
    items: [],
  });
  const [localeWording, setLocaleWording] = useState(t(`locales.${router.locale}`));

  const flagMap = useMemo(() => {
    const usFlagTenants = ['khglatt', 'lakeview'];

    return {
      el: GRFlag,
      en: usFlagTenants.includes(process.env.tenant) ? USFlag : GBFlag,
      cn: CNflag,
    };
  }, []);

  useEffect(() => {
    let loggedInItems = [
      {
        onClick: setShowMenu => {
          router.push('/user/credentials');
          setShowMenu(false);
        },
        wording: t('action_items.logged_in.my_acount'),
        icon: ProfileFilled,
      },
      {
        onClick: setShowMenu => {
          setLogoutModal(true);
          setShowMenu(false);
        },
        wording: t('action_items.logged_in.logout'),
        icon: LoginLogout,
      },
    ];

    let loggedOutItems = [
      {
        onClick: setShowMenu => {
          setShowMenu(false);
          router.push('/login');
        },
        wording: t('action_items.visitor.login_register'),
        icon: LoginLogout,
      },
    ];

    if (router.locales.length > 1) {
      loggedInItems.splice(1, 0, {
        onClick: () => {},
        wording: localeWording,
        icon: Globe,
        items: router.locales.map(locale => ({
          onClick: setShowMenu => {
            router.push(router.asPath, router.asPath, { locale: locale });
            setShowMenu(false);
          },
          wording: t('locales.' + locale),
          icon: flagMap[locale],
        })),
      });

      loggedOutItems.splice(1, 0, {
        onClick: () => {},
        wording: localeWording,
        icon: Globe,
        items: router.locales.map(locale => ({
          onClick: setShowMenu => {
            router.push(router.asPath, router.asPath, { locale: locale });
            setShowMenu(false);
          },
          wording: t('locales.' + locale),
          icon: flagMap[locale],
        })),
      });
    }

    setActionItems({ items: loggedInItems });
    setLoggedOutActionItems({ items: loggedOutItems });
  }, [router.locale, router.asPath, setLogoutModal, localeWording, t, router]);

  useEffect(() => {
    setLocaleWording(t(`locales.${router.locale}`));
  }, [router.locale, setLocaleWording, t]);

  return {
    actionItems,
    loggedOutActionItems,
  };
}
