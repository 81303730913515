import { wave } from './config';

export async function getSuggestionsV31(query, collectionTypes = ['Common']) {
  try {
    const response = await wave.get('/v3.1/products/search/suggestions', {
      params: {
        collectionTypes,
        term: query,
      },
    });
    return response.data;
  } catch (error) {
    return { error };
  }
}

export async function getSearchResultsV31(resource) {
  try {
    const response = await wave.get('/v3.1/products/search', {
      params: {
        pageNumber: '0',
        ...resource,
      },
    });
    return response.data;
  } catch (error) {
    return { error };
  }
}
