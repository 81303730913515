import { config } from '../../prebuild-data/config';

//Read the prebuilt layout data and enhance them
export function getSchema() {
  const tenant = config.pathNameTenant ? config.pathNameTenant.replace('-', '') : '';
  const env = config?.pathNameEnv || '';

  return env === 'production' ? tenant : `${tenant}-${env}`;
}

export function getProductIdFromPath(path) {
  var lastIndex = path.lastIndexOf('-');
  return path.substring(lastIndex + 1, path.length - 1);
}

export default getSchema;
