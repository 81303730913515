import { getBundlesSummary } from '@/api/bundle-offers';
import BundeOffersStore from '@/store/bundleOffers';

export const getOrFetchBundleOffers = async locale => {
  if (BundeOffersStore.isFetched()) {
    return BundeOffersStore.getValue();
  }
  return updateBundleOffers(locale);
};

export const updateBundleOffers = async locale => {
  const bundles = await getBundlesSummary(locale);
  BundeOffersStore.update(bundles);
  return BundeOffersStore.getValue();
};

export default { getOrFetchBundleOffers, updateBundleOffers };
