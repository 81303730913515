import { BehaviorSubject } from 'rxjs';

const isAddListSnackBarOpenSubject = new BehaviorSubject(false);

export const isAddListSnackBarOpen = {
  update: value => {
    isAddListSnackBarOpenSubject.next(value);
  },
  subscribe: setState => isAddListSnackBarOpenSubject.subscribe(setState),
  getValue: () => isAddListSnackBarOpenSubject.value,
};

export default { isAddListSnackBarOpen };
