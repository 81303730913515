import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getLayoutProps } from '@/utils/layout/layout';
import DefaultProductImage from '../../public/assets/wave-grocery/Empty-States-Default/DefaultProductImage.png';

export default function useGetDefaultProductImage(selectedIndex = -1) {
  const [defaultProductImage, setDefaultProductImage] = useState(DefaultProductImage.src);
  const router = useRouter();

  useEffect(() => {
    const layoutPropsFunc = async () => {
      const layoutProps = await getLayoutProps({ headerProps: { selectedIndex } }, router.locale);
      const defaultProdImg = layoutProps?.emptyStates?.default_product_image?.url || DefaultProductImage.src;
      setDefaultProductImage(defaultProdImg);
    };

    layoutPropsFunc();
  }, [router.locale, selectedIndex]);

  return defaultProductImage;
}
