import axios from 'axios';
import get from 'lodash/get';

import { wave } from '../config';

const baseURL = `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/api/users`;
const baseURLV3 = `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/api/v3/users`;
const USERS = axios.create({
  baseURL,
  headers: {
    appId: process.env.appId,
  },
});

export async function singUp(body) {
  try {
    const response = await USERS.post('/register', {
      ...body,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error.response.data);
  }
}

export async function createAddressUser(authorization, addressToCreate) {
  let response;
  try {
    response = await axios.post(`${baseURL}/address/create`, addressToCreate, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });

    const address = get(response, 'data.payload.users.address[0]');
    if (!address) {
      return {
        address,
        error: 'Η διεύθυνσή σας δεν ήταν δυνατό να δημιουργηθεί.Παρακαλώ προσπαθήστε αργότερα.',
      };
    }
    return { address };
  } catch (error) {
    const _error = get(
      response,
      'data.error.userMessage',
      'Η διεύθυνσή σας δεν ήταν δυνατό να δημιουργηθεί.Παρακαλώ προσπαθήστε αργότερα.'
    );
    return { error: _error };
  }
}

export async function updateUser(authorization, user) {
  try {
    const response = await axios.post(`${baseURL}/update`, user, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });
    const res = get(response, 'data.payload.users');
    const { users, verification } = response.data.payload;
    if (users) {
      const newUser = { ...res, token: authorization };
      return newUser;
    }
    if (verification) {
      return verification;
    }
    return 'Παρακαλώ προσπαθήστε αργότερα';
  } catch (error) {
    return error?.response?.data;
  }
}

export async function getUser(authorization) {
  try {
    const response = await axios.get(`${baseURL}/card`, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });

    const userFetched = response?.data?.payload?.users;
    if (!userFetched) return 'Παρακαλώ προσπαθήστε αργότερα';

    const userReduce = { ...userFetched, token: authorization };

    if (process.env.NODE_ENV === 'production' && process.env.appName === 'kritikos') {
      updateUserInContactPigeon(userReduce.email);
    }

    return userReduce;
  } catch (error) {
    captureException(error);
    return {};
  }
}
export async function getUserV3(authorization) {
  try {
    const response = await axios.get(`${baseURLV3}/me`, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });

    const userFetched = response?.data;
    if (!userFetched) return 'Παρακαλώ προσπαθήστε αργότερα';

    const userReduce = { ...userFetched, token: authorization };

    // if (process.env.NODE_ENV === 'production' && process.env.appName === 'kritikos') {
    //   updateUserInContactPigeon(userReduce.email);
    // }

    return userReduce;
  } catch (error) {
    return {};
  }
}

export async function addUserAvatar(authorization, avatar) {
  const body = { encodedImage: avatar };
  try {
    const res = await axios.post(`${baseURLV3}/me/avatar`, body, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });

    return res;
  } catch (e) {
    return {};
  }
}

export async function deleteUserAvatar(authorization) {
  try {
    const res = await axios.delete(`${baseURLV3}/me/avatar`, {
      headers: { appId: process.env.appId, authorization: `Bearer ${authorization}` },
    });

    return res.status === 204;
  } catch (e) {
    return false;
  }
}

export async function addNewCard(authorization, card) {
  try {
    const response = await axios.post(`${baseURL}/loyaltycard/add`, card, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });
    const users = get(response, 'data.payload.users');
    if (users) {
      const newUser = { ...users, token: authorization };
      return newUser;
    }
    return 'Παρακαλώ προσπαθήστε αργότερα';
  } catch (error) {
    return null;
  }
}

export async function verifyCode(code) {
  try {
    const response = await USERS.post('verify/phone', code);
    const userEmail = get(response, 'data.payload.users.email', '');
    window.cpaE = userEmail;
    if (window.cpaEv) {
      window.cpaEv();
    }
    return response.data.payload.users;
  } catch (error) {
    return error?.response?.data;
  }
}

export async function getUserLists(userID, authorization) {
  try {
    const response = await wave.get(`/v2/users/${userID}/lists`, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });
    return { data: response?.data?.payload || [] };
  } catch (error) {
    return { data: [], error };
  }
}

export async function updateUserLists(userId, body, authorization) {
  try {
    const response = await wave.put(`/v2/users/${userId}/favorites`, body, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });
    const list = response?.data?.payload;
    return { list };
  } catch (error) {
    return { list: [], error };
  }
}

export default {
  singUp,
  createAddressUser,
  updateUser,
  addNewCard,
  verifyCode,
  getUserLists,
  updateUserLists,
};
