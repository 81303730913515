import { DEFAULT_LOCALE } from '@/constants/locale.js';
import { wave } from './config';

export async function getBundlesSummary() {
  const { data } = await wave.get(`/v3/bundle-offers/summary`);
  return data;
}

export async function checkBundleOffer(id, products) {
  const body = {
    products: products,
  };

  const headers = {
    appId: process.env.appId,
    'Content-Type': 'application/json',
  };

  const { data } = await wave.post(
    `/v3/bundle-offers/${id}/check`,
    {
      products: products,
    },
    { headers }
  );
  return data;
}
