import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext.jsx';
import Prebook from '@/store/prebook.js';
import { formatPrebookDate, getCurrentDayTitle } from '@/utils/dates/dates.js';
import { ResponsiveMenu } from '@desquared/wave-components-library';
import { useRouter } from 'next/router';
import React, { useEffect, useState, useContext, useMemo, useCallback, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import imageCompression from 'browser-image-compression';
import { toBase64 } from '@/utils/imageToBase64';

import {
  SideSheetStore,
  ResponsiveMenuStore,
  ServiceAddressSideSheetStore,
  InitPickupMethodStore,
  PrebookSideSheetStore,
} from '@/store/sideSheet';
import User from '../../store/user';
import { Shop } from '@/store/shop';
import { menuListStore } from '@/store/headerFooter';
import { PostalCode } from '@/store/isAddressRegistered';
import { HeaderMessageStore } from '@/store/headerMessage';
import { useTranslations } from 'use-intl';
import { usePrebook } from '@/hooks/usePrebook';
import { addUserAvatar, deleteUserAvatar } from '@/api/wave/users';
import Cookies from 'universal-cookie';
import { useToast } from '@/contexts/ToastContext';
import { useDateFormatting } from '@/contexts/DateFormattingProvider';

const cookies = new Cookies();

export default function MobileMenu(props) {
  const token = cookies.get('token');
  const inputRef = useRef();
  const router = useRouter();
  const t = useTranslations();
  const { showToast } = useToast();
  const [prebook, setPrebook] = useState();
  const [address, setAddress] = useState(undefined);
  const [responsiveMenu, setMenu] = useState(false);
  const [sideSheet, setSideSheet] = useState(false);
  const [postalCode, setPostalCode] = useState(props.user);
  const [menuList, setMenuList] = useState([]);
  const [avatar, setAvatar] = useState();
  const { timeFormat } = useDateFormatting();

  const { hasPredictiveTimeslot, fetchTimeSlotsPerService } = usePrebook(Prebook);

  const languangeList = useMemo(() => {
    return router.locales.map(locale => ({
      text: locale.toUpperCase(),
      isSelected: true,
      isDark: false,
      languageChangeOnClick: () => {
        router.push(router.asPath, router.asPath, { locale });
      },
    }));
  }, [router]);

  const {
    updateSelectedDeliveryService,
    addressPickerStatus,
    selectedService,
    selectedShop,
    selectedUserAddressIndex,
  } = useContext(DeliveryServicesContext);
  const isUserLoggedIn = props?.user?._id;
  const getTimePickerText = () => {
    if (!isUserLoggedIn) return undefined;

    if (hasPredictiveTimeslot) {
      return props.message + ' ' + props.timeslotsSecondText;
    }

    if (isPrebookingEnabled) {
      const hasSelectedTimeslot = prebook?.reservation;
      if (hasSelectedTimeslot) {
        return `${getCurrentDayTitle(
          prebook?.reservation?.startsAt,
          router.locale,
          t('common.days.today')
        )} ${formatPrebookDate(prebook.reservation?.endsAt)}, ${formatPrebookDate(
          prebook?.reservation.startsAt,
          timeFormat
        )} -  ${formatPrebookDate(prebook?.reservation.endsAt, timeFormat)}`;
      }

      return t('common.timeslots.as_soon_as_possible');
    }

    return `${props.message}${props.timeslotsSecondText ? ',' + props.timeslotsSecondText : ''}`;
  };
  useEffect(() => {
    ResponsiveMenuStore.close();
  }, [router.asPath]);

  const getSelectedServiceStatus = () => {
    if (!selectedService) return 'unselected';

    if (!selectedService?.deliversToAddress) {
      return 'take away';
    }

    if (selectedService?.deliversToAddress) {
      return 'delivery';
    }

    return 'unselected';
  };

  const onService = () => {
    let tempStore = Shop.getValue();
    InitPickupMethodStore.update(addressPickerStatus);
    selectedService?.deliversToAddress
      ? updateSelectedDeliveryService(!addressPickerStatus)
      : updateSelectedDeliveryService(true);
    selectedService?.deliversToAddress
      ? tempStore?.easyShop?.postalCode
        ? PostalCode.update(tempStore?.easyShop?.postalCode)
        : null
      : tempStore?.takeAwayShop?.postalCode
      ? PostalCode.update(tempStore?.takeAwayShop?.postalCode)
      : null;
  };

  const onClick = () => {
    ResponsiveMenuStore.close();
  };
  const openServiceAddressSidesheet = () => {
    ServiceAddressSideSheetStore.open();
  };

  useEffect(() => {
    const userSubscription = User.subscribe(user => {
      if (user && user.avatarURL) setAvatar(user.avatarURL);
      else setAvatar(undefined);
    });

    return () => {
      userSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedService) && !isEmpty(selectedShop) && !isEmpty(postalCode)) {
      fetchTimeSlotsPerService(selectedService.id, selectedShop._id, postalCode);
    }
  }, [postalCode, selectedService, selectedShop]);

  useEffect(() => {
    const ResponsiveMenuStore$ = ResponsiveMenuStore.subscribe(setMenu);
    const SideSheetStore$ = SideSheetStore.subscribe(setSideSheet);
    const menuStore$ = menuListStore.subscribe(setMenuList);
    const postalCode$ = PostalCode.subscribe(postal => {
      setPostalCode(postal);
    });

    const PrebookStore$ = Prebook.subscribe(setPrebook);

    switch (addressPickerStatus) {
      case 1:
        //setStatus('delivery');
        let tempAddress;
        if (!isEmpty(props.guestUser)) {
          if (props.guestUser?.address) {
            tempAddress = props.guestUser?.address[0];
          } else {
            {
              tempAddress = {};
            }
          }
        } else {
          tempAddress = User.getUserMainAddress();
        }
        if (!isEmpty(tempAddress)) {
          setAddress(tempAddress.street + ' ' + tempAddress.streetNo);
        } else {
          if (postalCode) {
            setAddress(postalCode);
            // setStatus('pin');
          } else {
            setAddress(t('common.header.see_how'));
          }
        }
        break;

      case 0:
        //setStatus('take away');
        let tempStore = Shop.getValue();
        if (tempStore?.takeAwayShop?.title) {
          setAddress('Κατάστημα ' + tempStore.takeAwayShop?.title.slice(0, tempStore.takeAwayShop?.title.indexOf(' ')));
        } else {
          setAddress(t('common.header.see_how'));
        }

        break;
    }

    return () => {
      ResponsiveMenuStore$.unsubscribe();
      SideSheetStore$.unsubscribe();
      menuStore$.unsubscribe();
      postalCode$.unsubscribe();
      PrebookStore$.unsubscribe();
    };
  }, [
    addressPickerStatus,
    address,
    sideSheet,
    menuList,
    props.user,
    postalCode,
    HeaderMessageStore.getValue(),
    selectedShop,
  ]);
  const isPrebookingEnabled = selectedService?.timeslots?.prebook.enabled;

  const getSelectedServiceText = () => {
    if (!selectedService) return t('common.header.choose_service');

    if (!selectedService?.deliversToAddress) {
      return selectedShop ? selectedShop.name : selectedService?.title;
    }

    if (selectedService?.deliversToAddress) {
      if (isEmpty(props.user) && isEmpty(props.guestUser)) {
        return PostalCode.getValue()
          ? `${t('common.header.postal_code')}` + PostalCode.getValue()
          : selectedService.title;
      } else {
        const selectedAddress = props?.user?.addresses?.[selectedUserAddressIndex];
        return selectedAddress ? `${selectedAddress?.street} ${selectedAddress?.streetNo}` : selectedService.title;
      }
    }

    return selectedService.title;
  };

  const deleteAvatar = useCallback(async () => {
    const response = await deleteUserAvatar(token);
    if (response) {
      User.update({ ...User.getValue(), avatarURL: undefined });
      setAvatar(undefined);
    } else {
      // Handle error case
    }
  }, []);

  const handleUploadImage = useCallback(async e => {
    const file = e.target.files?.[0];

    if (!file) return;

    const imageCompressionOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 200,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, imageCompressionOptions);

      const base64 = await toBase64(compressedFile);
      const res = await addUserAvatar(token, base64);

      if (isEmpty(res)) {
        showToast(t('common.avatar.upload_error'));
        return;
      }

      User.update({ ...User.getValue(), avatarURL: base64 });
      showToast(t('common.avatar.upload_success'));
    } catch (error) {
      console.log(error);
    }
  }, []);

  const avatarContextMenuItems = useMemo(() => {
    if (!avatar) {
      return [{ action: () => inputRef.current.click(), title: 'Ανέβασμα νέας εικόνας' }];
    } else {
      return [
        {
          action: () => inputRef.current.click(),
          title: 'Ανέβασμα νέας εικόνας',
        },
        {
          action: async () => {
            await deleteAvatar();
          },
          title: 'Διαγραφή εικόνας',
        },
      ];
    }
  }, [avatar, deleteAvatar]);

  if (!responsiveMenu) return null;

  return (
    <ResponsiveMenu
      isServicePickerDeliveryDisabled={false}
      isServicePickerTakeAwayDisabled={false}
      isServicePickerSelected={!addressPickerStatus}
      isAddressPickerSelected={false}
      addresstext={getSelectedServiceText()}
      addressPickerStatus={getSelectedServiceStatus()}
      addressPickerTextColor={process.env.addressPickerTextColor}
      isProfileSelected={false}
      isProfileFilled={false}
      profileTextColor={process.env.profileTextColor}
      hasProfilePic={!!avatar}
      profileAvatar={avatar}
      contextMenuItems={avatarContextMenuItems}
      timePickerTextColor={process.env.timePickerTextColor}
      timePickerText={getTimePickerText()}
      onTimePickerSelect={() => {
        isPrebookingEnabled && PrebookSideSheetStore.open();
      }}
      isTimePickerSelected={false}
      onAddressSelect={openServiceAddressSidesheet}
      onServiceSelect={onService}
      onClose={onClick}
      header={t('common.asides.responsive_menu.service_header')}
      languangeList={router.locales.length > 1 ? languangeList : []}
      profileText={props?.user ? t('common.asides.responsive_menu.header', { name: props?.user?.firstName }) : ''}
    >
      <input
        type="file"
        name="avatar"
        id="avatar"
        ref={inputRef}
        style={{ display: 'none' }}
        accept="image/*"
        multiple={false}
        onChange={handleUploadImage}
      />
      {props.children}
    </ResponsiveMenu>
  );
}
