import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ActionModal } from '@desquared/wave-components-library';
import CUSTOM_STYLES from '@/utils/modalStyle';
import { ListLoginModalStore } from '@/store/listLoginModal';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import getLayoutProps from '@/utils/layout/layout';
import Router from 'next/router';

export default function ListLoginModal({ children }) {
  const router = useRouter();
  const t = useTranslations();
  const [image, setImage] = useState('/assets/wave-grocery/Empty-States-Default/Smart-Lists.png');

  const [open, setOpen] = useState(false);
  useEffect(() => {
    const listLoginModal$ = ListLoginModalStore.subscribe(setOpen);
    async function getProps() {
      const layoutProps = await getLayoutProps({ headerProps: { selectedIndex: -1 } }, Router.locale);
      const image = layoutProps?.emptyStates?.smart_lists_empty_state?.url
        ? layoutProps?.emptyStates?.smart_lists_empty_state?.url
        : '/assets/wave-grocery/Empty-States-Default/Smart-Lists.png';
      setImage(image);
    }
    getProps();
    return () => {
      listLoginModal$.unsubscribe();
    };
  }, []);

  const img = {
    url: image,
    ariaLabel: 'login_list',
    size: 'medium',
  };
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => ListLoginModalStore.close()}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      style={CUSTOM_STYLES}
      ariaHideApp={false}
      onAfterClose={ListLoginModalStore.close}
    >
      <ActionModal
        title={t('common.asides.list_login.title')}
        text={t('common.asides.list_login.description')}
        hasCenteredText={true}
        hasInlineButtons={true}
        actions={[
          {
            btnLabel: t('common.asides.list_login.register'),
            onClick: () => {
              router.replace('/register');
            },

            btnVariant: 'outlined',
          },
          {
            btnLabel: t('common.asides.list_login.login'),
            onClick: () => {
              router.replace('/login');
            },
          },
        ]}
        img={img}
      />
    </Modal>
  );
}
