import { setCategories } from '@/utils/homePageCmsCall.js';
import { Footer } from '@desquared/wave-components-library';
import React, { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { ModalContext } from '@/contexts/ModalProvider.jsx';
import { submitNewsletterSubscribe } from '@/utils/contactPigeon';
import { setSocial } from '@/store/headerFooter';
import { useTranslations } from 'next-intl';
import getLayoutProps from '@/utils/layout/layout';
import Router from 'next/router';

export default function Footer1({
  socialList,
  nameHolder,
  logo,
  languanegList,
  //phone,
  cmsCategories,
  newsLetter,
  footerCategories,
}) {
  const router = useRouter();
  const { openActionModal, close } = useContext(ModalContext);
  const [newsLetterEmail, setNewsLetterEmail] = useState('');
  const [newsLetterImage, setNewsLetterImage] = useState();
  const [hasNewsletter, setHasNewsletter] = useState();
  const t = useTranslations('common');

  const formattedCmsCategories = setCategories(cmsCategories);
  const formattedSocialList = setSocial(socialList);
  useEffect(() => {
    async function getProps() {
      const layoutProps = await getLayoutProps({ headerProps: { selectedIndex: -1 } }, Router.locale);
      const image = layoutProps?.emptyStates?.email_empty_state?.url
        ? layoutProps?.emptyStates?.email_empty_state?.url
        : '/assets/wave-grocery/NewsLetterSub.png';

      setNewsLetterImage(image);
      const newsLetter = layoutProps?.footerProps?.newsLetter?.enabled
        ? layoutProps?.footerProps?.newsLetter?.enabled
        : false;
      setHasNewsletter(newsLetter);
    }
    getProps();
  }, []);
  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const response = await submitNewsletterSubscribe(
        process.env.newsLetterInputLink,
        process.env.newsLetterHiddenInputValue,
        newsLetterEmail
      );
      setNewsLetterEmail('');
      if (response) {
        showSubscriptionModal();
      } else {
        router.push('/');
      }
    } catch (error) {
      router.push('/');
    }
  };

  const showSubscriptionModal = () => {
    openActionModal({
      closeOnOuterClick: true,
      title: t('newsletter.modal.title'),
      text: t('newsletter.modal.description'),
      hasCenteredText: true,
      actions: [
        {
          btnLabel: t('newsletter.modal.btnLabel'),
          onClick: () => {
            setNewsLetterEmail('');
            close();
            router.push('/');
          },
        },
      ],
      img: {
        url: newsLetterImage,
        ariaLabel: 'newsletter subscribe',
        size: 'medium',
      },
    });
  };

  return (
    <Footer
      categories={footerCategories}
      backgroundColor={process.env.footerBackgroundColor}
      nameHolder={nameHolder}
      hasImageFirst={process.env.newsLetterHasImageFirst}
      isInputRound={process.env.newsletterIsInputRound}
      inputPlaceholder={newsLetter?.input_text}
      inputLink={process.env.newsLetterInputLink ?? ''}
      newsLetterText={newsLetter?.title}
      newsLetterImage={newsLetter?.image.url}
      comCategories={formattedCmsCategories}
      newsLetterColor={process.env.newsLetterColor}
      socialFooterList={formattedSocialList}
      languanegList={languanegList}
      footerMenuCategoriesHastitle={process.env.footerMenuCategoriesHastitle}
      footerMenuCategoriesTitle={process.env.footerMenuCategoriesTitle}
      footerCommunicationLogo={logo}
      phoneNumber={process.env.footerPhoneNumbers}
      appleOnClick={process.env.appleStoreLink}
      googleOnClick={process.env.playStoreLink}
      huaweiOnClick={process.env.huaweiStoreLink}
      languageChangeOnClick={() => {}}
      hasAppStore={process.env.hasAppStore}
      hasGooglePlay={process.env.hasGooglePlay}
      hasAppGallery={process.env.hasAppGallery}
      hasApplepay={process.env.hasApplepay}
      hasGpay={process.env.hasGpay}
      hasMastercard={process.env.hasMastercard}
      hasAmericanExpress={process.env.hasAmericanExpress}
      hasMaestro={process.env.hasMaestro}
      hasVisa={process.env.hasVisa}
      hasDinners={process.env.hasDinners}
      hasDiscover={process.env.hasDiscover}
      hasNewsletter={hasNewsletter}
      newsLetterFormName={process.env.newsLetterFormName ?? ''}
      newsLetterHiddenInputValue={process.env.newsLetterHiddenInputValue ?? ''}
      newsLetterOnFormSubmit={event => {
        handleSubmit(event);
      }}
      newsLetterEmail={newsLetterEmail}
      setNewsLetterEmail={setNewsLetterEmail}
      companyName={'Wave Grocery'}
      linkRedirect={'https://www.wavegrocery.com/'}
    />
  );
}
