import { useEffect } from 'react';

export default function useDisableBodyScroll() {
  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';

    return () => {
      document.querySelector('body').style.overflow = 'auto';
    };
  }, []);
}
