import useDeviceDetect from '@/hooks/useDeviceDetect.js';
import getLayoutProps from '@/utils/layout/layout.js';
import { getProductIdFromPath, getSchema } from '@/utils/smartBanner.js';
import { SmartBanner } from '@desquared/wave-components-library';
import dayjs from 'dayjs';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import styles from './MobileAppsBanner.module.css';
import Overlay from '../Overlay/Overlay';

const cookies = new Cookies();

const COOKIE_NAME = 'smart-banner-display';

export default function MobileAppsBanner({ showAgainAfterInDays = 1 }) {
  const router = useRouter();
  const [showBanner, setShowBanner] = useState(false);
  const [showDownload, setShowDownload] = useState(true);
  const [bannerData, setBannerData] = useState({});

  const { isMobile, isSafari, isAndroid, isIos, isMiBrowser } = useDeviceDetect();

  useEffect(() => {
    getBannerData();
    handleShouldShowBanner();
  }, [isMobile, isSafari]);

  const checkIfAppIsInstalled = async () => {
    if (typeof window.navigator === 'undefined') return;
    if (!window.navigator.getInstalledRelatedApps) {
      console.warn('Cannot detect installed apps');
      return;
    }
    try {
      const relatedApps = await window.navigator.getInstalledRelatedApps();

      //console.warn('relatedApps', relatedApps);

      if (relatedApps.length !== 0) setShowDownload(false);
    } catch (err) {
      console.error(err);
    }
  };

  const getBannerData = async () => {
    if (!process.env.enableSmartBanners) return;

    const layoutProps = await getLayoutProps({}, router.locale);
    if (layoutProps?.smartBanner) setBannerData({ ...layoutProps?.smartBanner });
  };

  const handleShouldShowBanner = () => {
    if (!process.env.enableSmartBanners) return;
    if (!isMobile) return;
    if (isSafari || isMiBrowser) return;
    if (!!cookies.get(COOKIE_NAME)) return;

    checkIfAppIsInstalled();
    setShowBanner(true);
  };

  const handleClose = () => {
    const cookieExpirationDate = dayjs().add(showAgainAfterInDays, 'day').toDate();
    cookies.set(COOKIE_NAME, cookieExpirationDate.toString(), {
      expires: cookieExpirationDate,
      path: '/',
    });

    setShowBanner(false);
  };

  const handleView = () => {
    const urlObject = new URL(router.asPath, window.location.origin);
    const pathname = urlObject.pathname;

    const schema = getSchema();

    let url;

    if (pathname.indexOf('/categories/') === 0) {
      url = `${schema}:/${pathname}`;
    } else if (pathname.indexOf('/products/') === 0) {
      url = `${schema}://products/${getProductIdFromPath(pathname)}`;
    } else if (pathname.indexOf('/questionnaire/') === 0) {
      //check if this is accurate
      url = `${schema}:/${pathname}`;
    } else {
      url = `${schema}://`;
    }

    window.open(url);
  };

  const handleDownload = () => {
    if (isAndroid) window.open(process.env.NEXT_PUBLIC_ANDROID_APP_URL);
    if (isIos) window.open(process.env.NEXT_PUBLIC_IOS_APP_URL);
  };

  const displayData = {
    title: isAndroid ? bannerData?.android_title : bannerData?.ios_title,
    description: isAndroid ? bannerData?.android_description : bannerData?.ios_description,
  };

  if (!process.env.enableSmartBanners) {
    return null;
  }

  if (!showBanner) return null;

  return (
    <>
      <Head>
        <meta
          name="apple-itunes-app"
          content={`app-id=${process.env.NEXT_PUBLIC_IOS_APP_ID}, app-argument=${process.env.NEXT_PUBLIC_HOSTNAME}`}
        />
      </Head>
      <Overlay isVisible={showBanner} onClick={handleClose} isAppBannerShowing={showBanner} />
      <div className={styles.bottom}>
        <SmartBanner
          title={displayData?.title || 'Wave grocery app'}
          description={displayData?.description || 'An awesome app'}
          imageUrl={'/icon512_rounded.png'}
          downloadLabel={bannerData?.button_text_download || 'Get'}
          viewLabel={bannerData?.button_text_view || 'View'}
          showDownload={showDownload}
          onClose={handleClose}
          onView={handleView}
          onDownload={handleDownload}
        />
      </div>
    </>
  );
}
