import { useRouter } from 'next/router';
import React, { useEffect, useState, useRef } from 'react';
import { SideSheet } from '@desquared/wave-components-library';
import isEmpty from 'lodash/isEmpty';
import Cookies from 'universal-cookie';
import cloneDeep from 'lodash/cloneDeep';

import { ListAdditionSheetStore } from '@/store/sideSheet';
import ListAdditionSheet from '@/components/ListAdditionSheet/ListAdditionSheet';
import User from '@/store/user';
import { ListSelectedProductStore } from '@/store/listSelectedProduct';
import { createUserLists, updateUserLists } from '@/api/lists';
import { getOrFetchUserLists, updateUserGetLists } from '@/utils/getLists';
import UserListsStore from '@/store/userLists';
import { isAddListSnackBarOpen } from '@/store/addListSnackBar';
import { useTranslations } from 'next-intl';

export default function ListAdditionAside() {
  const router = useRouter();
  const [sideSheet, setSideSheet] = useState(false);
  const [serverLists, setServerLists] = useState([]);
  const [localLists, setLocalLists] = useState([]);
  const [previousLists, setPreviousLists] = useState([]);
  const [addItem, setAddItem] = useState(false);

  const [newListName, setNewListName] = useState('');
  const [newListNameError, setNewListNameError] = useState('');
  const [newListNameHelper, setNewListNameHelper] = useState('');
  const cookies = new Cookies();
  const authorization = cookies.get('token');
  const t = useTranslations('common.asides.list_addition');

  const checkListItem = index => {
    let newLists = cloneDeep(localLists);
    newLists[index].checked = !newLists[index].checked;
    setLocalLists(newLists);
  };

  const updateItemsLists = async () => {
    const selectedProduct = ListSelectedProductStore.getValue();
    let newSelectedProduct;
    if (selectedProduct?.length > 0) {
      newSelectedProduct = selectedProduct.map(el => el.sku);
    } else {
      newSelectedProduct = [selectedProduct.sku];
    }
    let newServerLists = cloneDeep(serverLists);
    const newLocalLists = localLists.map((list, index) => {
      if (list.checked !== previousLists[index].checked) {
        if (list.checked) {
          updateUserLists(authorization, list.id, 'add', newSelectedProduct, router.locale);
          newServerLists[index].products.push({ probability: 1, sku: selectedProduct.sku });
          setServerLists(newServerLists);
          UserListsStore.update(newServerLists);
        } else {
          updateUserLists(authorization, list.id, 'remove', newSelectedProduct, router.locale);
          newServerLists[index].products = newServerLists[index].products.filter(product => {
            return product.sku != selectedProduct.sku;
          });
          UserListsStore.update(newServerLists);
          setServerLists(newServerLists);
        }
      }
    });
    isAddListSnackBarOpen.update(true);
    onClick();
    setTimeout(() => {
      isAddListSnackBarOpen.update(false);
    }, 2000);
  };

  const onNewListNameChange = e => {
    setNewListName(e.currentTarget.value);
    setNewListNameError('');
    setNewListNameHelper('');
  };

  const createNewList = async () => {
    const { data, error } = await createUserLists(newListName, authorization, router.locale);
    if (error) {
      setNewListNameError('Η λίστα υπάρχει ήδη');
      setNewListNameHelper('error');
      setNewListName('');
    } else {
      const newLists = await updateUserGetLists(user.token, router.locale);
      setServerLists(newLists);
      setPreviousLists(newLists);
      setNewListName('');
    }
  };

  const refOne = useRef(null);

  const [user, setUser] = useState();

  useEffect(() => {
    const user$ = User.subscribe(_user => {
      setUser(_user);
    });
    return () => user$.unsubscribe();
  }, []);
  useEffect(() => {
    async function getNewLists(token) {
      const lists = await getOrFetchUserLists(token);
      setServerLists(lists);
      return;
    }

    if (!isEmpty(authorization)) getNewLists(authorization);
  }, [sideSheet]);

  const onClick = () => {
    setTimeout(() => {
      ListAdditionSheetStore.close();
    }, '300');
  };
  useEffect(() => {
    const ListAdditionSheetStore$ = ListAdditionSheetStore.subscribe(setSideSheet);

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      ListAdditionSheetStore$.unsubscribe();

      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      ListAdditionSheetStore.close();
    }
  };

  if (!sideSheet) return null;
  return (
    <div ref={refOne}>
      <SideSheet
        header={t('header')}
        hasClose={true}
        onClose={onClick}
        buttonText={t('save_btn')}
        onSubmit={updateItemsLists}
        actionButtonText={t('add_list')}
        onAction={setAddItem}
      >
        <ListAdditionSheet
          user={user}
          localLists={localLists}
          onNewListNameChange={onNewListNameChange}
          newListNameError={newListNameError}
          newListNameHelper={newListNameHelper}
          checkListItem={checkListItem}
          createNewList={createNewList}
          newListName={newListName}
          serverLists={serverLists}
          setServerLists={setServerLists}
          setLocalLists={setLocalLists}
          setPreviousLists={setPreviousLists}
          setNewListName={setNewListName}
          addItem={addItem}
          setAddItem={setAddItem}
        />
      </SideSheet>
    </div>
  );
}
