import { SideCart, SideSheet, SnackBar } from '@desquared/wave-components-library';
import React, { useState, useEffect, useRef, useContext } from 'react';
import Router, { useRouter } from 'next/router';
import { isUndefined, isEmpty } from 'lodash';
import { fetchCart } from '@/api/syncCart';
import { IsAddressModalOpen } from '../../store/addressModal';
import CartStore, { productsForOrder, cartPrice, fullCartStore } from '../../store/cart';
import { isCartErrorSnackBarOpen } from '@/store/cartErrorSnackBar';
import GuestUserStore from '../../store/guestUser';
import { Shop } from '../../store/shop';
import User from '../../store/user';
import { priceChangesModalStore } from '@/store/priceChangesStore';
import { openListSideSheet, openProductDetails } from '@/utils/products';
import useBundleOffersSummary from '@/hooks/useBundleOffersSummary';
import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext';
import getLayoutProps from '@/utils/layout/layout';
import EmptyState from '../../../public/assets/wave-grocery/Empty-States-Default/Empty-Cart.png';
import { ListAdditionSheetStore } from '@/store/sideSheet';
import DefaultProductImage from '../../../public/assets/wave-grocery/Empty-States-Default/DefaultProductImage.png';
import Cookies from 'universal-cookie';
import {
  getDelivery,
  checkOrder,
  checkProducts,
  ORDER_LIMIT,
  onAdd,
  onRemove,
  setCartProducts,
  setDiscounts,
  onRemoveAll,
  updateCartOrder,
  transformCart,
} from '@/utils/products/products';
import { useTranslations } from 'next-intl';
import { CurrencyContext } from '@/contexts/CurrencyProvider';
import { arraysEqual } from '@/utils/helpers';
import useGetDefaultProductImage from '@/hooks/useGetDefaultProductImage.js';

export default function SideCartSheet() {
  const [isCartVisible, setIsCartVisible] = useState(CartStore.getIsVisble$);
  const [productsFiltered, setProductsFiltered] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState('');
  const defaultProductImage = useGetDefaultProductImage();
  const [remove, setRemove] = useState(true);
  const [formattedTitle, setFormatedTitle] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [image, setImage] = useState();
  const [data, setData] = useState();
  const refOne = useRef(null);
  const { getCollectionTypes, selectedUserAddressIndex, selectedShop } = useContext(DeliveryServicesContext);
  const { bundleOffersSummary } = useBundleOffersSummary();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [listAddtion, setListAddition] = useState();
  const [trigger, setTrigger] = useState(false);
  const handleClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target) && !ListAdditionSheetStore.getValue()) {
      CartStore.setIsVisible(false);
    }
  };
  const router = useRouter();
  const { formatter } = useContext(CurrencyContext);

  const t = useTranslations('common.asides.side_cart');
  const r = useTranslations('common');
  const cookies = new Cookies();
  const authorization = cookies.get('token');

  const headerTitle = t('header');
  const productValueLabel = t('product_value_label');
  const finalSumDescription = t('final_sum_description');
  const buttonLabel = t('btn_label');

  const onClose = () => {
    setIsCartVisible(false);
    CartStore.setIsVisible(false);
  };
  async function onSubmit() {
    const user = User.getValue();

    if (!user?._id) {
      onClose();
      router.push('/login', '/login');
      return;
    }
    if (!selectedShop) {
      isCartErrorSnackBarOpen.update(true);
      return;
    }
    if (data?.order?.products?.length === 0 || productsForOrder.getValue()?.length === 0) {
      onClose();
      router.push('/categories', '/categories');
      return;
    }
    const value = await checkOrder(
      tempProducts,
      'none',
      false,
      getCollectionTypes(),
      selectedUserAddressIndex,
      bundleOffersSummary,
      false,
      [],
      selectedProduct,
      setSelectedProduct,
      t('no_comment'),
      formatter,
      t('unavailable_product')
    );
    fullCartStore.update(value);
    const guestUser = GuestUserStore.getValue();

    if (guestUser?.email) {
      router.push('/cart', '/cart');
      return;
    }

    if (User.getValue()?.address?.length === 0 && guestUser?.address === 'undefined') {
      if (getDelivery() === 'takeAway') {
        SideSheetStore.open();
      } else {
        IsAddressModalOpen.update('open');
        return;
      }
    }
    if (data?.progress?.code !== 'undefined' && data?.progress?.code !== ORDER_LIMIT) {
      if (checkProducts()) {
        priceChangesModalStore.update(true);
      } else {
        onClose();
        router.push('/cart', '/cart');
      }
    }
  }

  useEffect(() => {
    async function initializeSidecart() {
      setTrigger(false);
      // Fetch and set layout properties
      const layoutProps = await getLayoutProps({ headerProps: { selectedIndex: -1 } }, Router.locale);

      const image = layoutProps?.emptyStates?.cart_empty_state?.url || null;
      setImage(image);

      // Initialize shop and subscriptions
      Shop.lazyInit();
      const cartIsVisible$ = CartStore.getIsVisble$().subscribe(_cartVisible => {
        setIsCartVisible(_cartVisible);
      });

      const productsForOrder$ = productsForOrder.subscribe((productsOrder = []) => {
        const tempProducts = setCartProducts(productsOrder);
        setTempProducts(tempProducts);
      });

      document.addEventListener('click', handleClickOutside, true);

      const cartPrice$ = cartPrice.subscribe(setTotalPrice);
      const $listAddition = ListAdditionSheetStore.subscribe(setListAddition);

      return () => {
        $listAddition.unsubscribe();
        cartIsVisible$.unsubscribe();
        cartPrice$.unsubscribe();
        document.removeEventListener('click', handleClickOutside, false);
        productsForOrder$.unsubscribe();
      };
    }

    async function fetchCartData() {
      try {
        if (!trigger) {
          const result = await fetchCart(); // Wait for API response
          if (result.data.products) {
            transformCart(
              result.data.products,
              'none',
              getCollectionTypes(),
              selectedUserAddressIndex,
              bundleOffersSummary,
              t('no_comment'),
              undefined,
              undefined,
              undefined,
              formatter,
              t('unavailable_product')
            );
          }
          setTrigger(true);
        }
      } catch (error) {
        console.error('Failed to fetch cart data:', error);
      } finally {
        setTrigger(true);
      }
    }

    if (!isEmpty(authorization) && process.env.NEXT_PUBLIC_SYNC_CART === 'true') {
      initializeSidecart();
      if (!trigger) {
        fetchCartData();
      }
    } else {
      if (!trigger) {
        const tempProducts = setCartProducts(productsForOrder.getValue());
        setTempProducts(tempProducts);
        setTrigger(true);
      }
    }
  }, [productsForOrder]);

  useEffect(() => {
    async function handleCartChanges() {
      if (isCartVisible && trigger) {
        setIsLoading(true);

        if (data?.products?.length > 0) {
          const updatedData = { ...data };
          const productIndex = selectedProduct
            ? updatedData.products.findIndex(el => {
                if (el?.ingredients?.length && selectedProduct?.ingredients?.length) {
                  return el.id === selectedProduct.id && arraysEqual(el.ingredients, selectedProduct.ingredients);
                }

                return el.id === selectedProduct.id;
              })
            : 0;

          if (productIndex >= 0) updatedData.products[productIndex].isLoading = true;
          setData(updatedData);
        }

        if (!isEmpty(authorization) && process.env.NEXT_PUBLIC_SYNC_CART === 'true') {
          await updateCartOrder(tempProducts, 'none', false, t('no_comment'));
        }

        await checkOrder(
          tempProducts,
          'none',
          false,
          getCollectionTypes(),
          selectedUserAddressIndex,
          bundleOffersSummary,
          false,
          [],
          selectedProduct,
          setSelectedProduct,
          t('no_comment'),
          formatter,
          t('unavailable_product')
        )
          .then(result => {
            if (result && result.data) {
              const productIndex = selectedProduct
                ? result.products.findIndex(el => {
                    if (el?.ingredients?.length && selectedProduct?.ingredients?.length) {
                      return el.id === selectedProduct.id && arraysEqual(el.ingredients, selectedProduct.ingredients);
                    }

                    return el.id === selectedProduct.id;
                  })
                : 0;

              if (productIndex >= 0) result.products[productIndex].isLoading = false;
              setData(result);

              if (result?.progress) {
                const { title, data } = result.progress;
                setFormatedTitle(
                  data?.remainingAmountForFree
                    ? title
                        .replace('{remainingAmountForFree}', data.remainingAmountForFree.toFixed(2))
                        .replace('{currency}', data.currency)
                    : title.replace('{remainingAmount}', data.remainingAmount).replace('{currency}', data.currency)
                );
              }
            }
          })
          .finally(() => {
            setIsLoading(false);
            setTotalPrice(
              (
                (data?.data?.order?.orderItemsPriceWithoutDiscounts -
                  setDiscounts(data?.data?.order?.discounts, 'PRODUCTS')) /
                100
              ).toFixed(2)
            );
          });

        if (
          tempProducts.length === 0 &&
          productsForOrder.getValue().length === 0 &&
          !isEmpty(authorization) &&
          process.env.NEXT_PUBLIC_SYNC_CART === 'true'
        ) {
          updateCartOrder([], 'none', true, t('no_comment'));
          cartPrice.update('0.00');
        }

        const flag = data?.order?.products?.length > 0;
        setProductsFiltered(flag ? data?.order?.products : tempProducts);
      }
    }

    handleCartChanges();
  }, [isCartVisible, remove, listAddtion, trigger, tempProducts]);

  if (!isCartVisible) return;

  return (
    <div ref={refOne}>
      <SideSheet position="right">
        <SideCart
          headerTitle={headerTitle}
          hasProgressBar={!isUndefined(data?.progress)}
          progressBarDescription={!isUndefined(data?.progress) && formattedTitle}
          progressBarProgress={
            (data?.progress?.progressInfo?.currentValue * 100) / data?.progress?.progressInfo?.requiredValue
          }
          content={{
            items: data?.products ? data?.products : [],
            empty: data?.products?.length > 0 ? false : true,
            emptyTitle: data?.products?.length === 0 && t('empty_title'),
            emptyText: data?.products?.length === 0 && t('empty_text'),
            emptyImg: image ? image : EmptyState.src,
          }}
          productValueLabel={
            data?.data?.order?.fees?.some(el => el.type === 'SHIPPING_COST')
              ? data?.data?.order?.fees?.filter(el => el.type === 'SHIPPING_COST')[0].title
              : undefined
          }
          productValue={
            data?.data?.order?.fees?.some(el => el.type === 'SHIPPING_COST')
              ? formatter.format(data?.data?.order?.fees?.filter(el => el.type === 'SHIPPING_COST')[0]?.amount / 100)
              : undefined
          }
          finalSumDescription={finalSumDescription}
          finalSumValue={
            !isUndefined(data?.data?.order) && data?.products?.length > 0
              ? formatter.format(
                  (data?.data?.order?.orderItemsPriceWithoutDiscounts -
                    setDiscounts(data?.data?.order?.discounts, 'PRODUCTS') +
                    (data?.data?.order?.fees?.filter(el => el.type === 'SHIPPING_COST')[0]?.amount
                      ? data?.data?.order?.fees?.filter(el => el.type === 'SHIPPING_COST')[0]?.amount
                      : 0)) /
                    100
                )
              : formatter.format(totalPrice)
          }
          buttonLabel={buttonLabel}
          onClose={onClose}
          actionButtons={[
            {
              btnLabel: t('btn_label'),
              onClick: () => onSubmit(),
              variant: 'filled',
              key: 'filled',
              disabled: data?.data?.messages?.some(el => el.code === 'MSG_ORDER00017') || (isLoading ? true : false),
            },
          ]}
          onChange={(id, ingredients) => {
            onAdd(id, setTempProducts, setRemove, remove, data, setSelectedProduct, ingredients);
          }}
          onRemove={(id, ingredients) => {
            onRemove(id, setTempProducts, setRemove, remove, null, setSelectedProduct, ingredients);
          }}
          onAddList={item => {
            openListSideSheet(item);
          }}
          onOpenDetails={item => {
            openProductDetails(item);
          }}
          disabled={!isLoading || data?.products?.length > 0}
          isLoading={isLoading && data?.products.length === 0}
          setMaxSnackBar={setShowSnackBar}
          listButtonText={r('lists_tile_item')}
          removeButtonText={r('remove_all_tile_item')}
          removeAll={(id, ingredients) => {
            onRemoveAll(id, setTempProducts, setRemove, remove, null, setSelectedProduct, ingredients);
          }}
          progressBarEmoji={data?.data?.messages?.some(el => el.code === 'MSG_ORDER00017') ? '🙄' : '🚚'}
          defaultProductImage={defaultProductImage}
        />
      </SideSheet>
      <SnackBar open={showSnackBar} setOpen={setShowSnackBar} text={r('snackbar')} position={'center'} />
    </div>
  );
}
