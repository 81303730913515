import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext.jsx';
import { DeliveryServices, TextColorEnum } from '@desquared/wave-components-library';
import React, { useContext, useEffect } from 'react';
import { AddressViewSideSheetStore } from '@/store/sideSheet';
import { useTranslations } from 'next-intl';
import { setShopId } from '@/utils/products/products';
import User from '@/store/user.js';

const menuList = undefined;

export default function DeliveryServicesSheet() {
  const t = useTranslations('common.asides.service_address.types');
  const { user, selectedUserAddressIndex, setSelectedUserAddressIndex, selectedService } =
    useContext(DeliveryServicesContext);
  const addresses =
    user?.addresses.map((element, index) => {
      return {
        ...element,
        isAvailable: null,
        textColor: TextColorEnum.Gray1,
        isSelected: selectedUserAddressIndex === index,
        title: addressType(element.type),
        subTitle: addressName(element),
      };
    }) || [];

  useEffect(() => {
    if (user && user?.addresses.length > 0) {
      if (selectedUserAddressIndex !== undefined) {
        const selectedAddressObj = User.getValue().addresses[selectedUserAddressIndex];
        setShopId(selectedAddressObj?.postalCode, selectedService?.id);
      } else {
        setSelectedUserAddressIndex(0);
      }
    }
    //eslint-disable-next
  }, []);

  function addressType(s) {
    switch (s) {
      case 'home':
        return t('home');
      case 'work':
        return t('work');
      case 'other':
        return t('other');
    }
  }
  function addressName(s) {
    if (!s.street) return undefined;
    return s.street + ' ' + s.streetNo + ', ' + s.city + ' ' + s.postalCode;
  }

  return (
    <DeliveryServices
      listAddress={addresses}
      addAddress={t('add_address')}
      list={menuList}
      setSelectedAddress={setSelectedUserAddressIndex}
      selectedIndex={selectedUserAddressIndex}
      onAddClick={() => {
        // SideSheetStore.close();
        AddressViewSideSheetStore.open();
      }}
    />
  );
}
