import { BehaviorSubject } from 'rxjs';

const isCartErrorSnackBarOpenSubject = new BehaviorSubject(false);

export const isCartErrorSnackBarOpen = {
  update: value => {
    isCartErrorSnackBarOpenSubject.next(value);
  },
  subscribe: setState => isCartErrorSnackBarOpenSubject.subscribe(setState),
  getValue: () => isCartErrorSnackBarOpenSubject.value,
};

export default { isCartErrorSnackBarOpen };
