import { useRouter } from 'next/router';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { BundleOffersSideSheet } from '@desquared/wave-components-library';

import BundeOffersStore from '@/store/bundleOffers';
import { BundleOfferSideSheetStore } from '@/store/sideSheet';
import { SelectedBundleOfferStore } from '@/store/selectedBundleOfferId';
import { productsForOrder, fullCartStore } from '@/store/cart';
import { addProduct, getBundleOfferDescription, getBundleOfferTitle } from '@/utils/products';
import { checkBundleOffer } from '@/api/bundle-offers';
import {
  checkOrder,
  onRemove,
  transformToFullPageCartItems,
  getQuantity,
  onRemoveAll,
} from '@/utils/products/products';
import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext';
import { openListSideSheet } from '@/utils/products';
import { useTranslations } from 'next-intl';
import useGetDefaultProductImage from '@/hooks/useGetDefaultProductImage.js';

import styles from './BundleOfferAside.module.css';
import { CurrencyContext } from '@/contexts/CurrencyProvider';

export default function BundleOfferAside({}) {
  const { formatter } = useContext(CurrencyContext);
  const router = useRouter();
  const [sideSheet, setSideSheet] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [bundleOfferDescription, setBundleOfferDescription] = useState('');
  const [bundleOfferTitle, setBundleOfferTitle] = useState('');
  const [localBundleOffers, setLocalBundleOffers] = useState([]);
  const [bundleItems, setBundeItems] = useState([]);
  const [tempProducts, setTempProducts] = useState(productsForOrder.getValue());
  const [remove, setRemove] = useState(false);
  const [allDescriptions, setAllDescriptions] = useState([]);
  const { getCollectionTypes, selectedUserAddressIndex } = useContext(DeliveryServicesContext);
  const [bundleOfferProducts, setBundleOfferProducts] = useState([]);
  const [firstCall, setFirstCall] = useState(true);
  const [bundleOfferOrderProducts, setBundleOfferOrderProducts] = useState([]);
  const [option, setOption] = useState('combo-offer-choice');
  const [progress, setProgress] = useState(0);
  const [gifts, setGifts] = useState([]);
  const [giftQuantities, setGiftQuantities] = useState([]);
  const t = useTranslations('common.asides');
  const [selectedLoadProduct, setSelectedLoadProduct] = useState({});
  const r = useTranslations('common');
  const refOne = useRef(null);
  const defaultProductImage = useGetDefaultProductImage(-1);

  const closeSheet = () => {
    setTimeout(() => {
      BundleOfferSideSheetStore.close();
    }, '300');
  };

  useEffect(() => {
    const BundleOfferSideSheetStore$ = BundleOfferSideSheetStore.subscribe(setSideSheet);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      BundleOfferSideSheetStore$.unsubscribe();
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    const SelectedBundleOfferStore$ = SelectedBundleOfferStore.subscribe(_product => {
      const tempProductsFiltered = productsForOrder
        .getValue()
        .filter((value, index, self) => self.map(p => p.id).indexOf(value.id) === index);
      checkOrder(
        tempProductsFiltered,
        'none',
        false,
        getCollectionTypes(),
        selectedUserAddressIndex,
        [],
        false,
        [],
        selectedLoadProduct,
        setSelectedLoadProduct,
        undefined,
        formatter
      ).then(result => {
        fullCartStore.update(result);
        setSelectedProduct(_product);
        const bundleOffersArray = [];
        const transformedItems = [];
        const transformAll = result?.data?.products?.map(item => {
          const singleItem = transformToBundleProduct(item, result.data.order.products);
          transformedItems.push(singleItem);
        });
        // const transformedBundleProduct = transformToBundleProduct(_product, result.data.order.products);
        // bundleOffersArray.push(transformedBundleProduct);
        setBundleOfferProducts(transformedItems);
      });
    });
    return () => {
      SelectedBundleOfferStore$.unsubscribe();
    };
  }, []);

  const transformToBundleProduct = (product, order) => {
    return {
      productId: product.id ?? product._id,
      quantity: product.isWeighed ? 1 : getQuantity(product, order),
      changesAllowed: 'none',
      comments: 'string',
      options: [],
    };
  };

  const transformToEmptyBundleProduct = product => {
    return {
      productId: product.id ?? product._id,
      quantity: 0,
      changesAllowed: 'none',
      comments: 'string',
      options: [],
    };
  };

  useEffect(() => {
    const productsForOrder$ = productsForOrder.subscribe((productsOrder = []) => {
      const tempProductsFiltered = productsOrder.filter(
        (value, index, self) => self.map(p => p.id).indexOf(value.id) === index
      );
      setTempProducts(tempProductsFiltered);
    });
    const SelectedBundleOfferStore$ = SelectedBundleOfferStore.subscribe(_product => {
      const bundleOffers = BundeOffersStore.getValue();
      setSelectedProduct(_product);
      // if (bundleOfferProducts.length > 0) {
      checkBundleOffer(bundleOffers[_product.bundleOfferIds[0]]._id, bundleOfferProducts)
        .then(bundleResult => {
          if (bundleResult) {
            const progress = findProgress(bundleResult?.bundle);
            setProgress(progress);
            const tempBundleOfferOrderProducts = getBundleOrderProducts(bundleResult.bundle);
            const hasGift = bundleResult.freeProducts && bundleResult.freeProducts.length > 0;

            const isSingleItemDiscount =
              bundleResult.bundle.length === 2 &&
              bundleResult.bundle[0].products.length === 1 &&
              bundleResult.bundle[1].products.length === 1 &&
              bundleResult.bundle[0].products[0]._id === bundleResult.bundle[1].products[0]._id;
            if (isSingleItemDiscount) {
              setOption('discount');
              setBundleOfferOrderProducts([tempBundleOfferOrderProducts[0]]);
              setLocalBundleOffers(bundleResult.bundle);
            } else if (hasGift) {
              setOption('campaign-gift');
              const giftsArray = [];
              let transformedGiftsArray = [];
              let giftsQuantities = [];
              const allGifts = bundleResult.freeProducts.map((gift, index) => {
                giftsQuantities.push(gift.quantity);
                giftsArray.push(gift.product);
              });
              setGiftQuantities(giftsQuantities);
              const transfromedGift = transformToFullPageCartItems(
                giftsArray,
                !firstCall && tempBundleOfferOrderProducts.length > 0
                  ? tempBundleOfferOrderProducts
                  : fullCartStore.getValue().data?.order,
                true,
                progress >= 100,
                giftsQuantities,
                bundleResult.bundle[0].timesCovered,
                t('side_cart.no_comment'),
                t,
                formatter
              );
              transformedGiftsArray = transfromedGift;

              setGifts(transformedGiftsArray);
              setBundleOfferOrderProducts(tempBundleOfferOrderProducts);
              setLocalBundleOffers(bundleResult.bundle);
            } else {
              setBundleOfferOrderProducts(tempBundleOfferOrderProducts);
              setLocalBundleOffers(bundleResult.bundle);
            }
            const bundlesArray = [];
            const descriptionsArray = [];
            const allBundle = bundleResult.bundle.map((bundle, index) => {
              const transfromedBundles = transformToFullPageCartItems(
                bundle.products,
                !firstCall && tempBundleOfferOrderProducts.length > 0
                  ? tempBundleOfferOrderProducts
                  : fullCartStore.getValue().data?.order,
                false,
                undefined,
                undefined,
                undefined,
                t('side_cart.no_comment'),
                t,
                formatter
              );
              bundlesArray.push(transfromedBundles);
              if (!isSingleItemDiscount) descriptionsArray.push(bundle.description);
            });
            if (isSingleItemDiscount) {
              descriptionsArray.push(
                `${bundleResult?.bundle[0]?.description}  +  ${bundleResult?.bundle[1]?.description}`
              );
            }

            setBundeItems(bundlesArray);
            setAllDescriptions(descriptionsArray);
          }
        })
        .finally();
      // }
      setBundleOfferDescription(getBundleOfferDescription(_product, bundleOffers));
      setBundleOfferTitle(getBundleOfferTitle(_product, bundleOffers));
    });

    return () => {
      SelectedBundleOfferStore$.unsubscribe();
      productsForOrder$.unsubscribe();
    };
  }, [sideSheet, remove, bundleOfferProducts]);

  const handleClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      BundleOfferSideSheetStore.close();
    }
  };

  function onAddProduct(product) {
    setSelectedLoadProduct(product);
    addProduct(product, false, undefined, false, t('side_cart.no_comment'));
    product.isLoading = true;
    const transformedItems = [];
    const transformAll = bundleItems.map(items => {
      const newItem = items.map(item => {
        const singleItem = transformToBundleProduct(
          item,
          !firstCall && bundleOfferOrderProducts.length > 0
            ? bundleOfferOrderProducts
            : fullCartStore.getValue().data?.order?.products ?? []
        );
        if (!singleItem.quantity) singleItem.quantity = 0;
        if (singleItem.productId === product.id) {
          singleItem.quantity++;
        }

        transformedItems.push(singleItem);
      });
      return newItem;
    });
    setFirstCall(false);
    setBundleOfferProducts(option === 'discount' ? [transformedItems[0]] : transformedItems);
    setRemove && setRemove(!remove);
  }

  function onRemoveProduct(product) {
    product.isLoading = true;

    onRemove(product.id, setTempProducts, setRemove, remove, tempProducts, setSelectedLoadProduct);
    const transformedItems = [];
    const transformAll = bundleItems.map(items => {
      const newItem = items.map(item => {
        const singleItem = transformToBundleProduct(
          item,
          !firstCall && bundleOfferOrderProducts.length > 0
            ? bundleOfferOrderProducts
            : fullCartStore.getValue().data?.order.products
        );
        if (!singleItem.quantity) singleItem.quantity = 0;
        if (singleItem.productId === product.id && singleItem.quantity !== 0) singleItem.quantity--;

        transformedItems.push(singleItem);
      });
      return newItem;
    });
    setFirstCall(false);

    setBundleOfferProducts(option === 'discount' ? [transformedItems[0]] : transformedItems);
    setRemove && setRemove(!remove);
  }
  function onRemoveAllProduct(product) {
    product.isLoading = true;

    // Call the removal function
    onRemoveAll(product.id, setTempProducts, setRemove, remove, tempProducts, setSelectedLoadProduct);

    // Create a new array to hold transformed items
    const transformedItems = [];
    const transformAll = bundleItems.map(items => {
      const newItem = items.map(item => {
        const singleItem =
          item.id === product.id
            ? transformToEmptyBundleProduct(item)
            : transformToBundleProduct(
                item,
                !firstCall && bundleOfferOrderProducts.length > 0
                  ? bundleOfferOrderProducts
                  : fullCartStore.getValue().data?.order.products
              );
        if (!singleItem.quantity) singleItem.quantity = 0;
        if (singleItem.productId === product.id && singleItem.quantity !== 0) singleItem.quantity--;

        transformedItems.push(singleItem);
      });
      return newItem;
    });
    // Set the first call state to false
    setFirstCall(false);

    // Update the state based on the option
    setBundleOfferProducts(option === 'discount' ? [transformedItems[0]] : transformedItems);

    // Toggle the remove state
    setRemove && setRemove(!remove);
  }

  function getBundleOrderProducts(bundleResult) {
    let offersArray = [];
    const singleBundleOffer = bundleResult.map(bundle => {
      if (bundle.orderProducts) {
        offersArray = [...offersArray, ...bundle.orderProducts];
      }
    });

    return offersArray;
  }
  function trimVolume(arrays) {
    return arrays.map(array =>
      array.map(item => {
        if (item.volume) {
          item.volume = item.volume.replace(/\d+/g, ''); // Remove numbers
        }
        return item;
      })
    );
  }
  function findProgress(bundles) {
    const covered = bundles.reduce((sum, bundle) => sum + (bundle.coveredQuantity || 0), 0);
    const remaining = bundles.reduce((sum, bundle) => sum + (bundle.remainingQuantity || 0), 0);
    const progress = covered > 0 || remaining > 0 ? covered / (covered + remaining) : 0;
    return progress * 100;
  }
  if (!sideSheet || bundleItems.length === 0 || localBundleOffers.length === 0) return null;
  const hasCompleted =
    bundleItems[bundleItems.length - 1][bundleItems[bundleItems.length - 1].length - 1].productPriceBefore &&
    bundleItems[bundleItems.length - 1][bundleItems[bundleItems.length - 1].length - 1].productPriceBefore !== '0.00€';
  return (
    <div ref={refOne} className={styles.container}>
      <BundleOffersSideSheet
        headerTitle={bundleOfferTitle}
        hasProgressBar={false}
        // progressBarDescription={args.progressBarDescription}
        // progressBarProgress={args.progressBarProgress}
        content={{ items: trimVolume(bundleItems), empty: false }}
        // productValueLabel="Αξία προϊόντων"
        // productValue="51,37€"
        // shippingCostsLabel={args.shippingCostsLabel}
        // shippingCosts={args.shippingCosts}
        // finalSumDescription={args.finalSumDescription}
        // finalSumValue={args.finalSumValue}
        // buttonLabel={args.buttonLabel}
        // onClose={() => {}}
        // onSubmit={() => {}}
        OfferBannerState={progress < 100 ? 'notStarted' : 'success'}
        OfferBannerEmoji={progress < 100 ? '🙌' : '🎉'}
        OfferBannerText={bundleOfferDescription}
        option={option}
        groupName={allDescriptions}
        // gift={transfromedGift}
        // giftHeading={args.giftHeading}
        gifts={gifts}
        onClose={() => {
          BundleOfferSideSheetStore.update(false);
        }}
        onSubmit={() => {}}
        productValueLabel="Αξία προϊόντων"
        productValue="51,37€"
        shippingCostsLabel="Μεταφορικά"
        shippingCost="3,00€"
        finalSumDescription="Σύνολο"
        finalSumValue="54,37€"
        buttonLabel="Συνέχεια"
        onChange={product => {
          product.isSideCart = true;
          onAddProduct(product);
        }}
        onAddList={product => {
          openListSideSheet(product);
        }}
        onRemove={product => onRemoveProduct(product)}
        hasBannerProgressBar={progress < 100}
        bannerProgressBarProgress={progress}
        listButtonText={r('lists_tile_item')}
        removeButtonText={r('remove_all_tile_item')}
        removeAll={product => {
          onRemoveAllProduct(product);
        }}
        defaultProductImage={defaultProductImage}
      ></BundleOffersSideSheet>
    </div>
  );
}
