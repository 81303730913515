import { usePrebook } from '@/hooks/usePrebook.js';
import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext.jsx';
import { formatPrebookDate } from '@/utils/dates/dates.js';
import { useTranslations } from 'next-intl';

import React, { useEffect, useState, useRef, useContext } from 'react';
import { SideSheet, PrebookTimeSlots, Text, TextEnum } from '@desquared/wave-components-library';
import { PrebookSideSheetStore } from '@/store/sideSheet';
import { PostalCode } from '@/store/isAddressRegistered';
import PrebookStore from '@/store/prebook.js';
import { getCurrentDayTitle } from '@/utils/dates/dates.js';
import styles from './Prebook.module.css';
import { useRouter } from 'next/router';

export default function PrebookAside() {
  const ref = useRef();
  const router = useRouter();
  const [sideSheet, setSideSheet] = useState(false);
  const [postalCode, setPostalCode] = useState();

  const { selectedService, selectedShop } = useContext(DeliveryServicesContext);
  const t = useTranslations('common.asides.prebook');
  const tDays = useTranslations('common.days');
  const {
    fetchTimeSlotsPerService,
    timeInMinsLeft,
    handlePrebookTimeslot,
    timeslots,
    handleSelectDay,
    handleSelectTimeslot,
    selectedDayWithPrebookStatus,
    prebook,
  } = usePrebook(PrebookStore, () => setSideSheet(true));

  useEffect(() => {
    const postalCode$ = PostalCode.subscribe(postal => {
      setPostalCode(postal);
    });

    return () => {
      postalCode$.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (selectedShop && selectedService && sideSheet) {
      fetchTimeSlotsPerService(selectedService?.id, selectedShop?._id, postalCode);
    }
  }, [sideSheet, selectedShop?._id, selectedService?.id, postalCode]);

  const onClose = () => {
    setTimeout(() => {
      PrebookSideSheetStore.close();
    }, 300);
  };
  useEffect(() => {
    const PrebookSideSheetStore$ = PrebookSideSheetStore.subscribe(setSideSheet);

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      PrebookSideSheetStore$.unsubscribe();

      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      PrebookSideSheetStore.close();
    }
  };

  if (!sideSheet) return null;
  return (
    <div ref={ref}>
      <SideSheet
        header={t('header')}
        hasClose={true}
        onClose={onClose}
        buttonText={t('save_btn')}
        onSubmit={() => {
          handlePrebookTimeslot();
          onClose();
        }}
      >
        {selectedShop?._id ? null : (
          <Text type={TextEnum.Body} classOption={styles.warning}>
            {t('no_selected_shop')}
          </Text>
        )}

        {prebook?.selectedDay && (
          <PrebookTimeSlots
            formatTitle={date => getCurrentDayTitle(date, router.locale, tDays('today'))}
            labels={{
              title: t('time_info_saved', { minutes: selectedService?.timeslots?.prebook?.timeAllocationInMinutes }),
              timeMessageForSavedValue: t('saved_info'),
              timeMessage: t('time_info', { minutes: selectedService?.timeslots?.prebook?.timeAllocationInMinutes }),
              lowAvailability: t('low_availability'),
              unavailable: t('unavailable'),
            }}
            formatDay={formatPrebookDate}
            days={timeslots}
            lowAvailabilityThreshold={2}
            timeLeftInMins={timeInMinsLeft}
            selectedDay={selectedDayWithPrebookStatus}
            setSelectedDay={handleSelectDay}
            selectedTimeslot={prebook?.selectedTimeslot}
            setSelectedTimeslot={handleSelectTimeslot}
          />
        )}
      </SideSheet>
    </div>
  );
}
