import { useState, useEffect } from 'react';

export function useDebouncedValue(value, ms = 500) {
  const [debouncedValue, setDebouncedValue] = useState('');

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedValue(value);
    }, ms);

    return () => clearTimeout(debounceTimer);
  }, [value, ms]);

  return debouncedValue;
}
