import { SideSheet, CompanySideSheet } from '@desquared/wave-components-library';
import React, { useEffect, useState, useRef } from 'react';
import { CompanyAsideSideSheetStore } from '../../store/sideSheet';
import { useTranslations } from 'next-intl';
import styles from './CompanyAside.module.css';

export default function CompanyAside(props) {
  const [sideSheet, setSideSheet] = useState(true);
  const t = useTranslations('common.asides');

  const refOne = useRef(null);

  const onClick = () => {
    setTimeout(() => {
      CompanyAsideSideSheetStore.close();
    }, '300');
  };

  useEffect(() => {
    const CompanyAsideSideSheetStore$ = CompanyAsideSideSheetStore.subscribe(setSideSheet);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      CompanyAsideSideSheetStore$.unsubscribe();
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      CompanyAsideSideSheetStore.close();
    }
  };
  if (!sideSheet) return null;

  return (
    <div ref={refOne}>
      <SideSheet header={t('company.header')} hasClose={true} onClose={onClick} classOption={styles.CompanyAsideIndex}>
        <div className={styles.CompanyAsideContainer}>
          <CompanySideSheet fields={props.fields}></CompanySideSheet>
        </div>
      </SideSheet>
    </div>
  );
}
