import {
  ContentCardShapeEnum,
  GridView,
  ListAlt,
  LocalOffer,
  ShoppingBag,
  OrdersFilled,
  ListsFilled,
  OffersFilled,
  CategoriesFilled,
} from '@desquared/wave-components-library';
import { isEmpty, isUndefined } from 'lodash-es';
import Router from 'next/router';

export const findSize = n => {
  switch (n) {
    case 1:
      return ContentCardShapeEnum.GridHalf;
    case 2:
      return ContentCardShapeEnum.GridWhole;
    case 3:
      return ContentCardShapeEnum.GridThirds;
  }
};

export const assingIcon = (s, i) => {
  if (i === 0) {
    switch (s) {
      case 1:
        return LocalOffer;
      case 0:
        return GridView;
      case 2:
        return ListAlt;
      case 3:
        return ShoppingBag;
    }
  } else {
    switch (s) {
      case 1:
        return OffersFilled;
      case 0:
        return CategoriesFilled;
      case 2:
        return ListsFilled;
      case 3:
        return OrdersFilled;
    }
  }
};
export const assignLink = s => {
  switch (s) {
    case 1:
      return '/offers';
    case 0:
      return '/categories';
    case 2:
      return '/user/lists';
    case 3:
      return '/user/history/';
  }
};

export const setGrid = data => {
  let cards = [];
  data.forEach(element => {
    let tempCard = {
      path: element.content.image?.url ?? undefined,
      shape: findSize(element.content.size),
      title: element.content.title,
      background: ContentCardShapeEnum.Squared,
      order: element.content.order,
      onCardClick: () => {},
      link: element.content?.url ?? '/',
    };

    cards.push(tempCard);
  });

  return cards;
};

export const setLang = data => {
  if (!data) return [];
  let list = [];
  data.forEach(element => {
    let tempCard = {
      text: element,
      isSelected: false,
      isDark: false,
    };

    list.push(tempCard);
  });
  return list;
};

export const setCategories = (data, isMobile) => {
  if (isEmpty(data)) return;
  let included = data.included.footer_category_entry;
  let dataSub = data.data[0].content.subcategories;
  let list = [];

  dataSub.forEach(element => {
    const arr = element.sub_categories_entry.map(x => {
      const matchedEntry = included.find(el => el.id === x.id);
      if (matchedEntry) {
        return {
          text: matchedEntry.content.title,
          link: matchedEntry.content.url,
        };
      }
    });

    let tempCard = {
      title: element.subcategory_title,
      isOpen: !isMobile,
      categories: arr,
    };
    list.push(tempCard);
  });
  return list;
};
export const setCategoriesFull = (data, isMobile) => {
  if (isUndefined(data?.data)) return;

  let included = data.included.footer_category_entry;
  let dataSub = data.data;
  let list = [];

  dataSub.forEach(data => {
    let tempCardArray = []; // Initialize a temporary array for each iteration

    data.content.subcategories.forEach(element => {
      const arr = element.sub_categories_entry.map(x => {
        const matchedEntry = included.find(el => el.id === x.id);
        if (matchedEntry) {
          return {
            text: matchedEntry.content.title,
            link: matchedEntry.content.url,
          };
        }
      });

      let tempCard = {
        title: element.subcategory_title,
        isOpen: !isMobile,
        categories: arr,
      };

      tempCardArray.push(tempCard); // Push tempCard into the temporary array
    });

    list.push(tempCardArray); // Push the temporary array into the main list
  });

  return list;
};

function sortIncludedFields(data) {
  const includedFields = data.included?.company_sidesheet_field;
  const fieldIdsOrder = data?.data[0]?.content.fields.map(field => field.id);

  const sortedIncludedFields = fieldIdsOrder.map(fieldId => {
    return includedFields.find(field => field.id === fieldId);
  });

  return {
    ...data,
    included: {
      ...data.included,
      company_sidesheet_field: sortedIncludedFields,
    },
  };
}

export const setHeaderCategories = categoriesData => {
  const result = [];
  if (categoriesData?.data[0] === undefined) return [];
  const sortedData = sortIncludedFields(categoriesData);
  sortedData?.included?.company_sidesheet_field?.map((field, index) => {
    const links = [];
    field?.content?.links.map((link, index) => {
      links.push({ title: link.link_title, redirect: link.redirect_link });
    });
    result.push({ title: field?.content?.title, links: links });
  });
  return result;
};
export const getGrid = productsData => {
  const cards = setGrid(productsData.included.grid_cards);
  const title = productsData.data[0].content.title;
  const columns = productsData.data[0].content.columns;
  return {
    cards,
    title,
    columns,
  };
};

export const setHighLights = data => {
  let list = [];
  data.forEach((element, index) => {
    let tempCard = {
      text: element.content.description,
      title: element.content.title,
      imgTitle: element.content.description || `This is an image for the highlights section #${index + 1}`,
      image: element.content.image?.url,
      link: element.content?.link ?? undefined,
    };

    list.push(tempCard);
  });

  return list;
};

export const setBanner = data => {
  let list = [];

  const currentDate = new Date();

  data.forEach(element => {
    let targetDate = new Date(element.content.end_date);
    let startDate = new Date(element.content.start_date);

    if (currentDate < targetDate && currentDate >= startDate && element.content.enabled) {
      let tempCard = {
        image: element.content.web_image?.url ?? '',
        mobileImage: element.content.image?.url ?? '',
        altText: element.content.title,
        key: element.content.title,
        href: element.content?.redirection_page ? element.content.redirection_page : null,
      };
      list.push(tempCard);
    }
  });

  return list;
};
export const setBannerCart = data => {
  let list = [];

  const currentDate = new Date();

  data.forEach(element => {
    let targetDate = new Date(element.content.end_date);
    let startDate = new Date(element.content.start_date);

    if (currentDate < targetDate && currentDate >= startDate && element.content.enabled && element.content.web_image) {
      let tempCard = {
        image: element.content?.web_image?.[0]?.url ?? element.content.web_image?.url,
        mobileImage: element.content?.mobile_image?.[0]?.url ?? element.content.mobile_image?.url,
        altText: element.content.title,
        key: element.content.title,
        href: element.content?.redirection_page ? element.content.redirection_page : undefined,
      };
      list.push(tempCard);
    }
  });

  return list;
};
export const setSlider = (data, fill, tab) => {
  let list = [];

  data.forEach(element => {
    let tempCard = {
      path: element.content.image?.image,
      title: element.content.title,
      buttonText: element.content?.button_text ?? 'Enabled',
      text: element.content?.content_card_description ?? undefined,
      onCardClick: () => {
        if (tab) window.open(element.content?.url, '_blank');
      },
      link: !tab ? (element.content?.url ? element.content?.url : '/') : '',
      imageFillContainer: fill,
    };
    if (element.content?.enabled) list.push(tempCard);
  });
  return list;
};
