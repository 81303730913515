import { useEffect, useState, useContext } from 'react';
import ReactModal from 'react-modal';
import CUSTOM_STYLES from '../../utils/modalStyle';
import { PriceChangesAvailability } from '@desquared/wave-components-library';
import { priceChangesModalStore } from '@/store/priceChangesStore';
import { fullCartStore, productsForOrder } from '@/store/cart';
import styles from './PriceChangesAvailabilityModal.module.css';
import { checkOrder, updatePrices, setCartProducts } from '@/utils/products/products';
import { ReplacementOptionStore } from '@/store/sideSheet';
import User from '@/store/user';
import GuestUserStore from '@/store/guestUser';
import { useRouter } from 'next/router';
import Router from 'next/router';
import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext';
import { onClickRemoveFromOrder } from '@/utils/cart';
import { useTranslations } from 'next-intl';
import useGetDefaultProductImage from '@/hooks/useGetDefaultProductImage.js';

function PriceChangesAvailabilityModal({ isOpen = true, modalClose, shouldCloseOnOverlayClick = true }) {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [categories, setCategories] = useState([]);
  const [tempProducts, setTempProducts] = useState([]);
  const [description, setDescription] = useState('');
  const t = useTranslations('common.asides.price_changes');
  const defaultProductImage = useGetDefaultProductImage(0);

  const router = useRouter();
  function closeModal() {
    setIsModalOpen(false);
    priceChangesModalStore.update(false);
  }
  const { getCollectionTypes } = useContext(DeliveryServicesContext);
  const onSubmit = () => {
    let filteredArray;
    if (fullCartStore.getValue()?.products?.some(el => el.labelType === 'unavailable')) {
      const array1 = fullCartStore.getValue()?.products;

      const array2 =
        categories.find(category => category.items.some(item => item.labelType === 'unavailable'))?.items || [];

      array2.forEach(el => {
        productsForOrder.getValue().forEach(ol => {
          if (ol.id === el.id) {
            onClickRemoveFromOrder(ol);
          }
        });
      });

      filteredArray = setCartProducts(productsForOrder.getValue());
    } else {
      filteredArray = tempProducts;
    }
    const currentRoute = router.pathname;

    checkOrder(filteredArray, ReplacementOptionStore.getValue().option, true, getCollectionTypes()).then(result => {
      if (result && result.data) {
        fullCartStore.update(result);
        const user = User.getValue();
        const guestUser = GuestUserStore.getValue();

        closeModal();
        updatePrices();
        if (guestUser?.email) {
          if (currentRoute !== '/cart') {
            Router.push('/cart', '/cart');
            return;
          } else {
            Router.push('/checkout', '/checkout');
            return;
          }
        }

        if (!user?._id) {
          Router.push('/login', '/login');
          return;
        }
        if (User.getValue()?.address?.length === 0) {
          IsAddressModalOpen.update('open');
          return;
        }

        if (currentRoute !== '/cart') {
          Router.push('/cart', '/cart');
          return;
        } else {
          Router.push('/checkout', '/checkout');
          return;
        }
      }
    });
  };

  useEffect(() => {
    const productsUnavailable = fullCartStore
      .getValue()
      ?.products?.filter(el => el.labelType === 'unavailable' && !el?.isGift && !el?.isOutOfCatalogueGift);
    const productsChanged = fullCartStore.getValue()?.products?.filter(el => el.labelType === 'priceChanged');
    const productsForOrder$ = productsForOrder.subscribe((productsOrder = []) => {
      const tempProductsFiltered = setCartProducts(productsOrder);

      setTempProducts(tempProductsFiltered);
    });
    const newCategories = [];
    if (productsChanged?.length > 0) {
      setDescription(t('auto_changed_description'));
      newCategories.push({
        title: t('renewed_prices'),
        items: productsChanged,
      });
    }

    if (productsUnavailable?.length > 0) {
      setDescription(
        productsChanged?.length > 0 ? t('auto_changed_unavailable_description') : t('auto_unavailable_description')
      );
      newCategories.push({
        title: t('unavailable_products'),
        items: productsUnavailable,
      });
    }
    setCategories(newCategories);
    return () => {
      productsForOrder$.unsubscribe();
    };
  }, []);

  return (
    isModalOpen && (
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={CUSTOM_STYLES}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        className={styles.modalContent}
      >
        <PriceChangesAvailability
          title={t('renew_products')}
          description={description}
          submitButton={'Συνέχεια'}
          secondaryButton={
            fullCartStore.getValue()?.products?.some(el => el.labelType === 'unavailable')
              ? t('back_to_cart')
              : undefined
          }
          onSubmit={onSubmit}
          onBackToCart={closeModal}
          categories={categories}
          defaultProductImage={defaultProductImage}
        />
      </ReactModal>
    )
  );
}

export default PriceChangesAvailabilityModal;
