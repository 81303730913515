import { BehaviorSubject } from 'rxjs';

import jsonStorage from '../utils/json-storage';

const initialState = {
  email: '',
  contactPhone: '',
  city: '',
  nameAtBell: '',
  floor: '',
  postalCode: '',
  street: '',
  streetNo: '',
};

const GuestUserStore = {
  subject: null,

  _lazyInit() {
    if (GuestUserStore.subject) return GuestUserStore.subject;

    const storage = window.sessionStorage;
    const guestUser = jsonStorage.get('guestUser', { storage });

    GuestUserStore.subject = new BehaviorSubject(guestUser);
    return GuestUserStore.subject;
  },
  update: guest => {
    const guestNext = {
      ...GuestUserStore.getValue(),
      ...guest,
    };
    const guestUser = GuestUserStore._lazyInit();
    guestUser.next(guestNext);

    const storage = window.sessionStorage;
    jsonStorage.set('guestUser', guestNext, { storage });
  },
  subscribe: setState => {
    const guestUser = GuestUserStore._lazyInit();
    return guestUser.subscribe(setState);
  },
  getValue: () => {
    const guestUser = GuestUserStore._lazyInit();
    return guestUser.value;
  },
  setInitUser: () => {
    GuestUserStore.update(initialState);
  },
  clear: () => {
    const guestUser = GuestUserStore._lazyInit();

    guestUser.next(null);

    const storage = window.sessionStorage;
    jsonStorage.set('guestUser', null, { storage });
  },
};

export default GuestUserStore;
