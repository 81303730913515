import { ListAddition } from '@desquared/wave-components-library';
import React, { useEffect } from 'react';
import { checkListForProduct } from '@/utils/lists';
import { ListSelectedProductStore } from '@/store/listSelectedProduct';
import { useTranslations } from 'next-intl';

export default function ListAdditionSheet({
  user,
  localLists,
  onNewListNameChange,
  newListNameError,
  newListNameHelper,
  checkListItem,
  createNewList,
  newListName,
  setServerLists,
  setLocalLists,
  setPreviousLists,
  serverLists,
  setNewListName,
  addItem,
  setAddItem,
}) {
  const t = useTranslations('common.asides.list_addition');

  useEffect(() => {
    const product = ListSelectedProductStore.getValue();
    const newLists = serverLists
      .filter(list => list.type !== 'suggested')
      .map(list => {
        let newList = {};
        newList.id = list._id;
        newList.name = list.label;
        newList.checked = checkListForProduct(list._id, serverLists, product);
        newList.label = list.label;
        return newList;
      });
    setLocalLists(newLists);
    setPreviousLists(newLists);
  }, [serverLists]);

  const product = ListSelectedProductStore.getValue();
  return (
    <>
      <ListAddition
        productName={` ${product.displayName ?? product.productTitle} `}
        firstText={t('first_text')}
        secondText={t('second_text')}
        addText={t('add_list')}
        cancelWording={t('cancel')}
        addWording={t('add')}
        onAddClick={createNewList}
        primaryButtonWording={t('save')}
        listPlaceHolder={t('placeholder')}
        listName={newListName}
        listLabel={t('label')}
        lists={localLists}
        setListName={onNewListNameChange}
        listErrorText={newListNameError}
        listHelperTextType={newListNameHelper}
        onListItemClick={checkListItem}
        setNewListName={setNewListName}
        addItem={addItem}
        setAddItem={setAddItem}
      />
    </>
  );
}
