import { getUserListsV3 } from './../../src/api/lists';
import UserListsStore from './../../src/store/userLists';

export function getOrFetchUserLists(token) {
  if (UserListsStore.isFetched()) {
    return UserListsStore.getValue();
  }
  return updateUserGetLists(token);
}

export async function updateUserGetLists(token) {
  const { data, error } = await getUserListsV3(token);
  UserListsStore.update(data);
  return data;
}
