import React from 'react';
import ReactDOM from 'react-dom';
import styles from './Overlay.module.css';
import cn from 'classnames';

const Overlay = ({ isVisible, onClick, isAppBannerShowing }) => {
  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div className={cn(styles.overlay, { [styles.overlayAppBanner]: isAppBannerShowing })} onClick={onClick}></div>,
    document.body
  );
};

export default Overlay;
