import { BehaviorSubject } from 'rxjs';

const INITIAL_SUBJECT_VALUE = 'default';
const meta = {
  cached: false,
  fetched: false,
  updatedAt: new Date(),
};
const SortBy = {
  subject: new BehaviorSubject(INITIAL_SUBJECT_VALUE),

  update(sortOption) {
    SortBy.subject.next(sortOption);
    Object.assign(meta, {
      fetched: true,
      updatedAt: new Date(),
    });
  },
  delete: () => {
    SortBy.update(INITIAL_SUBJECT_VALUE);
  },
  subscribe(setState) {
    return SortBy.subject.subscribe(setState);
  },

  getValue() {
    return SortBy.subject.value;
  },
};

export default SortBy;
