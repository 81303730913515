import React, { useEffect, useState, useRef } from 'react';
import { SideSheet } from '@desquared/wave-components-library';
import { PostalCodeSideSheetStore } from '@/store/sideSheet';
import { useTranslations } from 'next-intl';
import CheckPostalCodeSheet from '@/components/CheckPostalCodeSheet/CheckPostalCodeSheet';

export default function PostalCodeAside(props) {
  const [sideSheet, setSideSheet] = useState(false);
  const t = useTranslations('common.asides.postal_check');

  const refOne = useRef(null);

  const onClick = () => {
    setTimeout(() => {
      PostalCodeSideSheetStore.close();
    }, '300');
  };
  useEffect(() => {
    const PostalCodeSideSheetStore$ = PostalCodeSideSheetStore.subscribe(setSideSheet);

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      PostalCodeSideSheetStore$.unsubscribe();

      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      PostalCodeSideSheetStore.close();
    }
  };

  if (!sideSheet) return null;
  // useDisableBodyScroll();
  return (
    <div ref={refOne}>
      <SideSheet header={t('header')} hasClose={true} onClose={onClick} hasBack={props.hasBack} onBack={props.onBack}>
        <CheckPostalCodeSheet config={props.config} logo={props.logo} />
      </SideSheet>
    </div>
  );
}
