import { MapPinEnum } from '@desquared/wave-components-library';

export function formatAddress(address = {}, t) {
  let formatted = '';

  if (address.street) {
    formatted = address.street;
  }
  if (address.streetNo) {
    formatted = `${formatted} ${address.streetNo},`;
  }
  if (address.city) {
    formatted = `${formatted} ${address.city}`;
  }
  if (address.postalCode) {
    formatted = `${formatted}, ${address.postalCode}`;
  }
  if (address.floor) {
    formatted = `${formatted}, \n${t ? t('user_address.subtitle.floor') : 'Όροφος'}: ${address.floor}`;
  }
  if (address.nameAtBell) {
    formatted = `${formatted}, ${t ? t('user_address.subtitle.door_bell') : 'Κουδούνι'}: ${address.nameAtBell}`;
  }
  if (address.contactPhone) {
    formatted = `${formatted},\n${address.contactPhone}`;
  }
  return formatted;
}

export function getAddressTypeInfo(type, t) {
  switch (type) {
    case 'work':
      return { label: t ? t('user_address.types.work') : 'Εργασία' };
    case 'other':
      return { label: t ? t('user_address.types.other') : 'Άλλο' };
    default:
      return { label: t ? t('user_address.types.default') : 'Σπίτι' };
  }
}
export function getAddressList(addresses, onEdit, onDelete, onNewAddress, isMobile, mainAddress, t) {
  const list = [];
  addresses.map((item, index) => {
    let myType;
    switch (item.type) {
      case 'home':
        myType = 'Home';
        break;
      case 'other':
        myType = 'Other';
        break;
      case 'work':
        myType = 'Work';
        break;
    }
    const newAddress = {
      isMainAddress: mainAddress._id === item._id,
      title: getAddressTypeInfo(item.type, t).label,
      type: myType,
      subtitle: formatAddress(item, t),
      onEdit: () => {
        onEdit(item._id);
      },
      onDelete: () => {
        onDelete(item._id);
      },
      isAddNew: false,
    };
    list.push(newAddress);
  });
  if (list.length > 0 && !isMobile) {
    const newAddress = {
      title: t ? t('user_address.add') : 'Προσθήκη Νέας Διεύθυνσης',
      type: 'Other',
      onEdit: () => {},
      onDelete: () => {},
      isAddNew: true,
      onAdd: () => {
        onNewAddress();
      },
    };
    list.push(newAddress);
  }
  return list[0] ? list : list[0];
}

export function transformToStigmas(inputArray) {
  const stigmas = inputArray.map(obj => {
    const { address } = obj;
    return {
      postal: address.postalCode,
      lat: address.latitude,
      lng: address.longitude,
      pin: MapPinEnum.Wave,
    };
  });

  return stigmas;
}

export const getAddressType = addressType => {
  switch (addressType) {
    case 0:
      return 'home';
    case 1:
      return 'work';
    case 2:
      return 'other';
  }
};

export default { formatAddress, getAddressTypeInfo, getAddressList, transformToStigmas, getAddressType };
