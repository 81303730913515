import { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';

import { GeneralSelectedTimeslotStore, FirstSelectedTimeslotStore } from '@/store/selectedTimeslot';
import { HeaderMessageStore } from '@/store/headerMessage';
import { returnDay, returnDate } from '@/utils/transformers/transformTimeslots';
import { daytToFull } from '@/utils/transformers/transformTimeslots';

// const FULL_CAPACITY_TIMESLOTS_TEXT = 'Είμαστε πλήρεις, ραντεβού ξανά το πρωί!';
// const UNSELECTED_SHOP_TIMESLOTS_TEXT = 'Από το κατάστημα που επιθυμείς';

export default function useGenericTimeslots(
  isCheckout,
  timeslotsSideSheet,
  addressPickerStatus,
  genericTimeslots,
  genericTransformedTimeslots,
  genericIndexes,
  setGenericIndexes,
  setMessage,

  setTimeslotDropdownOptions,
  setTimeslotsSecondText,
  selectedShopId,
  isPrebookingEnabled,
  t
) {
  useEffect(() => {
    if (isPrebookingEnabled) return;

    if (!isEmpty(genericTimeslots)) {
      let newGenericIndexes = GeneralSelectedTimeslotStore.getValue();
      if (
        newGenericIndexes === 'none' ||
        newGenericIndexes?.selectedDayIndex === -1 ||
        newGenericIndexes?.selectedIndex === -1
      ) {
        newGenericIndexes = genericIndexes;
      } else if (
        (newGenericIndexes?.selectedDayIndex !== -1 &&
          newGenericIndexes?.selectedIndex !== -1 &&
          genericTimeslots.length < newGenericIndexes.selectedDayIndex) ||
        genericTimeslots[newGenericIndexes?.selectedDayIndex]?.timeslots.length > newGenericIndexes?.selectedIndex
      ) {
        newGenericIndexes = genericIndexes;
      }
      GeneralSelectedTimeslotStore.update(newGenericIndexes);

      if (
        newGenericIndexes?.selectedDayIndex !== -1 &&
        newGenericIndexes?.selectedIndex !== -1 &&
        !isEmpty(genericTimeslots) &&
        genericTimeslots[newGenericIndexes?.selectedDayIndex]?.date
      ) {
        if (!isCheckout) {
          const genericDate = returnDate(genericTimeslots[newGenericIndexes.selectedDayIndex].date, t);
          const genericDay = returnDay(genericTimeslots[newGenericIndexes.selectedDayIndex].date, t);
          const timeslotsMessage = `${
            genericTimeslots[newGenericIndexes.selectedDayIndex].timeslots[newGenericIndexes.selectedIndex].startsAt
          } - ${
            genericTimeslots[newGenericIndexes.selectedDayIndex].timeslots[newGenericIndexes.selectedIndex].endsAt
          }`;
          setMessage(`${genericDay} ${genericDate}`);
          setTimeslotsSecondText(timeslotsMessage);
          HeaderMessageStore.update(`${genericDay} ${genericDate} ${timeslotsMessage}`);
        } else {
          setTimeslotDropdownOptions([
            {
              name: `${FirstSelectedTimeslotStore.getValue() ? t('common.timeslots.soonest') : ''}${daytToFull(
                genericTransformedTimeslots[newGenericIndexes.selectedDayIndex].day,
                t
              )} ${genericTransformedTimeslots[newGenericIndexes.selectedDayIndex].date} ${
                genericTransformedTimeslots[newGenericIndexes.selectedDayIndex].timeslots[
                  newGenericIndexes.selectedIndex
                ].name
              }`,
              unavailable: false,
            },
          ]);
        }
      } else {
        if (!isCheckout) {
          setMessage(t('common.timeslots.not_available_timeslots'));
          setTimeslotsSecondText('');

          HeaderMessageStore.update(t('common.timeslots.not_available_timeslots'));
        } else {
          setTimeslotDropdownOptions([
            {
              name: t('common.timeslots.unavailable_store'),
              unavailable: true,
            },
          ]);
        }
      }
    } else if (isEmpty(genericTimeslots)) {
      if (!isCheckout) {
        setMessage(t('common.timeslots.not_supported_region'));
        setTimeslotsSecondText('');

        HeaderMessageStore.update(t('common.timeslots.not_supported_region'));
      } else {
        setTimeslotDropdownOptions([
          {
            name: t('common.timeslots.unsupported_region'),
            unavailable: true,
          },
        ]);
      }
    }
  }, [timeslotsSideSheet, addressPickerStatus, genericTimeslots, selectedShopId]);
}
