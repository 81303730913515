import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext.jsx';

import isEmpty from 'lodash/isEmpty';
import Link from 'next/link';
import { useState, useEffect, useContext } from 'react';
import { useTranslations } from 'next-intl';
import { SearchResults } from '@desquared/wave-components-library';
import { getSuggestionsV31 } from '@/api/Search';
import { categoriesToTerms, suggestionsToProducts } from '@/utils/search';
import { enhanceProductWithSlug } from '@/utils/products/productSlugs';
import useBundleOffersSummary from '@/hooks/useBundleOffersSummary';
import { useRouter } from 'next/router';
import { getProductPropsWithCleanUrl } from '@/utils/products';
import { useDebouncedValue } from '@/hooks/useDebouncedValue';
import useGetDefaultProductImage from '@/hooks/useGetDefaultProductImage.js';

export default function SuggestedSearchResults(query) {
  const { locale, defaultLocale } = useRouter();
  const { getCollectionTypes } = useContext(DeliveryServicesContext);
  const [collectionTypes, _] = useState(getCollectionTypes());
  const [suggestions, setSuggestions] = useState({});
  const { bundleOffersSummary } = useBundleOffersSummary();
  const t = useTranslations('common.header.search_suggestions');
  const debouncedQuery = useDebouncedValue(query.terms);
  const defaultProductImage = useGetDefaultProductImage();

  useEffect(() => {
    async function fetchSuggestions() {
      if (!debouncedQuery) return;

      const _suggestions = await getSuggestionsV31(debouncedQuery, collectionTypes);

      if (_suggestions.error) return;

      //TODO: Fix enhanceProductWithSlug to remove double slashes
      const tempFixProductSlug = p => {
        let result = p.slug.slice(1);
        return { ...p, slug: result };
      };
      _suggestions.catalogProducts = _suggestions.catalogProducts?.map(enhanceProductWithSlug)?.map(tempFixProductSlug);
      _suggestions.suggestedProducts = _suggestions.suggestedProducts
        ?.map(enhanceProductWithSlug)
        ?.map(tempFixProductSlug);

      setSuggestions(_suggestions);
    }

    fetchSuggestions();
  }, [debouncedQuery, collectionTypes]);

  if (query.length < 3) return null;

  return (
    !isEmpty(suggestions.categories) && (
      <SearchResults
        title={t('title')}
        suggestions={categoriesToTerms(suggestions.categories, query, t, locale, defaultLocale)}
        hasSuggestions={!isEmpty(suggestions.categories)}
        favoritesTitle={t('favourites_title')}
        hasFavorites={!isEmpty(suggestions.suggestedProducts)}
        favoritesProducts={
          !isEmpty(suggestions.suggestedProducts)
            ? suggestionsToProducts(suggestions.suggestedProducts, locale, defaultLocale)
            : []
        }
        catalogueTitle={t('catalogue_title')}
        catalogueProducts={
          !isEmpty(suggestions.catalogProducts)
            ? suggestionsToProducts(suggestions.catalogProducts, bundleOffersSummary, locale, defaultLocale)
            : []
        }
        LinkComponent={props => {
          const propsWithCleanUrl = getProductPropsWithCleanUrl(props);

          return <Link {...propsWithCleanUrl} />;
        }}
        hasCatalogueProducts={!isEmpty(suggestions.catalogProducts)}
        defaultProductImage={defaultProductImage}
      />
    )
  );
}
