import { getTimeslotsForShopV3 } from '@/api/Shops.js';
import { reserveTimeslot } from '@/api/Timeslots.js';
import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext.jsx';
import { ModalContext } from '@/contexts/ModalProvider.jsx';
import getLayoutProps from '@/utils/layout/layout';
import { formatUTCTimeslotsToLocal, getDifferenceInMins } from '@/utils/dates/dates.js';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { addDisabledDaysTimeslots } from '@/utils/transformers/transformTimeslots';
import { PREDICTION_TIMESLOT_TOKEN, createPredictiveTimeslotTextWithDate } from '@/utils/logic/timeslots';
import { useToast } from '@/contexts/ToastContext';
import { PostalCode } from '@/store/isAddressRegistered';
import Router from 'next/router';
import cloneDeep from 'lodash/cloneDeep';
import { useDateFormatting } from '@/contexts/DateFormattingProvider';

const TIME_INTERVAL = 60000;
let TIMER = null;

export function usePrebook(StoreToUse, onTimeRunOut) {
  const router = useRouter();
  const { timeFormat } = useDateFormatting();
  const { showToast } = useToast();
  const [timeslots, setTimeslots] = useState([]);
  const [postalCode, setPostalCode] = useState();
  const [prebook, setPrebook] = useState();
  const { selectedService, selectedShop, user } = useContext(DeliveryServicesContext);
  const { openErrorModal, close } = useContext(ModalContext);
  const [timeInMinsLeft, setTimeInMinsLeft] = useState();
  const [hasPredictiveTimeslot, setHasPredictiveTimeslot] = useState(false);
  const [predictiveTimeslotText, setPredictiveTimeslotText] = useState(['', '']);
  const t = useTranslations('common.asides.prebook');
  const isCheckout = router.asPath.indexOf('/checkout') > -1;
  const [prebookImage, setPrebookImage] = useState();

  useEffect(() => {
    return () => StoreToUse.update({ selectedDay: undefined, selectedTimeslot: undefined });
  }, [StoreToUse]);

  useEffect(() => {
    if (prebook?.reservation?.requestId) {
      handleStartTimer();
    } else {
      window.clearInterval(TIMER);
    }
    return () => window.clearInterval(TIMER);
  }, [prebook?.reservation?.requestId]);

  useEffect(() => {
    const PrebookStore$ = StoreToUse.subscribe(setPrebook);
    async function getProps() {
      const layoutProps = await getLayoutProps({ headerProps: { selectedIndex: -1 } }, Router.locale);
      const image = layoutProps?.emptyStates?.prebook_empty_state?.url
        ? layoutProps?.emptyStates?.prebook_empty_state?.url
        : null;
      setPrebookImage(image);
    }
    getProps();
    return () => {
      PrebookStore$.unsubscribe();
    };
  }, [StoreToUse]);

  useEffect(() => {
    const postal = PostalCode.subscribe(setPostalCode);

    return () => {
      postal.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (timeslots.length > 0) {
      const firstAvailableDay = timeslots.find(day => !day.disabled);
      const firstAvailableTimeslot = firstAvailableDay?.timeslots?.[0];
      StoreToUse.update({
        selectedDay: firstAvailableDay || prebook?.selectedDay,
        selectedTimeslot: prebook?.selectedTimeslot,
      });
    } else {
      StoreToUse.update({ selectedDay: undefined, selectedTimeslot: undefined });
    }
  }, [timeslots]);

  const showExpirationModal = () => {
    openErrorModal({
      title: t('time_ran_out_error.title'),
      text: t('time_ran_out_error.text'),
      actions: [
        {
          btnLabel: t('time_ran_out_error.action'),
          onClick: () => {
            close();
            onTimeRunOut && onTimeRunOut();
          },
        },
      ],
      img: {
        url: prebookImage ? prebookImage : '/assets/wave-grocery/PrebookExpiration.png',
        ariaLabel: t('time_ran_out_error.img_aria_label'),
        size: 'medium',
      },
    });
  };

  const fetchTimeSlotsPerService = async (serviceId, shopId, postCode) => {
    if (!shopId) {
      setTimeslots([]);
      return;
    }
    const { timeslots: timeslotsData } = await getTimeslotsForShopV3(
      shopId || selectedShop._id,
      serviceId || selectedService.id,
      postCode
    );

    if (timeslotsData?.type === PREDICTION_TIMESLOT_TOKEN) {
      setHasPredictiveTimeslot(true);
      setPredictiveTimeslotText(
        createPredictiveTimeslotTextWithDate(timeslotsData.prediction, t('predictive_time_title'), router.locale)
      );
      return;
    }

    const localTimeslots = formatUTCTimeslotsToLocal(timeslotsData?.exactTimeTimeslots, timeFormat);
    const transformedTimeslots = addDisabledDaysTimeslots(localTimeslots, t);
    setTimeslots(transformedTimeslots);
    const firstAvailableDay = transformedTimeslots.findIndex(day => !day.disabled);
    StoreToUse.update({
      selectedDay: prebook?.selectedDay ? prebook?.selectedDay : transformedTimeslots[firstAvailableDay ?? 0],
    });

    setHasPredictiveTimeslot(false);
    setPredictiveTimeslotText(['', '']);
  };

  const handleSelectDay = day => {
    let updatedPrebook = cloneDeep(prebook);
    updatedPrebook.selectedDay = day;
    setPrebook(updatedPrebook);
    StoreToUse.update({ selectedDay: day });
  };

  const handleSelectTimeslot = async timeslot => {
    let updatedPrebook = cloneDeep(prebook);
    updatedPrebook.selectedTimeslot = timeslot;
    setPrebook(updatedPrebook);
    StoreToUse.update({ selectedTimeslot: timeslot });
  };

  const handlePrebookTimeslot = async (serviceId, shopId) => {
    //ToDo: an easy to open toast message would be nice
    if (!prebook?.selectedTimeslot) {
      showToast(t('no_timeslot_selected_error'));
      return;
    }

    StoreToUse.update({
      selectedTimeslot: { ...prebook?.selectedTimeslot },
    });

    try {
      const reservation = await reserveTimeslot(
        {
          shop: shopId || selectedShop?._id,
          deliveryService: serviceId || selectedService.id,
          startsAt: prebook?.selectedTimeslot?.startsAtDate,
          endsAt: prebook?.selectedTimeslot?.endsAtDate,
          ...(selectedService?.deliversToAddress && { postalCode: postalCode.toString() }),
        },
        //despair inc
        user?.token || user?.authToken
      );
      StoreToUse.update({ reservation: reservation });
    } catch (err) {
      StoreToUse.update({ selectedTimeslot: { ...prebook?.selectedTimeslot, isPrebooked: false } });

      openErrorModal({
        title: t('reservation_error.title'),
        text: t('reservation_error.text'),
        actions: [
          {
            btnLabel: t('reservation_error.action'),
            onClick: () => {
              close();
              onTimeRunOut && onTimeRunOut();
            },
          },
        ],
        img: {
          url: prebookImage ? prebookImage : '/assets/wave-grocery/PrebookExpiration.png',
          ariaLabel: t('reservation_error.img_aria_label'),
          size: 'medium',
        },
      });
      console.log(err);
    }
  };

  //this starts a timer that displays how much time is left for a reservation to be considered valid
  const handleStartTimer = () => {
    window.clearInterval(TIMER);

    const differenceInMins = getDifferenceInMins(new Date(), new Date(prebook?.reservation?.expiresAt));
    if (differenceInMins <= 0) {
      if (isCheckout) {
        showExpirationModal();
      }
      StoreToUse.reset();
      setTimeInMinsLeft(undefined);
      return;
    }

    setTimeInMinsLeft(differenceInMins);
    TIMER = window.setInterval(() => {
      const differenceInMins = getDifferenceInMins(new Date(), new Date(prebook?.reservation?.expiresAt));
      if (differenceInMins <= 0) {
        if (isCheckout) {
          showExpirationModal();
        }
        StoreToUse.reset();
        setTimeInMinsLeft(undefined);
        window.clearInterval(TIMER);
        return;
      }
      setTimeInMinsLeft(getDifferenceInMins(new Date(), new Date(prebook?.reservation?.expiresAt)));
    }, TIME_INTERVAL);
  };
  const selectedDayWithPrebookStatus = {
    ...prebook?.selectedDay,
    timeslots: prebook?.selectedDay?.timeslots.map(timeslot => {
      return {
        ...timeslot,
        isPrebooked: prebook?.reservation?.startsAt?.toString?.() === timeslot.startsAtDate.toString(),
      };
    }),
  };

  return {
    prebook,
    timeslots,
    timeInMinsLeft,
    showExpirationModal,
    selectedDayWithPrebookStatus,
    handlePrebookTimeslot,
    fetchTimeSlotsPerService,
    handleSelectDay,
    handleSelectTimeslot,
    handleStartTimer,
    hasPredictiveTimeslot,
    predictiveTimeslotText,
  };
}
