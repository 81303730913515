import MobileAppsBanner from '@/components/MobileAppsBanner/MobileAppsBanner.jsx';
import styles from '@/styles/User.module.css';
import {
  BoldEnum,
  Heading,
  HeadingEnum,
  TextColorEnum,
  OffersSnackbar,
  SnackBar,
  PageNotFound,
  Text,
  TextEnum,
  ArrowBack,
} from '@desquared/wave-components-library';
import isEmpty from 'lodash/isEmpty';
import { ErrorBoundary } from 'react-error-boundary';
import useWindowUnderThreshold from '@/hooks/useWindowUnderThreshold';

import cn from 'classnames';
import Header from './Header';
import React from 'react';
import Footer from './Footer/Footer';
import MobileMenu from './ResponsiveMenu/ResponsiveMenu';
import { setLang } from '@/utils/homePageCmsCall';
import SideCart from '@/components/SideCart/SideCart';
import ListLoginModal from '@/components/ListLoginModal/ListLoginModal';
import OrdersLoginModal from '@/components/OrdersLoginModal/OrdersLoginModal';
import GuestUserStore from '@/store/guestUser';
import ProfileMenuSection from './ProfileMenuSection/ProfileMenuSection';
import User from '@/store/user';
import { prefferenceModalStore, snackBarMessageStore, prefferenceSnackBarStore } from '../store/prefferenceModal';
import ProductsPrefferenceModal from './ProductsPrefferenceModal/ProductsPrefferenceModal';
import { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import CookieBar from './CookieBar/CookieBar';
import CartStore, { productsForOrder, isEditStarted, productsToKeep } from '@/store/cart';
import PriceChangesAvailabilityModal from './PriceChangesAvailabilityModal/PriceChangesAvailabilityModal';
import { priceChangesModalStore } from '@/store/priceChangesStore';
import useDeliveryServices from '@/hooks/useDeliveryServices.js';
import BundleOfferAside from '@/components/BundleOfferAside/BundleOfferAside';
import { DeliveryServicesContext } from '../contexts/DeliveryServicesContext';
import { getOrFetchConfiguration, createPaymentMethods } from '@/utils/getConfiguration';
import { BundleOfferSideSheetStore, PaymentOptionStore } from '@/store/sideSheet';
import ListAdditionAside from '@/components/Aside/ListAdditionAside';
import { isAddListSnackBarOpen } from '@/store/addListSnackBar';
import { ListLoginModalStore, OrderLoginModalStore } from '@/store/listLoginModal';
import { useTranslations } from 'next-intl';
import { isCartErrorSnackBarOpen } from '@/store/cartErrorSnackBar';
import { ModalContext } from '@/contexts/ModalProvider';
import { Shop } from '@/store/shop';
import { combineLatest, from } from 'rxjs';
import { transformCart } from '@/utils/products/products';
import useBundleOffersSummary from '@/hooks/useBundleOffersSummary';
import { fetchCart } from '@/api/syncCart';
import Cookies from 'universal-cookie';
import { updateCartOrder } from '@/utils/products/products.js';
import { mergeProductArrays } from '@/utils/cart';
import { IsFromLoginStore } from '@/store/isFromLogin';

export default function PageLayout({ children, isUserLayout }) {
  const isMobile = useWindowUnderThreshold(850);
  const imageUrl = 'assets/wave-grocery/Empty-States-Default/404.png';
  const [user, setUser] = useState();
  const [config, setConfig] = useState();
  const [guestUser, setGuestUser] = useState({});
  const [products, setProducts] = useState();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isPricesModalOpen, setIsPricesModalOpen] = useState(false);
  const [timeslotsText, setTimeslotsText] = useState('');
  const [timeslotsSecondText, setTimeslotsSecondText] = useState('');
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [isBundlesSideSheetVisible, setIsBundlesSideSheetVisible] = useState(false);
  const [showListSnackBar, setShowListSnackbar] = useState(false);
  const [isListLoginModalOpen, setIsListLoginModalOpen] = useState(false);
  const [isOrderLoginModalOpen, setIsOrderLoginModalOpen] = useState(false);
  const [snackBarCartOpen, setSnackBarCartOpen] = useState(false);
  const { openActionModal, close } = useContext(ModalContext);
  const headerProps = children?.props?.headerProps;
  const footerProps = children?.props?.footerProps;
  const router = useRouter();
  const { bundleOffersSummary } = useBundleOffersSummary();
  const cookies = new Cookies();
  const authorization = cookies.get('token');
  useEffect(() => {
    const shop$ = Shop.subject;
    const user$ = User.subject;
    const subscription = combineLatest([shop$, user$]).subscribe(([shopValue, user]) => {
      if (
        isEmpty(shopValue?.selectedShop) &&
        process.env.hasStoreNecessary &&
        !isEmpty(user) &&
        user?.addresses?.length === 0
      ) {
        router.replace('/select-service');
        return;
      }
      if (isEmpty(shopValue?.selectedShop) && process.env.hasStoreNecessary) {
        router.replace('/welcome');
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [router]);

  const {
    deliveryServices,
    options: servicesV3,
    addressPickerStatus,
    selectedService,
    updateSelectedDeliveryService,
    selectedShop,
    selectedDeliveryShop,
    setSelectedService,
    getOrFetchDeliveryServices,
    getCollectionTypes,
    setSelectedShop,
    setSelectedDeliveryShop,
    selectedUserAddressIndex,
    setSelectedUserAddressIndex,
    setSelectedTakeAwayStoreIndex,
    selectedTakeAwayStoreIndex,
  } = useDeliveryServices();

  const t = useTranslations();

  useEffect(() => {
    getOrFetchDeliveryServices();

    async function getConfiguration() {
      const configuration = await getOrFetchConfiguration();
      const methods = createPaymentMethods(configuration);
      if (methods && methods.length > 0) {
        PaymentOptionStore.update(methods[0]);
      }
      setConfig(configuration);
    }

    const fetchCartData = async () => {
      const collectionTypes = selectedShop?.collectionTypes;
      if (!collectionTypes) return;
      try {
        const result = await fetchCart();
        if (IsFromLoginStore.getValue()) {
          IsFromLoginStore.update(false);
          const prods = await updateCartOrder(
            productsForOrder.getValue(),
            'none',
            false,
            t('common.asides.side_cart.no_comment'),
            true
          );
          if (result?.data?.products?.length > 0) {
            const updated = await transformCart(
              mergeProductArrays(result.data.products, prods),
              'none',
              collectionTypes,
              selectedUserAddressIndex,
              bundleOffersSummary,
              t('common.asides.side_cart.no_comment')
            );
            updateCartOrder(updated, 'none', false, t('common.asides.side_cart.no_comment'), false);
          }
        } else {
          if (result?.data?.products?.length > 0) {
            transformCart(
              result.data.products,
              'none',
              collectionTypes,
              selectedUserAddressIndex,
              bundleOffersSummary,
              t('common.asides.side_cart.no_comment')
            );
          }
        }
      } catch (error) {
        console.error('Failed to fetch cart data:', error);
      }
    };

    getConfiguration();
    if (authorization != null) {
      if (!isEmpty(authorization) && process.env.NEXT_PUBLIC_SYNC_CART === 'true') {
        fetchCartData();
      }
    }
  }, [selectedShop]);

  const errorText = (
    <Text classOption={styles.text} type={isMobile ? TextEnum.SubHeading3 : TextEnum.SubHeading1}>
      {t('common.server_error.message')}
    </Text>
  );

  useEffect(() => {
    const user$ = User.subscribe(_user => {
      setUser(_user);
    });
    const guestUser$ = GuestUserStore.subscribe(setGuestUser);
    const productsForOrder$ = productsForOrder.subscribe(setProducts);
    const openPrefModal$ = prefferenceModalStore.subscribe(setIsConfirmationModalOpen);
    const openPricesModal$ = priceChangesModalStore.subscribe(setIsPricesModalOpen);
    const snackBarMessageStore$ = prefferenceSnackBarStore.subscribe(setSnackBarOpen);
    const addListSnackBar$ = isAddListSnackBarOpen.subscribe(setShowListSnackbar);
    const listLoginModal$ = ListLoginModalStore.subscribe(setIsListLoginModalOpen);
    const orderLoginModal$ = OrderLoginModalStore.subscribe(setIsOrderLoginModalOpen);
    const isCartErrorSnackBarOpen$ = isCartErrorSnackBarOpen.subscribe(setSnackBarCartOpen);
    const cartIsVisible$ = CartStore.getIsVisble$().subscribe(_cartVisible => {
      setIsCartVisible(_cartVisible);
    });
    const bundleOfferSideSheetStore$ = BundleOfferSideSheetStore.subscribe(_isBundlesSideSheetOpen => {
      setIsBundlesSideSheetVisible(_isBundlesSideSheetOpen);
    });
    if (isEditStarted.getValue()) {
      openActionModal({
        closeOnOuterClick: true,
        title: `${t('common.asides.side_cart.cancel_back_edit')}`,
        text: t('common.asides.side_cart.cancel_back_edit_description'),
        actions: [
          {
            btnLabel: t('common.asides.side_cart.btn_label'),
            onClick: () => {
              close();
            },
          },
        ],
        img: {
          url: '/assets/wave-grocery/Bus.png',
          ariaLabel: t('common.asides.side_cart.cancel_back_edit'),
          size: 'medium',
        },
      });
    }
    return () => {
      guestUser$.unsubscribe();
      user$.unsubscribe();
      openPrefModal$.unsubscribe();
      productsForOrder$.unsubscribe();
      openPricesModal$.unsubscribe();
      cartIsVisible$.unsubscribe();
      bundleOfferSideSheetStore$.unsubscribe();
      snackBarMessageStore$.unsubscribe();
      addListSnackBar$.unsubscribe();
      listLoginModal$.unsubscribe();
      orderLoginModal$.unsubscribe();
      isCartErrorSnackBarOpen$.unsubscribe();
    };
  }, [router]);
  if (!headerProps) return null;
  return (
    <DeliveryServicesContext.Provider
      value={{
        user,
        guestUser,
        addressPickerStatus,
        deliveryServices,
        servicesV3,
        options: servicesV3,
        selectedService,
        selectedShop,
        selectedDeliveryShop,
        updateSelectedDeliveryService,
        setSelectedService,
        getOrFetchDeliveryServices,
        getCollectionTypes,
        setSelectedShop,
        setSelectedDeliveryShop,
        selectedUserAddressIndex,
        setSelectedUserAddressIndex,
        setSelectedTakeAwayStoreIndex,
        selectedTakeAwayStoreIndex,
      }}
    >
      <MobileAppsBanner />
      <Header
        logo={headerProps.logo}
        menuList={headerProps.menu}
        selectedIndex={headerProps.selectedIndex}
        responsiveLogo={headerProps.responsiveLogo}
        headerCategories={headerProps.headerCategories}
        user={user}
        guestUser={guestUser}
        message={timeslotsText}
        setMessage={setTimeslotsText}
        timeslotsSecondText={timeslotsSecondText}
        setTimeslotsSecondText={setTimeslotsSecondText}
        config={config}
      />
      <MobileMenu
        user={user ? user : null}
        guestUser={guestUser ? guestUser : null}
        message={timeslotsText}
        setMessage={setTimeslotsText}
      >
        <ProfileMenuSection config={config} user={user ? user : null} />
      </MobileMenu>
      {isUserLayout ? (
        <div className={cn(styles.hpWidth)}>
          <div className={cn(styles.layout)}>
            <Heading
              type={HeadingEnum.HeadingL}
              color={TextColorEnum.OnSurfaceHighEmphasis}
              bold={BoldEnum.Bold}
              classOption={styles.menu}
            >
              {t('common.user.profile.header_title')}
            </Heading>

            <div className={cn(styles.content)}>
              <div style={{ height: 600 }} className={styles.menu}>
                <ProfileMenuSection user={user} config={config} selected={'credentials'} />
              </div>
              <ErrorBoundary fallback={<div>There was an error</div>}>{children}</ErrorBoundary>
            </div>
          </div>
        </div>
      ) : (
        <ErrorBoundary
          fallback={
            <main style={{ padding: '3rem 0' }}>
              <PageNotFound
                text={errorText}
                img={{
                  size: isMobile ? 'large' : 'medium',
                  url: `${process.env.NEXT_PUBLIC_HOSTNAME}${imageUrl}`,
                  alt: 'There was a problem',
                }}
                actions={[
                  { btnLabel: t('common.server_error.action_label'), onClick: router.back, leftIcon: ArrowBack },
                ]}
              />
            </main>
          }
        >
          {React.cloneElement(children, { products })}
        </ErrorBoundary>
      )}
      {/*  {React.cloneElement(children, { products })}*/}
      {isCartVisible && <SideCart />}
      {snackBarCartOpen && (
        <div style={{ width: '100%' }}>
          <SnackBar
            open={snackBarCartOpen}
            setOpen={() => {
              setSnackBarCartOpen(false);
              isCartErrorSnackBarOpen.update(false);
            }}
            text={t('common.cart.select_shop')}
            hasCloseOption={true}
            className={styles.snackBarContainer}
            position={'center'}
          />
        </div>
      )}
      {isBundlesSideSheetVisible && <BundleOfferAside />}
      <ListAdditionAside />
      {isListLoginModalOpen && <ListLoginModal />}
      {isOrderLoginModalOpen && <OrdersLoginModal />}
      {isConfirmationModalOpen && (
        <ProductsPrefferenceModal
          isOpen={isConfirmationModalOpen}
          modalClose={() => setIsConfirmationModalOpen(false)}
        />
      )}
      {snackBarOpen && (
        <OffersSnackbar
          emoji="🙌"
          open={snackBarOpen}
          setOpen={() => {
            setSnackBarOpen(false);
            snackBarMessageStore.update('');
            prefferenceSnackBarStore.update(false);
          }}
          hasCloseOption={true}
          position="center"
          type="warning"
          autoWidth={true}
          buttonText={''}
          text={snackBarMessageStore.getValue()}
          onButtonPress={() => {
            setSnackBarOpen(false);
            snackBarMessageStore.update('');
            prefferenceSnackBarStore.update(false);
          }}
          autoHideDuration={4000}
          closeOnClickAway={true}
          hasShadow={true}
        />
      )}
      {isPricesModalOpen && (
        <PriceChangesAvailabilityModal isOpen={isPricesModalOpen} modalClose={() => setIsPricesModalOpen(false)} />
      )}
      <Footer
        socialList={footerProps.socialList}
        nameHolder={footerProps.nameHolder}
        logo={footerProps.contactLogo}
        languanegList={setLang(footerProps.languangeList)}
        phone={footerProps.phone}
        cmsCategories={footerProps.cmsCategories}
        newsLetter={footerProps.newsLetter}
        footerCategories={footerProps.footerCategories}
      />
      <CookieBar />
    </DeliveryServicesContext.Provider>
  );
}
