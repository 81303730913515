import { BehaviorSubject } from 'rxjs';
import jsonStorage from '../utils/json-storage';

export const IsFromLoginStore = {
  subject: new BehaviorSubject(false),

  _lazyInit() {
    if (IsFromLoginStore.subject) return IsFromLoginStore.subject;
    const storage = window.localStorage;
    const IsFromLogin = jsonStorage.get('IsFromLogin', { storage });
    IsFromLoginStore.subject = new BehaviorSubject(IsFromLogin);
    return IsFromLoginStore.subject;
  },
  update: IsFromLogin => {
    const store = IsFromLoginStore._lazyInit();
    store.next(IsFromLogin);
    const storage = window.localStorage;
    jsonStorage.set('IsFromLogin', IsFromLogin, { storage });
  },
  subscribe: setState => {
    const store = IsFromLoginStore._lazyInit();
    return store.subscribe(setState);
  },
  getValue: () => {
    const store = IsFromLoginStore._lazyInit();
    return store.value;
  },
};
