// import { captureException } from '@sentry/node';

export function initGlobalValues(cpaAccount) {
  Object.assign(window, {
    cpaAccount: cpaAccount,
    cpaE: '',
    cpa_async: {
      ctag: '',
      utmipn: '',
      utmipc: '',
      utmtid: '',
      utmtto: '',
      cutmcn: '',
      cutmcc: '',
    },
  });
}

export function updateUser(userEmail) {
  try {
    window.cpaE = userEmail;
    if (window.cpaEv) {
      window.cpaEv();
    }
    return {};
  } catch (error) {
    // captureException(error);
    console.error(error);
    return { error: "Couldn't update contact pigeon" };
  }
}

export async function submitNewsletterSubscribe(newsLetterInputLink, newsLetterHiddenInputValue, newsLetterEmail) {
  const response = await fetch(`${newsLetterInputLink}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      gm_pid: `${newsLetterHiddenInputValue}`,
      sfemail: newsLetterEmail,
    }),
    redirect: 'follow', // Prevent automatic redirection
    mode: 'no-cors',
  });

  return response;
}
