import distanceFromTwoPoints from './distanceFromTwoPoints';
import { getGreekVariants } from './greekUtils';
import { getGreeklishVariants } from 'greek-utilities';
import { getCurrentDay, getWorkingHoursInfo } from '@/utils/dates/dates.js';
import { TextColorEnum, StoreSelector, MapPinEnum } from '@desquared/wave-components-library';

export function modifyShops(stores, objCoordinates) {
  const transformedStores = stores.map(s => {
    const storesWithSearchTerms = addSearchTerms(s);
    const storesWithDistance = distanceFromShop(s, objCoordinates);
    return { ...storesWithSearchTerms, ...storesWithDistance };
  });
  return transformedStores;
}

export function addSearchTerms(store) {
  const nameSearchTerms = getGreeklishVariants(store.name.toLowerCase());
  nameSearchTerms.push(...getGreekVariants(store.name.toLowerCase()));

  const { region, city, street } = store.address;
  const addressSearchTerms = [region.toLowerCase(), city.toLowerCase(), street.toLowerCase()];

  const searchTerms = [...nameSearchTerms, ...addressSearchTerms];
  return { ...store, searchTerms };
}

export function distanceFromShop(store, objCoordinates) {
  const distance = distanceFromTwoPoints(
    objCoordinates.lat,
    objCoordinates.lng,
    store.address.latitude,
    store.address.longitude,
    'K'
  );
  const formattedDistance = parseInt(distance.toFixed(1));
  const address = { ...store.address, distance: formattedDistance };
  return { ...store, address };
}

export function addressName(s) {
  return s.street + ' ' + s.streetNo + ', ' + s.city + ' ' + s.postalCode;
}

export function getStoresList(filteredShops, selectedStoreIndex, unitShorthand = 'χλμ.') {
  let enhancedMapStores = [];
  let enhancedStores = [];

  filteredShops?.forEach((element, index) => {
    if (element) {
      //-1 cause Sunday is not included in the list of working days
      const workingHoursObj = element?.workingHours?.[getCurrentDay() - 1];

      let mapStore = {
        lat: element.address?.latitude,
        lng: element.address?.longitude,
        postal: undefined,
        pin: MapPinEnum.Wave,
      };

      let simpleStore = {
        ...element,
        textColor: TextColorEnum.Gray1,
        isSelected: index === selectedStoreIndex,
        title:
          element.address?.distance && element.address?.distance !== '0.0'
            ? element.name + ' (' + element.address?.distance + ` ${unitShorthand})`
            : element.name,
        subTitle: addressName(element.address),
        ...getWorkingHoursInfo(workingHoursObj?.opensAt, workingHoursObj?.closesAt),
        id: element._id,
        postalCode: element.address?.postalCode,
      };
      enhancedMapStores.push(mapStore);
      enhancedStores.push(simpleStore);
    }
  });

  return { enhancedMapStores, enhancedStores };
}

export function transformShops(shops, coordinates) {
  if (!coordinates)
    return shops.map(s => {
      const storesWithSearchTerms = addSearchTerms(s);
      return { ...storesWithSearchTerms };
    });

  const transformedShops = modifyShops(shops, coordinates);
  transformedShops.sort((a, b) => {
    if (a.address.distance < b.address.distance) {
      return -1;
    }
    if (a.address.distance > b.address.distance) {
      return 1;
    }
    return 0;
  });

  return transformedShops;
}

export function transformShopsWithDistance(shops, distances) {
  const transformedShops = shops
    .map((shop, index) => {
      return { ...shop, address: { ...shop.address, distance: parseInt(distances[index] / 1000).toFixed(1) } };
    })
    .map(addSearchTerms);

  transformedShops.sort((a, b) => {
    if (a.address.distance < b.address.distance) {
      return -1;
    }
    if (a.address.distance > b.address.distance) {
      return 1;
    }
    return 0;
  });

  return transformedShops;
}
