import { useEffect, useState } from 'react';

export default function useDeviceDetect() {
  const [isMobile, setMobile] = useState(false);
  const [isAndroid, setIsAndroid] = useState();
  const [isIos, setIsIos] = useState();
  const [isSafari, setIsSafari] = useState();
  const [isMiBrowser, setIsMiBrowser] = useState();

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));
    const android = Boolean(userAgent.match(/android/i));
    const ios = Boolean(userAgent.match(/iPad|iPhone|iPod/i)) && !window.MSStream;
    const safari = Boolean(/iPhone|iPad|iPod/i.test(userAgent) && /AppleWebKit/i.test(userAgent)) && !window.MSStream;
    const miBrowser = Boolean(userAgent.indexOf('XiaoMi/MiuiBrowser') != -1);

    setMobile(mobile);
    setIsAndroid(android);
    setIsIos(ios);
    setIsSafari(safari);
    setIsMiBrowser(miBrowser);
  }, []);

  return { isMobile, isAndroid, isIos, isSafari, isMiBrowser };
}
