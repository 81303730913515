import { BehaviorSubject } from 'rxjs';
import Router from 'next/router.js';
import Cookies from 'universal-cookie';
import { TextColorEnum } from '@desquared/wave-components-library';
import { assingIcon, assignLink } from '../utils/homePageCmsCall.js';
import { setCategories, setCategoriesFull } from '../utils/homePageCmsCall.js';
import { setHeaderCategories } from '../utils/homePageCmsCall.js';
import { ListLoginModalStore, OrderLoginModalStore } from '@/store/listLoginModal';

const subjectsocialList = new BehaviorSubject([]);
const subjectnameHolder = new BehaviorSubject();
const subjectlogo = new BehaviorSubject();
const subjectlanguanegList = new BehaviorSubject();
const subjectphone = new BehaviorSubject();
const subjectNewsLetter = new BehaviorSubject();
const subjectCmsCategories = new BehaviorSubject([]);
const subjectFooterCategories = new BehaviorSubject([[]]);
const subjectCartBanner = new BehaviorSubject([]);
const menuList = new BehaviorSubject();
const subjectHeaderCategories = new BehaviorSubject([]);
const subjectCategories = new BehaviorSubject([]);
const subjectCartProducts = new BehaviorSubject();

const menuListFetchedBehavior = new BehaviorSubject(false);
const menuListFetched = {
  update: fetched => {
    menuListFetchedBehavior.next(fetched);
  },
};
const categoriesFetchedBehavior = new BehaviorSubject(false);
const categoriesFetched = {
  update: fetched => {
    categoriesFetchedBehavior.next(fetched);
  },
};
const headerCategoriesFetchedBehavior = new BehaviorSubject(false);
const headerCategoriesFetched = {
  update: fetched => {
    headerCategoriesFetchedBehavior.next(fetched);
  },
};
const socialListFetchedBehavior = new BehaviorSubject(false);
const socialListFetched = {
  update: fetched => {
    socialListFetchedBehavior.next(fetched);
  },
};
const nameHolderFetchedBehavior = new BehaviorSubject(false);
const nameHolderFetched = {
  update: fetched => {
    nameHolderFetchedBehavior.next(fetched);
  },
};
const logoFetchedBehavior = new BehaviorSubject(false);
const logoFetched = {
  update: fetched => {
    logoFetchedBehavior.next(fetched);
  },
};
const languanegListFetchedBehavior = new BehaviorSubject(false);
const languanegListFetched = {
  update: fetched => {
    languanegListFetchedBehavior.next(fetched);
  },
};
const phoneFetchedBehavior = new BehaviorSubject(false);
const phoneFetched = {
  update: fetched => {
    phoneFetchedBehavior.next(fetched);
  },
};
const newsLetterFetchedBehavior = new BehaviorSubject(false);
const newsLetterFetched = {
  update: fetched => {
    newsLetterFetchedBehavior.next(fetched);
  },
};
const cmsCategoriesFetchedBehavior = new BehaviorSubject(false);
const cmsCategoriesFetched = {
  update: fetched => {
    cmsCategoriesFetchedBehavior.next(fetched);
  },
};
const footerCategoriesFetchedBehavior = new BehaviorSubject(false);
const footerCategoriesFetched = {
  update: fetched => {
    footerCategoriesFetchedBehavior.next(fetched);
  },
};
const cartProductsFetchedBehavior = new BehaviorSubject(false);
const cartProductsFetched = {
  update: fetched => {
    cartProductsFetchedBehavior.next(fetched);
  },
};

export const menuListStore = {
  update: (value, selectedIndex) => {
    menuList.next(SetMenu(value, selectedIndex));
    menuListFetched.update(true);
  },
  subscribe: setState => menuList.subscribe(setState),
  getValue: () => menuList.value,
  isFetched: () => menuListFetchedBehavior.value,
  updateSelected: selectedIndex => {
    menuList.value.forEach((item, index) => {
      item.isSelected = selectedIndex === index;
    });
  },
};

export const SetMenu = (data, selectedIndex) => {
  if (!data) return [];
  const cookies = new Cookies();
  const authorization = cookies.get('token');

  let list = [];

  let data2 = data.data[0].content.item;
  let dataList = data.included.menu_item;
  const sortedList = [...dataList].sort((a, b) => {
    const indexA = data2.findIndex(item => item.id === a.id);
    const indexB = data2.findIndex(item => item.id === b.id);
    return indexA - indexB;
  });
  let icon = data.data[0].content.has_icon;

  sortedList.forEach((element, index) => {
    let tempCard = {
      text: element.content.title,
      hasIcon: icon,
      textColor: TextColorEnum.Gray1,
      icon: assingIcon(element.content?.enum, 0),
      selectedIcon: assingIcon(element.content?.enum, 1),
      alignment: 'center',
      isSelected: selectedIndex === index && selectedIndex !== -1,
      isMobile: false,
      url:
        (process.env.NEXT_PUBLIC_HOSTNAME.slice(0, -1) || 'http://localhost:3000') + assignLink(element.content?.enum),
      onClick: () => {
        if (!authorization && (element.content?.enum === 2 || element.content?.enum === 3)) {
          if (element.content?.enum === 2) {
            ListLoginModalStore.open();
          } else {
            OrderLoginModalStore.open();
          }
        } else {
          menuListStore.updateSelected(index);
          const link = assignLink(element.content?.enum);
          Router.push(link);
        }
      },
    };

    list.push(tempCard);
  });
  if (sortedList.length === 3 && process.env.headerVersion === 'v2') {
    list.splice(1, 0, {
      text: '',
      hasIcon: false,
      textColor: TextColorEnum.Black,
      icon: null,
      selectedIcon: null,
      alignment: 'center',
      isSelected: false,
      isMobile: false,
      url: '',
      onClick: () => {},
    });
  }
  return list;
};

export const socialListStore = {
  update: value => {
    subjectsocialList.next(setSocial(value));
    socialListFetched.update(true);
  },
  subscribe: setState => subjectsocialList.subscribe(setState),
  getValue: () => subjectsocialList.value,
  isFetched: () => socialListFetchedBehavior.value,
};
export const setSocial = data => {
  if (!data) return [];
  let cards = [];
  data.forEach(element => {
    if (!element.content.enabled) return;

    let tempCard = {
      type: element.content.title,
      href: element.content.url,
    };

    cards.push(tempCard);
  });

  return cards;
};
export const nameHolderStore = {
  update: value => {
    subjectnameHolder.next(value);
    nameHolderFetched.update(true);
  },
  subscribe: setState => subjectnameHolder.subscribe(setState),
  getValue: () => subjectnameHolderSvalue,
  isFetched: () => nameHolderFetchedBehavior.value,
};
export const logoStore = {
  update: value => {
    subjectlogo.next(value);
    logoFetched.update(true);
  },
  subscribe: setState => subjectlogo.subscribe(setState),
  getValue: () => subjectlogo.value,
  isFetched: () => logoFetchedBehavior.value,
};
export const languanegListStore = {
  update: value => {
    subjectlanguanegList.next(value);
    languanegListFetched.update(true);
  },
  subscribe: setState => subjectlanguanegList.subscribe(setState),
  getValue: () => subjectlanguanegList.value,
  isFetched: () => languanegListFetchedBehavior.value,
};
export const phoneStore = {
  update: value => {
    subjectphone.next(value);
    phoneFetched.update(true);
  },
  subscribe: setState => subjectphone.subscribe(setState),
  getValue: () => subjectphone.value,
  isFetched: () => phoneFetchedBehavior.value,
};
export const newsLetterStore = {
  update: value => {
    subjectNewsLetter.next(value);
    newsLetterFetched.update(true);
  },
  subscribe: setState => subjectNewsLetter.subscribe(setState),
  getValue: () => subjectNewsLetter.value,
  isFetched: () => newsLetterFetchedBehavior.value,
};
export const cmsCategoriesStore = {
  update: (value, isMobile) => {
    subjectCmsCategories.next(setCategories(value, isMobile));
    cmsCategoriesFetched.update(true);
  },
  subscribe: setState => subjectCmsCategories.subscribe(setState),
  getValue: () => subjectCmsCategories.value,
  isFetched: () => cmsCategoriesFetchedBehavior.value,
};
export const footerCategoriesStore = {
  update: (value, isMobile) => {
    subjectFooterCategories.next(setCategoriesFull(value, isMobile));
    footerCategoriesFetched.update(true);
  },
  subscribe: setState => subjectFooterCategories.subscribe(setState),
  getValue: () => subjectFooterCategories.value,
  isFetched: () => footerCategoriesFetchedBehavior.value,
};
export const cartBannerStore = {
  update: (value, isMobile) => {
    subjectCartBanner.next(setCategoriesFull(value, isMobile));
    cartBannerFetched.update(true);
  },
  subscribe: setState => subjectCartBanner.subscribe(setState),
  getValue: () => subjectCartBanner.value,
  isFetched: () => cartBannerFetchedBehavior.value,
};

export const headerCategoriesStore = {
  update: value => {
    subjectHeaderCategories.next(setHeaderCategories(value));
    headerCategoriesFetched.update(true);
  },
  subscribe: setState => subjectHeaderCategories.subscribe(setState),
  getValue: () => subjectHeaderCategories.value,
  isFetched: () => headerCategoriesFetchedBehavior.value,
};
export const categoriesStore = {
  update: value => {
    subjectCategories.next(value);
    categoriesFetched.update(true);
  },
  subscribe: setState => subjectCategories.subscribe(setState),
  getValue: () => subjectCategories.value,
  isFetched: () => categoriesFetchedBehavior.value,
};
export const cartProductsStore = {
  update: value => {
    subjectCartProducts.next(value);
    cartProductsFetched.update(true);
  },
  subscribe: setState => subjectCartProducts.subscribe(setState),
  getValue: () => subjectCartProducts.value,
  isFetched: () => cartProductsFetchedBehavior.value,
};
