import { usePrebook } from '@/hooks/usePrebook.js';
import { getProductsCartInfo, selectShippingMethod } from '@/utils/analytics.js';
import { formatPrebookDate, getCurrentDayTitle } from '@/utils/dates/dates.js';
import { setHeaderCategories } from '@/utils/homePageCmsCall.js';
import { HeaderV1, HeaderMobileV1, HeaderV2 } from '@desquared/wave-components-library';
import React, { useState, useEffect, Suspense, useContext } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { distinctUntilChanged } from 'rxjs/operators';
import Router from 'next/router';
import { useTranslations } from 'next-intl';
import { useDateFormatting } from '@/contexts/DateFormattingProvider';
import { getShopsV3 } from '@/api/Shops';
import { ModalContext } from '@/contexts/ModalProvider.jsx';

import {
  SideSheetStore,
  CompanyAsideSideSheetStore,
  ResponsiveMenuStore,
  PostalCodeSideSheetStore,
  AddressViewSideSheetStore,
  ServiceAddressSideSheetStore,
  PrebookSideSheetStore,
  TimesSlotsSideSheetStore,
} from '@/store/sideSheet';
import { DeliveryServicesContext } from '@/contexts/DeliveryServicesContext';
import useWindowUnderThreshold from '../hooks/useWindowUnderThreshold';
import User from '../store/user';
import { createUserAddress } from '@/api/users';
import CartStore, { cartPrice, fullCartStore, productsForOrder } from '@/store/cart';
import AddressViewAside from '@/components/AddressViewAside/AddressViewAside';
import Aside from '@/components/Aside/Aside';
import PostalCodeAside from '@/components/Aside/PostalCodeAside';
import { PostalCode, SelectedShopId } from '@/store/isAddressRegistered';
import LogoutModal from './LogoutModal/LogoutModal';
import SuggestedSearchResults from './SuggestedSearchResults/SuggestedSearchResults';
import { menuListStore } from '@/store/headerFooter';
import { Shop } from '@/store/shop';
import PrebookStore from '@/store/prebook';
import ProductsSearchStore from '@/store/productsSearch';
import { HeaderMessageStore } from '@/store/headerMessage';
import { getValidObject } from '@/utils/objectManipulation';
import {
  fetchTimeslotsTakeAway,
  fetchTimeslotsDelivery,
  changeSelectedIndexes,
  createPredictiveTimeslotText,
  PREDICTION_TIMESLOT_TOKEN,
  EXACT_DATE_TIMESLOT_TOKEN,
} from '@/utils/logic/timeslots';
import CompanyAside from './Aside/CompanyAside';
import PrebookAside from './PrebookAside/PrebookAside.jsx';
import ServiceAddressAside from './Aside/ServiceAddressAside';
import { countDistinctIds } from '@/utils/products';
import { useActionItems } from '@/hooks/useActionItems';
import { getAddressType } from '@/utils/addressUtilities';
import useGenericTimeslots from '@/hooks/useGenericTimeslots';
import { formatUTCTimeslotsToLocal } from '@/utils/dates/dates.js';
import { isNumber } from 'lodash';
import { SetMenu } from '@/store/headerFooter';
import styles from '../styles/Header.module.css';
import { CurrencyContext } from '@/contexts/CurrencyProvider';

export default function Header(props) {
  const isMobile = useWindowUnderThreshold(850);
  const isUserLoggedIn = props?.user?._id;
  const [status, setStatus] = useState('unselected');
  const [deliveryServiceText, setDeliveryServiceText] = useState('unselected');
  const [address, setAddress] = useState(undefined);
  const [sideSheet, setSideSheet] = useState(false);
  const [menuList, setMenuList] = useState(SetMenu(props.menuList, 0));
  const [logoutModal, setLogoutModal] = useState(false);
  const [showAddAddressMenu, setShowAddAddressMenu] = useState(false);
  const [postalCode, setPostalCode] = useState('');
  const token = props?.user?.token;
  const [priceCart, setCartPrice] = useState(0);
  const router = useRouter();
  const { actionItems, loggedOutActionItems } = useActionItems(setLogoutModal);
  const [items, setItems] = useState(actionItems);
  const [isNotEligible, setIsNotEligible] = useState(false);
  const [isNotEligibleText, setIsNotEligibleText] = useState('');
  const [query, setQuery] = useState('');
  const [hideSuggestions, setHideSuggestions] = useState(true);
  const [genericTimeslots, setGenericTimeslots] = useState({});
  const [genericIndexes, setGenericIndexes] = useState({});
  const [timeslotsSideSheet, setTimeslotsSideSheet] = useState(false);
  const [companySideSheet, setCompanySideSheet] = useState(false);
  const [selectedShopId, setSelectedShopId] = useState(null);
  const [postalCodeSideSheet, setPostalCodeSideSheet] = useState(false);
  const [serviceAddressSideSheet, setServiceAddressSideSheet] = useState(false);
  const [hasPredictiveTimeslot, setHasPredictiveTimeslot] = useState(false);
  const [avatar, setAvatar] = useState();
  const { formatter } = useContext(CurrencyContext);
  const { timeFormat } = useDateFormatting();
  const { openActionModal, close } = useContext(ModalContext);

  const t = useTranslations();

  const formattedHeaderCategories = setHeaderCategories(props.headerCategories);

  const {
    servicesV3,
    addressPickerStatus,
    selectedService,
    updateSelectedDeliveryService,
    selectedShop,
    setSelectedService,
    selectedUserAddressIndex,
    setSelectedShop,
    selectedTakeAwayStoreIndex,
  } = useContext(DeliveryServicesContext);

  const { prebook } = usePrebook(PrebookStore);

  const isPrebookingEnabled = !!selectedService?.timeslots?.prebook?.enabled;

  const showChangeServiceModal = serviceId => {
    openActionModal({
      closeOnOuterClick: true,
      hasCenteredText: true,
      title: t('common.header.service_modal.text'),
      text: t('common.header.service_modal.description'),
      actions: [
        {
          btnLabel: t('common.header.service_modal.continue'),
          onClick: () => {
            close();
            onDeliveryServiceSelection(serviceId);
          },
        },
        {
          btnLabel: t('common.header.service_modal.close'),
          onClick: () => {
            close();
          },
          btnVariant: 'text',
        },
      ],
      img: {
        url: '/assets/wave-grocery/GlattLogo.webp',
        ariaLabel: t('common.header.service_modal.description'),
        size: 'medium',
      },
    });
  };

  const onDeliveryServiceSelection = serviceId => {
    updateSelectedDeliveryService(serviceId);
    ServiceAddressSideSheetStore.close();
    if (!process.env.hasStoreNecessary) {
      SelectedShopId.update('');
      Shop.setSelectedShop('');
      setSelectedShop('');
    }
    const selectedService = servicesV3.find(i => i.id === serviceId);
    if (
      isEmpty(props.user) &&
      isEmpty(props.guestUser) &&
      process.env.hasStoreNecessary &&
      selectedService?.deliversToAddress
    ) {
      router.replace('/postal-code-check');
      return;
    }
    if (
      !isEmpty(props.user) &&
      process.env.hasStoreNecessary &&
      (!props?.user?.addresses || props?.user?.addresses?.length === 0) &&
      selectedService?.deliversToAddress
    ) {
      router.replace('/address-details');
      return;
    }
    setSelectedService(selectedService);
    const shouldShowPostalCodeSelection = isEmpty(props.user) && isEmpty(props.guestUser);

    selectShippingMethod(getProductsCartInfo(fullCartStore.getValue().products), selectedService.id);

    if (selectedService?.deliversToAddress) {
      if (shouldShowPostalCodeSelection) PostalCodeSideSheetStore.open();
      //this should open the selection of addresses for the loggedin / guest user - the logic is handled internally in the Aside component
      SideSheetStore.open('');
    } else {
      //this should open the selection of stores as we are in the takeAway option - the logic is handled internally in the Aside component
      SideSheetStore.open('');
    }
  };
  const openMenu = () => {
    props.setMessage(props.message);
    ResponsiveMenuStore.open();
  };
  const openServiceAddressSidesheet = () => {
    ServiceAddressSideSheetStore.open();
  };

  const onService = () => {
    ServiceAddressSideSheetStore.open();
  };

  async function addAddress(newAddress) {
    setIsNotEligibleText('');
    setIsNotEligible(false);
    newAddress.type = getAddressType(newAddress.addressType);
    newAddress.contactPhone = newAddress.phone;
    if (process.env.hasStoreNecessary) {
      const shopsV3 = await getShopsV3(selectedService?.id, newAddress.postalCode);
      if (!shopsV3?.shops || shopsV3?.shops?.length === 0) {
        setIsNotEligibleText(t('common.header.no_service'));
        setIsNotEligible(true);
        return;
      }
    }

    const response = await createUserAddress(token, newAddress);
    if (response?.data) {
      AddressViewSideSheetStore.close('');
      SideSheetStore.open('');
    } else {
      setIsNotEligibleText(response.response?.data?.userMessage ?? 'Κάτι πήγε στραβά, δοκιμάστε ξανά αργότερα');
      setIsNotEligible(true);
    }
  }

  useEffect(() => {
    if (!router.isReady) return;
    const searchTerm = { ...getValidObject('product', router?.query?.product) };
    ProductsSearchStore.updateQuery(searchTerm?.product || '');
  }, [router.isReady]);

  function updateURL(routerAction, product = query) {
    const params = {
      product,
      ...getValidObject('sortBy', null),
    };

    router[routerAction](
      {
        pathname: '/search',
        query: params,
      },
      undefined,
      { shallow: false }
    );
  }

  function onChangeQuery(tempQuery) {
    setQuery(tempQuery);
    setHideSuggestions(false);
    if (tempQuery.length > 2) {
      ProductsSearchStore.updateQuery(tempQuery);
    }
  }
  function onSubmit() {
    if (!query || query.length === 0) {
      return;
    }
    setHideSuggestions(true);
    updateURL('push');
  }

  function onBlurSearchBar() {
    setTimeout(() => {
      setHideSuggestions(true);
    }, 300);
  }

  function onFocusSearchBar() {
    setHideSuggestions(false);
  }
  function onCompanyAsideClick() {
    CompanyAsideSideSheetStore.update(!companySideSheet);
  }
  const onPostalV2Back = () => {
    PostalCodeSideSheetStore.close();
    ServiceAddressSideSheetStore.open();
  };

  const getSelectedServiceText = t => {
    if (!selectedService) return t('common.header.choose_service');
    if (!selectedService?.deliversToAddress) {
      return selectedShop ? selectedService?.title + ', ' + selectedShop.name : selectedService?.title;
    }

    if (selectedService?.deliversToAddress) {
      if (isEmpty(props.user) && isEmpty(props.guestUser)) {
        return PostalCode.getValue()
          ? selectedService?.title + `, ${t('common.header.postal_code')}` + PostalCode.getValue()
          : selectedService.title;
      } else {
        const selectedAddress =
          props?.user?.addresses?.[isNumber(selectedUserAddressIndex) ? selectedUserAddressIndex : 0];
        return selectedAddress
          ? `${selectedService?.title},  ${selectedAddress?.street} ${selectedAddress?.streetNo}`
          : selectedService.title;
      }
    }

    return selectedService.title;
  };

  const onAsideBack = () => {
    if (!isEmpty(props.guestUser)) {
      if (selectedService?.deliversToAddress) {
        AddressViewSideSheetStore.close();
      } else {
        SideSheetStore.close();
      }

      ServiceAddressSideSheetStore.open();
      return;
    }
    if (!props.user && selectedService?.deliversToAddress) {
      PostalCodeSideSheetStore.close();
    } else {
      SideSheetStore.close();
    }

    ServiceAddressSideSheetStore.open();
  };

  useEffect(() => {
    if (!props.user) {
      setItems(loggedOutActionItems);
    } else {
      // let tempAddress = props.user?.mainAddress || props.user?.address?.[0] || {};
      setItems(actionItems);
    }
  }, [props.user, actionItems, loggedOutActionItems]);

  const [storesLoaded, setStoresLoaded] = useState(false);

  useEffect(() => {
    const TimesSlotsSideSheetStore$ = TimesSlotsSideSheetStore.subscribe(setTimeslotsSideSheet);
    const PrebookSideSheetStore$ = PrebookSideSheetStore.subscribe(setTimeslotsSideSheet);
    const PostalCodeSideSheetStore$ = PostalCodeSideSheetStore.subscribe(setPostalCodeSideSheet);
    const ServiceAddressSideSheetStore$ = ServiceAddressSideSheetStore.subscribe(setServiceAddressSideSheet);
    const UserSub = User.subscribe(user => {
      if (user && user.avatarURL) setAvatar(user.avatarURL);
      else setAvatar(undefined);
    });

    const handleRouteChangeStart = (url, { shallow }) => {
      if (!url.includes('search')) ProductsSearchStore.updateQuery('');
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    if (window && window.localStorage) setStoresLoaded(true);

    return () => {
      TimesSlotsSideSheetStore$.unsubscribe();
      PrebookSideSheetStore$.unsubscribe();
      PostalCodeSideSheetStore$.unsubscribe();
      ServiceAddressSideSheetStore$.unsubscribe();
      UserSub.unsubscribe();

      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, []);

  useEffect(() => {
    menuListStore.update(props.menuList, props.selectedIndex);
  }, [props.menuList, props.selectedIndex]);

  useEffect(() => {
    const SideSheetStore$ = SideSheetStore.subscribe(setSideSheet);
    const CompanyAsideSideSheetStore$ = CompanyAsideSideSheetStore.subscribe(setCompanySideSheet);

    const menuStore$ = menuListStore.subscribe(setMenuList);
    const postalCode$ = PostalCode.subscribe(setPostalCode);
    const cartPrice$ = cartPrice.subscribe(setCartPrice);
    const query$ = ProductsSearchStore.query(setQuery);
    const showAddAddressMenu$ = AddressViewSideSheetStore.subscribe(value => {
      setShowAddAddressMenu(value);
    });
    switch (addressPickerStatus) {
      case 1:
        setStatus('delivery');
        setDeliveryServiceText('Delivery,');
        let tempAddress;

        if (!isEmpty(props.guestUser)) {
          if (props.guestUser?.address) {
            tempAddress = props.guestUser?.address[0];
            fetchDeliveryMethods(postalCode, true);
          } else {
            {
              tempAddress = {};
            }
          }
        } else {
          tempAddress = User.getUserMainAddress();
        }
        if (!isEmpty(tempAddress)) {
          setAddress(tempAddress.street + ' ' + tempAddress.streetNo);
        } else {
          if (postalCode) {
            setAddress(postalCode);
            setStatus('pin');
          } else {
            setDeliveryServiceText(t('common.header.see_how'));
            setAddress('');
          }
        }
        break;
      case 0:
        setStatus('take away');
        setDeliveryServiceText('TakeAway,');
        let tempStore = Shop.getValue();
        if (tempStore?.takeAwayShop?.title) {
          setAddress(tempStore.takeAwayShop?.title.split(' ')[0] + ' ' + tempStore.takeAwayShop?.title.split(' ')[1]);
        } else {
          setDeliveryServiceText(t('common.header.see_how'));
          setAddress('');
        }

        break;
    }

    return () => {
      CompanyAsideSideSheetStore$.unsubscribe();
      query$.unsubscribe();
      SideSheetStore$.unsubscribe();
      menuStore$.unsubscribe();
      cartPrice$.unsubscribe();
      postalCode$.unsubscribe();
      showAddAddressMenu$.unsubscribe();
    };
  }, [
    addressPickerStatus,
    address,
    sideSheet,
    menuList,
    props.user,
    postalCode,
    props.guestUser,
    status,
    priceCart,
    // stateHeaderCategories,
  ]);

  useEffect(() => {
    PostalCode.lazyInit();
    const selectedShopId$ = SelectedShopId.subscribe(setSelectedShopId);

    const postalCode$ = PostalCode.subject.pipe(distinctUntilChanged()).subscribe(async value => {
      if (isPrebookingEnabled) {
        const newEasyTimeslots = await fetchTimeslotsDelivery(value, true, setGenericIndexes, selectedService?.id);

        if (newEasyTimeslots && newEasyTimeslots.type === PREDICTION_TIMESLOT_TOKEN) {
          const [firstText, secondText] = createPredictiveTimeslotText(
            newEasyTimeslots.prediction,
            t('common.header.predictive_timeslot.deliver_to'),
            t('common.header.predictive_timeslot.days')
          );
          props.setMessage(firstText);
          props.setTimeslotsSecondText(secondText);
          HeaderMessageStore.update(firstText + ' ' + secondText);
          setHasPredictiveTimeslot(true);
          return;
        }

        setHasPredictiveTimeslot(false);
        props.setMessage('');
        props.setTimeslotsSecondText('');
        HeaderMessageStore.update('');
        return;
      }

      const _user = User.getValue();
      if (selectedService?.deliversToAddress) {
        if (value) {
          const newEasyTimeslots = await fetchTimeslotsDelivery(value, true, setGenericIndexes, selectedService?.id);

          if (newEasyTimeslots && newEasyTimeslots.type === EXACT_DATE_TIMESLOT_TOKEN) {
            const localTimeslots = formatUTCTimeslotsToLocal(newEasyTimeslots.exactTimeTimeslots, timeFormat);
            setGenericTimeslots(localTimeslots);
            props.setTimeslotsSecondText('');
          }

          if (newEasyTimeslots && newEasyTimeslots.type === PREDICTION_TIMESLOT_TOKEN) {
            const [firstText, secondText] = createPredictiveTimeslotText(
              newEasyTimeslots.prediction,
              t('common.header.predictive_timeslot.deliver_to'),
              t('common.header.predictive_timeslot.days')
            );
            props.setMessage(firstText);
            props.setTimeslotsSecondText(secondText);
            HeaderMessageStore.update(firstText + ' ' + secondText);
            setHasPredictiveTimeslot(true);
          }
        }
        if (!value) {
          props.setMessage('');
          props.setTimeslotsSecondText('');
          HeaderMessageStore.update('');
        }
      } else {
        if (selectedService) {
          if (!selectedShopId) {
            props.setMessage('');
            props.setTimeslotsSecondText('');
            HeaderMessageStore.update('');
            return;
          }

          const takeAwayTimeslots = await fetchTimeslotsTakeAway(selectedService?.id);
          if (takeAwayTimeslots && takeAwayTimeslots.type === EXACT_DATE_TIMESLOT_TOKEN) {
            const localTimeslots = formatUTCTimeslotsToLocal(takeAwayTimeslots.exactTimeTimeslots, timeFormat);
            setGenericTimeslots(localTimeslots);
            changeSelectedIndexes(localTimeslots, setGenericIndexes, props.setMessage);
          }

          // No point in handling case for predictive in takeaway
        }
      }
    });

    return () => {
      postalCode$.unsubscribe();
      selectedShopId$.unsubscribe();
    };
  }, [addressPickerStatus, postalCode, selectedShopId, selectedService, setHasPredictiveTimeslot, router.locale]);

  useGenericTimeslots(
    false,
    timeslotsSideSheet,
    addressPickerStatus,
    genericTimeslots,
    [],
    genericIndexes,
    setGenericIndexes,
    props.setMessage,
    () => {},
    props.setTimeslotsSecondText,
    selectedShopId,
    isPrebookingEnabled,
    t
  );
  // return (
  //   <>
  //     {!isMobile && (
  //const newMessage = `${props.message}${props.timeslotsSecondText ? ',' + props.timeslotsSecondText : ''}`;

  const getTimePickerText = () => {
    if (!isUserLoggedIn) return undefined;

    if (hasPredictiveTimeslot) {
      return process.env.headerVersion === 'v1' ? props.message : props.message + ' ' + props.timeslotsSecondText;
    }

    if (isPrebookingEnabled) {
      const hasSelectedTimeslot = !!prebook?.reservation;
      if (hasSelectedTimeslot) {
        return `${getCurrentDayTitle(
          prebook?.reservation?.startsAt,
          router.locale,
          t('common.days.today')
        )} ${formatPrebookDate(prebook.reservation?.endsAt)}, ${formatPrebookDate(
          prebook?.reservation.startsAt,
          timeFormat
        )} -  ${formatPrebookDate(prebook?.reservation.endsAt, timeFormat)}`;
      }
      if (selectedService?.deliversToAddress && selectedUserAddressIndex !== undefined && !selectedShop)
        return t('common.timeslots.not_supported_region');
      if (!selectedService?.deliversToAddress && selectedTakeAwayStoreIndex !== undefined && !selectedShop)
        return t('common.timeslots.not_supported_region');
      if (!selectedShop) return '';
      return t('common.header.prebook_earliest_possible');
    }

    if (process.env.headerVersion === 'v1') {
      return `${props.message}`;
    } else {
      return `${props.message}${props.timeslotsSecondText ? ',' + props.timeslotsSecondText : ''}`;
    }
  };
  const finalHeader = props =>
    process.env.headerVersion === 'v1' ? (
      <>
        <HeaderV1
          logoHasContainer={process.env.logoHasContainer}
          logoUrl={process.env.NEXT_PUBLIC_HOSTNAME.slice(0, -1)}
          showSlots={router.pathname !== '/cart' && router.pathname !== '/checkout' && router.pathname !== '/thank-you'}
          showCart={router.pathname !== '/cart' && router.pathname !== '/checkout' && router.pathname !== '/thank-you'}
          topHeaderBackgroundColor={process.env.topHeaderBackgroundColor}
          bottomHeaderBackgroundColor={process.env.bottomHeaderBackgroundColor}
          isSearchBarRound={process.env.isSearchBarRound}
          isServicePickerDeliveryDisabled={false}
          isServicePickerTakeAwayDisabled={false}
          isServicePickerSelected={!addressPickerStatus}
          isAddressPickerSelected={false}
          addressPickerTextColor={process.env.addressPickerTextColor}
          addressPickerStatus={status}
          isProfileSelected={false}
          isProfileFilled={false}
          hasProfilePic={!!avatar}
          profileTextColor={process.env.profileTextColor}
          timePickerTextColor={process.env.timePickerTextColor}
          timePickerText={getTimePickerText()}
          timePickerSecondText={props.timeslotsSecondText}
          isTimePickerSelected={false}
          menuItemTextColor={process.env.menuItemTextColor}
          menuItemHasIcon={process.env.menuItemHasIcon}
          isCartSelected={false}
          cartRadius={process.env.cartRadius}
          cartPrice={formatter.format(priceCart)}
          isCartIconFilled={!storesLoaded ? false : productsForOrder.getValue().length > 0}
          numProductsInCart={!storesLoaded ? false : countDistinctIds(productsForOrder.getValue())}
          cartColor={process.env.cartColor}
          list={menuList}
          menuItemSeparatorColor={process.env.menuItemSeparatorColor}
          onAddressSelect={openServiceAddressSidesheet}
          onServiceSelect={onService}
          addresstext={getSelectedServiceText(t).split(',')[0]}
          addressSecondText={getSelectedServiceText(t).split(',').length ? getSelectedServiceText(t).split(',')[1] : ''}
          logo={props.logo}
          onLogoClick={() => {
            Router.push('/');
          }}
          onCartClick={() => {
            if (router.pathname !== '/checkout' && router.pathname !== '/cart') CartStore.setIsVisible(true);
          }}
          MyActionListProps={items}
          onProfileClick={() => {}}
          searchBarValue={query}
          setSearchBarValue={onChangeQuery}
          onSearchSubmit={onSubmit}
          searchBarOnBlur={onBlurSearchBar}
          searchBarOnFocus={onFocusSearchBar}
          firstRowPickerText={t('common.header.company')}
          onCompanySelect={() => {
            onCompanyAsideClick();
          }}
          searchBarPlaceholder={t('common.header.search_placeholder')}
          onTimePickerClick={() => {
            isPrebookingEnabled && selectedShop && !hasPredictiveTimeslot && PrebookSideSheetStore.open();
          }}
          profileAvatar={avatar}
          searchHasClose={process.env.searchHasClose}
          searchIconPosition={process.env.searchIconPosition}
        >
          {query.length > 2 && !hideSuggestions && (
            <Suspense fallback={<p />}>
              <SuggestedSearchResults terms={query} />
            </Suspense>
          )}
        </HeaderV1>
      </>
    ) : (
      <HeaderV2
        selectedServiceIcon={selectedService?.icon}
        logoUrl={process.env.NEXT_PUBLIC_HOSTNAME.slice(0, -1)}
        logo={props.logo}
        showCart={router.pathname !== '/cart' && router.pathname !== '/checkout' && router.pathname !== '/thank-you'}
        showSlots={router.pathname !== '/cart' && router.pathname !== '/checkout' && router.pathname !== '/thank-you'}
        logoHasContainer={process.env.logoHasContainer}
        topHeaderBackgroundColor={process.env.topHeaderBackgroundColor}
        bottomHeaderBackgroundColor={process.env.bottomHeaderBackgroundColor}
        isSearchBarRound={process.env.isSearchBarRound}
        isProfileSelected={false}
        isProfileFilled={false}
        hasProfilePic={!!avatar}
        profileTextColor={process.env.profileTextColor}
        timePickerTextColor={process.env.timePickerTextColor}
        timePickerText={getTimePickerText()}
        isTimePickerSelected={false}
        onTimeSelect={() => {
          isPrebookingEnabled && selectedShop && !hasPredictiveTimeslot && PrebookSideSheetStore.open();
        }}
        menuItemTextColor={process.env.menuItemTextColor}
        menuItemHasIcon={process.env.menuItemHasIcon}
        isCartSelected={false}
        cartRadius={process.env.cartRadius}
        cartPrice={formatter.format(priceCart)}
        isCartIconFilled={!storesLoaded ? false : productsForOrder.getValue().length > 0}
        numProductsInCart={!storesLoaded ? 0 : countDistinctIds(productsForOrder.getValue())}
        cartColor={process.env.cartColor}
        menuItemSeparatorColor={process.env.menuItemSeparatorColor}
        onLogoClick={() => {
          Router.push('/');
        }}
        MyActionListProps={items}
        onSearchSubmit={onSubmit}
        searchBarValue={query}
        setSearchBarValue={onChangeQuery}
        searchBarOnBlur={onBlurSearchBar}
        searchBarOnFocus={onFocusSearchBar}
        serviceAddressPickerText={getSelectedServiceText(t)}
        firstRowPickerText={t('common.header.company')}
        onServiceAddressSelect={openServiceAddressSidesheet}
        onCartClick={() => {
          if (router.pathname !== '/checkout' && router.pathname !== '/cart') CartStore.setIsVisible(true);
        }}
        flippedSecondRow={process.env.flippedSecondRow}
        onCompanySelect={() => {
          onCompanyAsideClick();
        }}
        onProfileClick={() => {}}
        firstRowlist={[menuList[2], menuList[3]]}
        secondRowlist={[menuList[0], menuList[1]]}
        searchBarPlaceholder={t('common.header.search_placeholder')}
        searchHasClose={process.env.searchHasClose}
        searchIconPosition={process.env.searchIconPosition}
        profileAvatar={avatar}
      >
        {query.length > 2 && !hideSuggestions && (
          <Suspense fallback={<p />}>
            <SuggestedSearchResults terms={query} />
          </Suspense>
        )}
      </HeaderV2>
    );

  return (
    <>
      <div className={styles.visibleOnDesktop}>{finalHeader(props)}</div>
      {/* <Icon size={IconSizeEnum.Small} IconComponent={ShoppingBag} iconColor={TextColorEnum.Gray1} /> */}
      <div className={styles.visibleOnMobile}>
        <Suspense>
          <HeaderMobileV1
            showCart={
              router.pathname !== '/cart' && router.pathname !== '/checkout' && router.pathname !== '/thank-you'
            }
            isRoundLogo={false}
            isDark={process.env.isMobileHeaderDark}
            hasSeparator={true}
            logoHasContainer={process.env.logoHasContainer}
            topHeaderBackgroundColor={process.env.topHeaderBackgroundColor}
            bottomHeaderBackgroundColor={process.env.bottomHeaderBackgroundColor}
            isSearchBarRound={process.env.isSearchBarRound}
            menuItemTextColor={process.env.menuItemTextColor}
            menuItemHasIcon={true}
            isCartSelected={false}
            cartRadius={process.env.cartRadius}
            cartPrice={priceCart}
            isCartIconFilled={productsForOrder.getValue()?.length > 0}
            numProductsInCart={!storesLoaded ? false : countDistinctIds(productsForOrder.getValue())}
            cartColor={process.env.cartColor}
            list={menuList
              .map(item => {
                if (item.text !== '') return item;
              })
              .filter(item => item !== undefined)}
            menuItemSeparatorColor={process.env.menuItemSeparatorColor}
            onOpen={openMenu}
            logo={props.responsiveLogo}
            onLogoClick={() => {
              Router.push('/');
            }}
            onCartClick={() => {
              CartStore.setIsVisible(true);
            }}
            searchBarValue={query}
            setSearchBarValue={onChangeQuery}
            onSearchSubmit={onSubmit}
            searchBarOnBlur={onBlurSearchBar}
            searchBarPlaceholder={t('common.header.search_placeholder_mobile')}
            searchHasClose={process.env.searchHasClose}
            searchIconPosition={process.env.searchIconPosition}
          >
            {query.length > 2 && !hideSuggestions && (
              <Suspense fallback={<p />}>
                <SuggestedSearchResults terms={query} />
              </Suspense>
            )}
          </HeaderMobileV1>
        </Suspense>
      </div>
      {sideSheet && (
        <Aside
          hasBack={process.env.headerVersion !== 'v1' && router.pathname !== '/checkout'}
          onBack={onAsideBack}
          responsiveLogo={props.responsiveLogo}
        />
      )}
      {serviceAddressSideSheet && (
        <ServiceAddressAside
          user={props.user}
          guestUser={props.guestUser}
          setAddressPickerStatus={updateSelectedDeliveryService}
          onClick={process.env.hasStoreNecessary ? showChangeServiceModal : onDeliveryServiceSelection}
          addressPickerStatus={addressPickerStatus}
          address={address}
        />
      )}
      {postalCodeSideSheet && (
        <PostalCodeAside hasBack={process.env.headerVersion !== 'v1'} onBack={onPostalV2Back} config={props.config} />
      )}
      {timeslotsSideSheet && <PrebookAside />}
      {companySideSheet && <CompanyAside fields={formattedHeaderCategories} />}
      {showAddAddressMenu &&
        (!isEmpty(props.user) || !isEmpty(props.guestUser)) &&
        !AddressViewSideSheetStore.isFromProfile() && (
          <AddressViewAside
            setIsNotEligibleText={setIsNotEligibleText}
            setIsNotEligible={setIsNotEligible}
            handleClose={() => {
              AddressViewSideSheetStore.close();
            }}
            isNotEligible={isNotEligible}
            notEligibleWording={isNotEligibleText}
            selectedAddress={null}
            user={props.user}
            guestUser={props.guestUser}
            isEdit={false}
            mainAddress={null}
            onSubmit={e => addAddress(e)}
            config={props.config}
          />
        )}
      <LogoutModal showModal={logoutModal} setShowModal={setLogoutModal} />
    </>
  );
}
